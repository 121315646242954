import React from 'react';
import { connect } from 'react-redux';
import User from 'AppCore/models/User';

export default connect(state => ({
    current_user: new User(state.user.id, state.user)
}), {})(
    ({ children, current_user, permissions, ...props }) => current_user.hasPermissions(permissions)
    ? children
    : null
)

export const withHasPermissions = WrappedComponent => {
    return connect(state => ({
        current_user: new User(state.user.id, state.user)
    }))(
        props => {
            return (
                <WrappedComponent
                    hasPermissions={permissions => props.current_user.hasPermissions(permissions)}
                    {...props}
                />
            )
        }
    )
}