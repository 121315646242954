import initialState from "../initialState";
import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;

export default (state = initialState.authData, action) => {
	switch (action.type) {
	case C.SET_AUTH_DATA:
		return {
			...action.authData,
			userLogged: !!action.authData.access_token
		};
	default:
		return state;
	}
}