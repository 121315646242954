import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from 'AppCore/Components/Icons';

export default ({id, onChange = () => {}, value = '', ...props}) => (
	<TextField
		id={id}
		label="Search"
		placeholder="Search"
		value={value}
		onChange={onChange}

		InputProps={{
			startAdornment: <InputAdornment position="start"><Icon name="search" /></InputAdornment>
		}}
	/>
)