import React from "react";
import { NavLink } from 'react-router-dom'
import get from 'lodash.get';

import DataTable from './index';

const DefaultNavLinkComponent =
    ({ object, children, ...props }) => <NavLink {...props} to={"/admin/product-item/" + object.id}>{children}</NavLink>

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    getNavLink = null,
    NavLinkComponent = DefaultNavLinkComponent
}) => {

    const match = (value, r) => {
        return value && value.match(r);
    }
    const r = new RegExp(searchQuery, 'i');
    const filteredProducts = data.filter(item =>
        match(item.product.name, r) || match(item.product.sku, r)
    );

    return <DataTable
        data={filteredProducts}
        config={{
            cells: [
                { title: 'EPC', key: 'epc' },
                { title: 'SKU', key: 'product.sku' },
                { title: 'Name', key: 'product.name' },
            ],
            actions: [
                { icon_name: 'visibility', title: "View", Component: NavLinkComponent || React.Fragment }
            ]
        }}
        getCell={(item, path_attr) => {

            if (path_attr === 'epc') {
                return <NavLinkComponent object={item}>{item.epc}</NavLinkComponent>
            }

            return get(item, path_attr, '');
        }}
    />

}