import React, { PureComponent } from 'react'

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CoreTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const TableCell = withStyles(theme => ({
  head: {
  	padding:'10px'
  },
  body: {
  	padding:'10px'
  },
}))(CoreTableCell);

export default class TableRender extends PureComponent {
	render() {
		return (
			<Table>
				{this.props.table.head &&
					<TableHead>
						{this.props.table.head.map((row, index) => this.renderRow(row, index))}
					</TableHead>
				}
				{this.props.table.body &&
					<TableBody>
						{this.props.table.body.map((row, index) => this.renderRow(row, index))}
						{this.props.children}
					</TableBody>
				}
			</Table>
		);
	}

	renderRow(row, key) {
		return (
			<TableRow hover key={key}>
				{row.map((column, index) => this.renderCell(column, key + '_' + index))}
			</TableRow>
		)
	}

	renderCell(cell, key) {

		let value = "";
		if (typeof cell !== "object") {
			value = cell;
			value = <span dangerouslySetInnerHTML={{__html: value }} />
		} else {
			value = cell.value;
			value = <span dangerouslySetInnerHTML={{__html: value }} />
			if (cell.italic) {
				value = <i>{value}</i>;
			}
			if (cell.bold) {
				value = <b>{value}</b>;
			}
			if (cell.strong) {
				value = <strong>{value}</strong>;
			}
		}

		return (
			<TableCell key={key}>
				{value}
			</TableCell>
		)
	}
}