import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { ModalContent } from 'AppCore/Components/Modal';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import UserChangeLogger from 'AppCore/Components/UserChangeLogger';

import User from 'AppCore/models/User';
import { updateProduct, createProduct } from 'AppCore/Actions';
import Api from 'AppCore/api';

import css from './editCreate.module.css';
import ModalForm from '../ModalForm'


class EditProductModal extends ModalForm {

	getFormAttributeConfig = () => {

		const { id, current_user } = this.props;
		const { loading = false, saving = false } = this.state;
		const isEdit = id !== null;

		return {
			disabled: {type: 'switch' },
			sku: { type: 'text', disabled: isEdit},
			ean13: {
				type: 'text', disabled: loading || saving,
				transformers: ['nospaces', 'numeric'],
			},
            name: { type: 'text', disabled: loading || saving },
            cost_price: { label: "Prix d'achat", type: 'number', disabled: loading || saving },
            color: { label: "Couleur", type: 'text', disabled: loading || saving},
            category: {
				label: "Categorie",
				type: 'text',
				transformers: ['lowercase', 'alpha'],
				disabled: !(current_user.hasPermission('edit_product') || !isEdit)
			},
            dlc_days: { label: "Nombre de jour de DLC", type: 'number', disabled: loading || saving }
		};
	}

	loadData = async () => {
		this.setState({
			data: await this.getData(),
			loading: false
		})
	}

	getData = async () => {
        const { id: product_id } = this.props;
		if (product_id) {
            return await Api.getProduct({ product_id });
		}

		return {};
	}

	save = async () => {

		const { id: product_id } = this.props;
        const { data: product } = this.state;
		try {

			if (product_id) {
				await this.props.updateProduct({ product_id, product });
			} else {
				await this.props.createProduct({ product });
			}

		} catch (error) {

            this.props.enqueueSnackbar(error.message, {variant: 'error'});

		}
	}

	renderModalContent() {

		const { id: product_id } = this.props;
		const { loading = false, data: product } = this.state;

		return (loading ? <ActivityIndicator /> :

			<ModalContent>

				<div className={css.formContainer}>
					{this.renderForm({ formClassName: css.form })}


					{product_id &&
						<UserChangeLogger
							style={{marginTop: '20px', width:'100%'}}
							object={product}
						/>
					}
				</div>
			</ModalContent>);
	}

	getTitle() {
		const { id } = this.props;
		return id ? "Modifier un produit" : "Nouveau Produit"
	}


}


export default connect(
	state => ({
		current_user: new User(state.user.id, state.user),
	}),
	{ updateProduct, createProduct }
)(withSnackbar(EditProductModal));