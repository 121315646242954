import { initApp as coreInitApp, initAppAsync as coreInitAppAsync } from 'AppCore/init';
import { setSetting as setAppSetting } from "Meth/config/app_settings";
import { setHostApi, setUserAgent as ApiSetUserAgent } from "Meth/api/request"

import settings from './settings';


function setUserAgent() {
	const user_agent = "ShakishakiKitchenTool/"+settings.app_version+" (shakishaki; x.x; Mobile; en)";
	setAppSetting("ua", user_agent);
	ApiSetUserAgent(user_agent)
}

let { currentUserId } = {};
function onStoreChange(state) {
	if (state.user && state.user.id && state.user.id !== currentUserId) {
		currentUserId = state.user.id;
	}
}

export function initApp() {
	setUserAgent();
	setHostApi(settings.default_api_url);
	coreInitApp({
		onStoreChange,
		settings: {
			ws: {
				...settings.ws,
				debug: message => {
					console.log(message);
				}
			}
		}
	});
}

export const initAppAsync =  async () => {
	await coreInitAppAsync();
}