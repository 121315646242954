import React, { Component } from 'react'
import { connect } from "react-redux";
import moment from 'moment';
import nanoid from 'nanoid';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withHasPermissions } from 'AppCore/Components/HasPermissions'
import { getById } from 'AppCore/Store/helper';
import Api from 'AppCore/api';
import User from 'AppCore/models/User';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import DatePicker from 'react-datepicker';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'

import CatProductTableEditor from '../CatProductTableEditor'

import { getMinDate, getProdDayFromDeliveryDayHour } from 'AppCore/Utils/ClientOrder';
import UserChangeLogger from 'AppCore/Components/UserChangeLogger';

import ContenantSelector from 'AppCore/Components/Selector/Contenant';
import HourSelector from 'AppCore/Components/Selector/Hours';

import Css from './style.css'
import { Checkbox } from '@material-ui/core';

const ProductNameCell = withStyles(theme => ({
  head: {
    width: 400,
    fontSize: 18,
  },
  body: {

    width: 400
  },
}))(TableCell);

const Transition = React.forwardRef(function(props, ref) {
	return <Slide ref={ref} direction="up" {...props} />;
})

const TableFormRow = ({ label, children }) => (
	<TableRow>
		<ProductNameCell>{label} : </ProductNameCell>
		<TableCell>
			{children}
		</TableCell>
	</TableRow>
)

const generateOrderId = () => nanoid(10)

class OrderDialog extends Component {

	static defaultProps = {
		onSaveOrder: () => {},
		onClose: () => {},

		shop: null,
		day: null,

		read_only: false,

		editData: null,
		editOrder: null,
		default_order_details: {
			client: {
				name: "",
				tel: "",
				address: "",
				address_infos: "",
				cp: "",
				city: ""
			},
			productList: []
		}
	}

	constructor(props) {
		super(props);

		var order;
		let isNewOrder = false;

		this.shop = getById(this.props.shop_id, this.props.shops)

		if (this.props.editOrder !== null) {
			order = {
				...this.props.editOrder,
			};
			if (!order.id) {

				order = {
					...order,
					day_prod: getProdDayFromDeliveryDayHour(moment(order.day, 'YYYYMMDD'), this.shop.hour_delivery || 5).format('YYYYMMDD'),
				}

				order.key = generateOrderId();

				order.client = {
					contact_name: this.shop.contact_name,
					tel: this.shop.tel,
					address: this.shop.address,
					address_infos: this.shop.address_infos,
					cp: this.shop.cp,
					city: this.shop.city,
					...this.props.editOrder.client,
				}

				isNewOrder = true;
			}
		} else {

			// NEW ORDER
			const day_delivery = this.props.day || getMinDate().format('YYYYMMDD');
			order = ({
				...this.props.default_order_details,
				shop_id: this.props.shop_id,
				day: day_delivery,
				hour_delivery: this.shop.hour_delivery || 5,
				hour_dropoff: this.shop.hour_dropoff || 5,
				day_prod: getProdDayFromDeliveryDayHour(moment(day_delivery, 'YYYYMMDD'), this.shop.hour_delivery || 5).format('YYYYMMDD'),

				delivery_signature: this.shop.delivery_signature,
				delivery_picture: this.shop.delivery_picture,

				key: generateOrderId(),

				client: {
					contact_name: this.shop.contact_name,
					tel: this.shop.tel,
					address: this.shop.address,
					address_infos: this.shop.address_infos,
					cp: this.shop.cp,
					city: this.shop.city
				},
				truck_delivery: this.shop.truck_delivery || null,
				container_type: this.shop.container_type || null
			});
			isNewOrder = true;
		}

		const order_creation_date = isNewOrder ? moment() : moment(order.__meta.creation_date*1000);

		this.state = {
			new_product_selected_id: 0,
			new_product_amount: "",

			order_creation_date,
			isNewOrder,

			loading_save_order: false,

			orderDetails: order,
			editData: this.props.editData,
			tab_index: 0,
		}

	}

	getProductListIntoCatProducts() {

		const { productList = [] } = this.state.orderDetails;

		const catProducts = {};
		const createNewCat = () => ({ amount: 0, productList: {} });

		productList.forEach(product => {
			if (!catProducts[product.category_id]) {
				catProducts[product.category_id] = createNewCat();
			}

			catProducts[product.category_id].productList[product.product_id] = product;
		})

		return catProducts;
	}

	setProductListFromCatProductList = catProducts => {

		let productList = {};
		Object.keys(catProducts).forEach(category_id => {
			Object.keys(catProducts[category_id].productList).forEach(product_id => {
				productList[product_id] = catProducts[category_id].productList[product_id];
			})
		})

		productList = Object.keys(productList).map(product_id => productList[product_id]);

		this.setState({
			orderDetails: {
				...this.state.orderDetails,
				productList
			}
		});
	}

	handleClose = () => {
		this.props.onClose();
	}

	onClickSave = async () => {

		if (!this.state.orderDetails.container_type) {
			window.alert("Vous devez entrer un type de contenant !");
			return false;
		}

		this.setState({
			loading_save_order: true
		})

		try {

			if (this.state.orderDetails.id) {
				await Api.updateClientOrder({ order_id: this.state.orderDetails.id, clientorder: this.state.orderDetails });
			} else {
				await Api.createClientOrder({ clientorder: this.state.orderDetails });
			}

		} catch (e) {
			window.alert(e.message);
			this.setState({
				loading_save_order: false
			})
			return;
		}

		this.setState({
			loading_save_order: false
		})

		this.props.onSaveOrder({
			...this.state.orderDetails
		}, this.props.editData)
	}

	_renderRowClientInfo = (key, label, { textFieldProps = {} } = {}) => (
		<TableRow>
			<ProductNameCell>{label} : </ProductNameCell>
			<TableCell>
				{this.props.read_only ? (this.state.orderDetails.client && this.state.orderDetails.client[key]) :
					<TextField
						value={(this.state.orderDetails.client && this.state.orderDetails.client[key]) || ""}
						onChange={e => {
							this.setState({
								orderDetails: {
									...this.state.orderDetails,
									client: {
										...this.state.orderDetails.client,
										[key]: e.target.value
									}
								}
							})
						}}
						{...textFieldProps}
					/>
				}
			</TableCell>
		</TableRow>
	)

	onChangeHourDelivery = hour_delivery => {
		this.setState({
			orderDetails: {
				...this.state.orderDetails,
				hour_delivery
			}
		})
	}

	onChangeDayDelivery = day => {

		const delivery_day = moment(day);

		this.setState({
			orderDetails: {
				...this.state.orderDetails,
				day: delivery_day.format('YYYYMMDD'),
				day_prod: delivery_day.format('YYYYMMDD')
			}
		})
	}

	onChangeDayProd = ({ target: { value: day }}) => {
		const prod_day = moment(day, 'YYYYMMDD');

		this.setState({
			orderDetails: {
				...this.state.orderDetails,
				day_prod: prod_day.format('YYYYMMDD')
			}
		})
	}

	_renderTableDeliveryInfos() {

		const selected_date = (this.state.orderDetails.day && moment(this.state.orderDetails.day, 'YYYYMMDD').toDate());
		const selected_date_display = (selected_date && moment(selected_date).format('DD/MM/YYYY')) || '';

		let user = null;
		let userCanSetAnyDay = false;
        if (this.props.current_user) {
			user = new User(this.props.current_user.id, this.props.current_user)
			if (user && (user.isAdmin() || user.hasPermission('force_edit_clientorder'))) {
				userCanSetAnyDay = true;
			}
        }

		return (
			<Table>
				<TableBody>

					<TableRow>
						<ProductNameCell>Client : </ProductNameCell>
						<TableCell>
							<h3>{getById(this.props.shop_id, this.props.shops).name}</h3>
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Date de Récupération : </ProductNameCell>
						<TableCell>
							{this.props.read_only ? moment(this.state.orderDetails.day, 'YYYYMMDD').format('LL') :
							<DatePicker
								dateFormat="dd/MM/yyyy"
								className={Css.DatePicker}
								selected={
									selected_date
								}
								highlightDates={[new Date()]}
								onChange={this.onChangeDayDelivery}
								locale="fr"
								placeholderText="Selectionner une date de commande"

								{...(userCanSetAnyDay ? {} : {
									minDate: getMinDate(parseInt(this.state.orderDetails.hour_delivery, 10)).toDate()
								})}

								customInput={<Button children={selected_date_display} variant="contained" onClick={this.handleClose} color="primary"/>}

							/>}

							{this.props.read_only ? (" (" + this.state.orderDetails.hour_delivery + "h)") :
								<HourSelector
									value={parseInt(this.state.orderDetails.hour_delivery, 10)}
									onChange={this.onChangeHourDelivery}
								/>
							}
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Date de Prod : </ProductNameCell>
						<TableCell>

							{this.props.read_only ? (this.state.orderDetails.day_prod && moment(this.state.orderDetails.day_prod, 'YYYYMMDD').format('LL')) :
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={this.state.orderDetails.day_prod}
									onChange={this.onChangeDayProd}
									style={{marginLeft: '10px'}}
								>

									{(() => {
										const day_delivery = moment(this.state.orderDetails.day, 'YYYYMMDD');
										const day_delivery_previous = day_delivery.clone().subtract(1, 'days');

										return [day_delivery, day_delivery_previous].map(day => (
											<MenuItem
												key={day.format('YYYYMMDD')} value={day.format('YYYYMMDD')}
											>
												{day.format('LL')}
											</MenuItem>
										))
									})()}

								</Select>
							}
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Heure de livraison : </ProductNameCell>
						<TableCell>

							{this.props.read_only ? (" (" + this.state.orderDetails.hour_dropoff + "h)") :
								<HourSelector
									value={parseInt(this.state.orderDetails.hour_dropoff, 10)}
									onChange={hour_dropoff => {
										this.setState({
											orderDetails: {
												...this.state.orderDetails,
												hour_dropoff
											}
										})
									}}
								/>
							}
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Type de contenant : </ProductNameCell>
						<TableCell>
							{this.props.read_only ? this.state.orderDetails.container_type :
								<ContenantSelector
									value={this.state.orderDetails.container_type || ""}
									onChange={
										container_type => {
											this.setState({
												orderDetails: {
													...this.state.orderDetails,
													container_type
												}
											})
										}
									}
								/>
							}
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Numéro de camion (1 à 10) : </ProductNameCell>
						<TableCell>
							{this.props.read_only ? this.state.orderDetails.truck_delivery :
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={this.state.orderDetails.truck_delivery || ""}
									onChange={
										({ target: { value: truck_delivery } }) => {
											this.setState({
												orderDetails: {
													...this.state.orderDetails,
													truck_delivery
												}
											})
										}
									}
								>
									{(() => {
										const Items = [];
										for (var i = 0; i <= 10; i++) {
											Items.push(
												<MenuItem key={"Truck_" + i} value={i}>{i}</MenuItem>
											)
										}
										return Items;
									})()}

								</Select>
							}
						</TableCell>
					</TableRow>

					<TableFormRow
						label="Photo obligatoire à la livraison"
					>
						{this.props.read_only ? (
							this.state.orderDetails.delivery_picture ? "Oui" : "Non"
						): (
							<Checkbox
								checked={this.state.orderDetails.delivery_picture}
								onChange={({ target: { checked: delivery_picture }}) => {
									this.setState({
										orderDetails: {
											...this.state.orderDetails,
											delivery_picture
										}
									})
								}}
							/>
						)}
					</TableFormRow>
					<TableFormRow
						label="Signature obligatoire à la livraison"
					>
						{this.props.read_only ? (
							this.state.orderDetails.delivery_signature ? "Oui" : "Non"
						): (
							<Checkbox
								checked={this.state.orderDetails.delivery_signature}
								onChange={({ target: { checked: delivery_signature }}) => {
									this.setState({
										orderDetails: {
											...this.state.orderDetails,
											delivery_signature
										}
									})
								}}
							/>
						)}
					</TableFormRow>


					{this._renderRowClientInfo('contact_name', "Nom du contact")}
					{this._renderRowClientInfo('tel', "Telephone du contact")}
					{this._renderRowClientInfo('address', "Adresse de livraison", { textFieldProps: {
						rows: 7,
						multiline: true
					}})}
					{this._renderRowClientInfo('address_infos', "Complément d'adresse", { textFieldProps: {
						rows: 2,
						multiline: true
					}})}
					{this._renderRowClientInfo('cp', "Code Postal")}
					{this._renderRowClientInfo('city', "Ville")}


				</TableBody>
			</Table>
		);
	}

	_renderTableClientOrder() {

		return (
			<Table>
				<TableBody>

				<TableRow>
						<ProductNameCell>Client : </ProductNameCell>
						<TableCell>
							<h3>{getById(this.props.shop_id, this.props.shops).name}</h3>
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Reference Client </ProductNameCell>
						<TableCell>
							{this.props.read_only ? this.state.orderDetails.external_reference : <TextField
								value={this.state.orderDetails.external_reference || ""}
								onChange={e => {
									this.setState({
										orderDetails: {
											...this.state.orderDetails,
											external_reference: e.target.value
										}
									})
								}}
							/>}
						</TableCell>
					</TableRow>

					<TableRow>
						<ProductNameCell>Reference Unique </ProductNameCell>
						<TableCell>
							{this.props.read_only ? this.state.orderDetails.key : <TextField
								value={this.state.orderDetails.key || ""}
								onChange={e => {
									this.setState({
										orderDetails: {
											...this.state.orderDetails,
											key: e.target.value
										}
									})
								}}
							/>}
						</TableCell>
					</TableRow>


				</TableBody>
			</Table>
		);
	}

	_renderTableProducts() {
		return (
			<CatProductTableEditor
				useCatPourcent={false}
				canEditProducts
				displayTotalByCat
				values={[{title: "Quantité", categories: this.getProductListIntoCatProducts()}]}
				onChangeItem={(i, catProductList) => this.setProductListFromCatProductList(catProductList)}
				readOnly={this.props.read_only}
				expandCategories
			/>
		)
	}

	render() {

		const { hasPermissions } = this.props;
		const { orderDetails } = this.state;

		if (orderDetails.id) {

		}

		return (

			<Dialog open={true} maxWidth={false} onClose={this.handleClose} aria-labelledby="simple-dialog-title" TransitionComponent={Transition}>
				<DialogTitle>{this.state.orderDetails.id ? "EDIT Order" : "Add New Order"}</DialogTitle>

				<DialogContent>


					<div className={Css.container}>

						<AppBar position="static" color="default">
							<Tabs value={this.state.tab_index || 0} onChange={(event, tab_index) => this.setState({ tab_index })}>
								<Tab label="General" />
								<Tab label="Information de livraison" />
								<Tab label="Produits" />
								{orderDetails.id && hasPermissions(['admin']) &&
									<Tab label="Logs" />
								}
							</Tabs>
						</AppBar>

						{!this.state.tab_index && <div className={Css.ClientTableContainer}>
							{this._renderTableClientOrder()}
						</div>}

						{this.state.tab_index === 1 && <div className={Css.ClientTableContainer}>
							{this._renderTableDeliveryInfos()}
						</div>}


						{this.state.tab_index === 2 && <div className={Css.RecapOrderTableContainer}>
							{this._renderTableProducts()}
						</div>}

						{orderDetails.id && hasPermissions(['admin']) && this.state.tab_index === 3 &&
							<UserChangeLogger
								style={{marginTop: '30px', width:'100%', minHeight: '400px'}}
								object={orderDetails}
							/>
						}

					</div>
				</DialogContent>

				{this.props.read_only ?

				<DialogActions>
					<Button variant="contained" onClick={this.handleClose} color="primary">
						Fermer
					</Button>
				</DialogActions>
				: <DialogActions>
					<Button variant="contained" onClick={this.handleClose} color="primary">
						Annuler
					</Button>
					{this.state.loading_save_order
						? 	<CircularProgress />
						: 	<Button variant="contained" onClick={this.onClickSave} color="primary">
								Enregistrer
							</Button>
					}
				</DialogActions>}
			</Dialog>

		)
	}
}


export default connect(
	state => ({
		shops: (state.shops && state.shops.data) || [],
		products: (state.products.data && state.products.data.filter(o => !o.disabled)) || [],
		current_user: state.user
	}),
	{}
)(withHasPermissions(OrderDialog));


