import initialState from "AppCore/Store/initialState";
import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.settings, action) => {
	switch (action.type) {
	case C.SET_SETTINGS:
		return {
			...state,
			...action.settings,
			loaded: true
		};
	case C.CLEAR_SETTINGS:
		return initialState.settings;
	default:
		return state;
	}
}