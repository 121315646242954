
export default class StdObject {

	constructor(data) {

		this.payload = null;
		this.__meta = null;

		if (data) {
			this.setData(data);
		}

		if (data && data.__meta) {
			this.__meta = data.__meta;
		}
	}

	getModel() {
		return {};
	}

	isMethObject() {
		return false;
	}

	export({ withPayload = true, withMeta = true } = {}) {

		var model = this.getModel();

		var export_data = {};

		if (this.id) {
			export_data.id = this.id;
		}

		for (var key in model) {

			if (!model.hasOwnProperty(key)) {
				continue;
			}

			if (!this.hasOwnProperty(key)) {
				continue;
			}

			if (model[key] === true) {
				export_data[key] = this[key];
				continue;
			}

			if (new model[key]() instanceof StdObject) {
				if (this[key] !== null) {
					export_data[key] = this[key].export();
				}
				continue;
			}

		}

		if (withPayload && this.payload !== null) {
			export_data.payload = {...this.payload};
		}

		if (withMeta && this.__meta !== null) {
			export_data.__meta = {...this.__meta}
		}

		return export_data;
	}

	setData(data) {

		var model = this.getModel();

		for (var key in model) {

			if (!model.hasOwnProperty(key)) {
				continue;
			}

			if (!data.hasOwnProperty(key)) {
				this[key] = null;
				continue;
			}

			if (model[key] === true) {
				this[key] = data[key];
				continue;
			}

			try {
				if ((new model[key]()).isMethObject() && data[key]) {

					this[key] = new model[key](data[key].id, data[key]);
					continue;
				}

				if (new model[key]() instanceof StdObject && data[key]) {
					this[key] = new model[key](data[key]);
					continue;
				}
			} catch (e) {
				console.warn(e.message, key, model);
			}

		}

		if (data.payload) {
			this.setPayload(data.payload);
		}

		return true;
	}

	setPayload(payload) {
		this.payload = payload;
	}

}