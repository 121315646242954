import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { yellow, red } from '@material-ui/core/colors';

const theme = createMuiTheme({

	palette: {
		primary: yellow,
		secondary: red,

		type: 'dark',

		background: {
			paper: '#333'
		},
	},

	typography: {},
});

export default ({ children, ...props }) => (
	<ThemeProvider theme={theme}>
		{children}
	</ThemeProvider>
)