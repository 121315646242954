import React, { PureComponent } from "react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import _get from 'lodash.get';
import Icon from 'AppCore/Components/Icons';

import * as validatorsFct from './validators';

import InputField from './inputField';
import Selects from "./Selects";

const styles = theme => ({

	container: {
		position: 'relative',
		marginBottom: '10px'
	},

	errorContainer: {
		position: 'absolute',
		right: 10,
		top: 0,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
    }
});

const objectToArrayMap = obj => Object.keys(obj).map(attr_name => ({ attr_name, value: obj[attr_name] }))

class Form extends PureComponent {

	state = { errors : {} }

	hasError = attr_name => {
		if (attr_name !== undefined) {
			return this.hasErrorAttr(attr_name);
		}

		let hasError = false;
		Object.keys(this.state.errors).forEach(attr_name => {
			if (this.state.errors[attr_name].hasError) {
				hasError = true;
				return false;
			}
		})

		return hasError;
	}

	hasErrorAttr = attr_name => (this.state.errors[attr_name] && this.state.errors[attr_name].hasError) || false
	getErrorMessage = attr_name => {
		if (!this.hasError(attr_name)) {
			return '';
		}

		const { errors } = this.state;

		return errors[attr_name]
				.errors.map(error => (validatorsFct[error] && validatorsFct[error].error_msg) || '')
				.join('; ');
	}
	getError = (attr_name, value) => {

		const { values, config: { attributes } } = this.props;

		const { validators = [] } = attributes[attr_name];
		if (value === undefined) {
			value = values[attr_name] || '';
		}

		const errors = [];
		let hasError = false;

		if (validators.indexOf('not_empty') !== -1 || value !== "") {
			validators.forEach(validator => {
				if (!validatorsFct[validator] || validatorsFct[validator](value)) return;
				hasError = true;
				errors.push(validator);
			});
		}

		return {
			hasError,
			errors
		}
	}

	checkAllErrors = () => {
		const { config: { attributes } } = this.props;

		const errors = {};
		let hasError = false;
		Object.keys(attributes).forEach(attr_name => {
			errors[attr_name] = this.getError(attr_name);
			hasError = hasError || errors[attr_name].hasError;
		});

		this.setState({
			errors: {
				...this.state.errors,
				...errors
			}
		}, this.callOnChange)

		return hasError;
	}

	checkErrors = (attr_name, value) => {
		this.setState({
			errors: {
				...this.state.errors,
				[attr_name]: this.getError(attr_name, value)
			}
		}, this.callOnChange)
	}

	callOnChange = newValues => {
		const { values, onChange = () => {} } = this.props;

		onChange({
			values: {
				...values,
				...newValues
			},
			errors: this.state.errors,
			hasError: this.hasError()
		});
	}

	newOnChangeField = (attr_name, event_attr = 'value') => value => {
		this.callOnChange({
			[attr_name]: value
		});
		if (this.hasError(attr_name)) {
			this.checkErrors(attr_name, value);
		}
	}

	getFieldValue = (attr_name, _default = "") => {
        const { values } = this.props;

        return _get(values, attr_name, _default);
	}

	renderFieldAttribute = attr_name => {

		const { config: { attributes }, classes } = this.props;
		const { type, selectType, multiline = false, disabled = false, label = null, placeholder = null, values = [] } = attributes[attr_name];

		if (type === 'select' && !!selectType) {
			return (
				<Selects
					style={{minWidth: '200px'}}
					classes={classes}
					label={label || attr_name}
					selectType={selectType}
					value={this.getFieldValue(attr_name)}
					onChange={this.newOnChangeField(attr_name)}
				/>
			)
		}

		return (
			<InputField
				transformers={attributes[attr_name].transformers || []}
				onsave_transformers={attributes[attr_name].onsave_transformers || []}
				label={label || attr_name}
				type={type}
				onChange={this.newOnChangeField(attr_name)}
				classes={classes}
				onBlur={() => this.checkErrors(attr_name)}
				disabled={disabled}

				value={this.getFieldValue(attr_name)}
				values={values}

				error={this.hasError(attr_name)}
				placeholder={placeholder || attr_name}
				multiline={multiline}
			/>
		)
	}

	render() {
		const { classes, textFieldClassName, config: { displayLabelOnLeft, attributes }, values, onChange = () => {}, ...props } = this.props; // eslint-disable-line

		return (
			<div {...props}>
				{objectToArrayMap(attributes).map(
					({ attr_name, value: { label, style = {} } = {} }) => (

							<div key={attr_name} className={classNames(textFieldClassName, classes.container)} style={{
								...style,
								position: 'relative'
							}}>
								{displayLabelOnLeft ? label : null}

								<Tooltip
									title={this.getErrorMessage(attr_name)}
									open={this.hasError(attr_name)}
									placement="right"
								>
									<div style={{display: 'inline-block'}}>{this.renderFieldAttribute(attr_name)}</div>
								</Tooltip>

								{this.hasError(attr_name) &&
									<div className={classes.errorContainer}>
										<Icon name="error" color="error" />
									</div>
								}
							</div>
					)
				)}

			</div>
		)
	}
}

export default withStyles(styles)(Form);