import React from "react";
import { ActivityIndicator } from "react-native";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
});

const Progress = ({material = false, size = 15, classes, ...props}) => {

	if (material) {

		const sizeMap = {big: 50, small: 30, default: 15};
		if (typeof size !== 'number') {
			size = sizeMap[size] || sizeMap['default'];
		}

		return (
			<CircularProgress
				variant="indeterminate"
				className={classes.progress}
				color="primary"
				size={size}
				{...props}
			/>
		);
	}

	return (
		<ActivityIndicator size={size} {...props} />
	);

}

export default withStyles(styles)(Progress);
