import React, { Component } from 'react'
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress'


import User from 'AppCore/models/User';
import Api from 'AppCore/api';
import { displayModal, hideModal } from 'AppCore/Actions'

import ClientOrdersTable from 'AppCore/Components/DataTable/ClientOrders';

import Css from './style.css';
import CommonCss from 'Screens/App/common.css';

class ClientOrders extends Component {

	constructor(props) {
		super(props);

		this.state = {

			loading: true,
			order: null,
			orders: null,

			clientOrders: [],
		}
	}

	componentDidMount = async () => {
		this.setState({
			clientOrders: (await Api.getClientOrdersByShop({ shop_id: this.props.shop.id })).data,
			loading: false
		})
	}

	reloadClientOrders = async () => {
		this.setState({
			clientOrders: (await Api.getClientOrdersByShop({ shop_id: this.props.shop.id })).data
		})
	}

	openNewOrderDialog = (newOrderDialogOrderDetails = null) => this.props.displayModal({
		name: 'edit_create_clientorder',
		props: {
			shop_id: this.props.shop.id,
			editOrder: newOrderDialogOrderDetails,
			onSaveOrder: this.onSaveOrderClient
		}
	})

	onSaveOrderClient = (orderDetails) => {
		this.props.hideModal()
		this.reloadClientOrders();
	}

	_renderTableClientOrders() {
		return (
			<ClientOrdersTable
				data={this.state.clientOrders}
				triggerReload={this.reloadClientOrders}
			/>
		)
	}

	render() {

		if (this.state.loading) {
			return (<CircularProgress />);
		}

		let hasCreatePermission = false;
		if (this.props.current_user) {
			const current_user = new User(this.props.current_user.id, this.props.current_user);
			hasCreatePermission = current_user.hasPermission('create_clientorder');
		}

		return (
			<div className={Css.CommandContainer}>

				<div className={CommonCss.tableContainer + ' ' + Css.ClientOrdersContainer}>
					{hasCreatePermission && <Button variant="contained" color="primary" className={Css.ActionButton} onClick={e => this.openNewOrderDialog()}>
						<AddIcon /> Créer une commande
					</Button>}
					<div className={Css.ClientOrdersTableContainer}>
						{this._renderTableClientOrders()}
					</div>
				</div>

			</div>
		)
	}


}



export default connect(
	state => ({
		current_user: state.user,
	}),
	{ displayModal, hideModal }
)(ClientOrders);







