import React, { Component, useState, useEffect } from 'react'
import get from 'lodash.get';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Api from 'AppCore/api';

import { withHasPermissions } from 'AppCore/Components/HasPermissions';

import { PageContainer, Content } from 'Screens/App/Layout';
import Header from 'Screens/App/Layout/Header';

import css from './style.css';

import EditTab from './Edit';


class InvoiceScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tab_index: 0,
		}
	}

	componentDidMount = async () => {
		const { state } = this.props.history.location;

		if (state && state.edit) {
			this.setState({
				tab_index: 0
			})
		}
	}

	onChangeTab = (event, value) => {
		this.setState({tab_index: value})
    }

	render() {

		const { invoice } = this.props;

		const TabList = [
			{
				label: "Détails / Edition",
				render: () => (
					<EditTab invoice_id={this.props.invoice.id} invoice={this.props.invoice} onChange={this.props.refreshData} />
				),
				enable: this.props.hasPermissions(['edit_invoice'])
			}
		];

		return (
			<PageContainer fullWidth>
				<AppBar position="static" color="default">
					<Toolbar>
						<Header title={get(invoice, 'client.name', 'Chargement...') + (invoice ? " - " : "" ) + get(invoice, 'name', '')} />
					</Toolbar>

					<Tabs value={this.state.tab_index} onChange={this.onChangeTab} variant="fullWidth">
						{TabList.map(({ label, enable }, i) => (
							enable && <Tab label={label} key={i} />
						))}
					</Tabs>
				</AppBar>

				<Content className={css.content}>
					{invoice && TabList.map(({ render, enable }, i) => (
						enable && this.state.tab_index === i && <React.Fragment key={i}>{render()}</React.Fragment>
					))}
                    {!invoice && <div>Chargement...</div>}
				</Content>
			</PageContainer>
		)
	}
}


const InvoiceScreenLoader = props => {

    const invoice_id = props.match.params.id;
    const [ invoice, setInvoice ] = useState(null);

    const refreshData = async () => {
        setInvoice(await Api.getInvoice({ invoice_id }));
    }

    useEffect(() => {
        refreshData();
    }, [ invoice_id ] )

    return <InvoiceScreen refreshData={refreshData} invoice={invoice} {...props} />
}

export default withHasPermissions(InvoiceScreenLoader)