import React, { Component } from "react";
import { NavLink } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import ProductsItemsTable from 'AppCore/Components/DataTable/productItems';
import OrderTable from 'AppCore/Components/DataTable/orders';

import { getLocations } from 'AppCore/Actions';

import { PageContainer, Content } from '../../Layout';
import Header from '../../Layout/Header';

class FridgeScreen extends Component {

	state = {
        tabValue: 0,
    }

	render() {
        const { tabValue = 0 } = this.state;

		return (
			<PageContainer>

				<Header title={"Fridge - " + this.props.fridge.code}></Header>

				<Content>

                    <Paper elevation={2}>
                        <Tabs
                            value={tabValue}
                            onChange={(event, tabValue) => {
                                this.setState({ tabValue });
                            }}
                            indicatorColor="primary"
                        >

                            <Tab label="Produits" />
                            <Tab label="Commandes"  />
                            <Tab label="Prix par produit"  />

                        </Tabs>

                        {tabValue === 0 && this.renderProductsTab()}
                        {tabValue === 1 && this.renderOrdersTab()}
                    </Paper>

				</Content>

			</PageContainer>
		);
    }

    renderProductsTab() {
        const { products: { data = [] } = {} } = this.props;

        return (
            <ProductsItemsTable
                data={data}
                getNavLink={
                    productItem => <NavLink to={"/admin/product-item/" + productItem.id}>{productItem.epc}</NavLink>
                }
            />
        );
    }

    renderOrdersTab() {
        const { orders: { data = [] } = {} } = this.props;
        return (
            <OrderTable
                data={data}
                getNavLink={
                    order => <NavLink to={"/admin/order/" + order.id}>{order.id.substr(-5)}</NavLink>
                }
            />
        )
    }

}


export default connect(
	state => ({
		locations: state.locations
	}),
	{ getLocations }
)(withSnackbar(FridgeScreen));