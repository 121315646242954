import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import { NavLink, withRouter, matchPath } from 'react-router-dom'
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import User from 'AppCore/models/User';
import { logout, toggleMenu } from 'AppCore/Actions'
import Css from './style.css';

import Logo from 'Images/logo/shakishaki_logo_RVB_blanc.png';

const drawerWidth = 240;
const styles = theme => ({

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },


  ListLink: {
  	paddingLeft: 10,
  	paddingRight: 10,
	overflow: "auto",
	flex: 1
},
  link: {
  	borderRadius: 3,
  	backgroundColor: theme.palette.background.paper,
  	'&:focus': {
  		backgroundColor: theme.palette.background.paper,
  	},
  	'&:hover': {
  		backgroundColor: '#1A60C6',
  	}
  },
  linkActive: {
  	backgroundColor: '#1A60C6',
  	'&:focus': {
  		backgroundColor: '#1A60C6',
  	},
  },
  isSubmenuActive: {
		backgroundColor: '#1B98E0'
  },

  menu: {
  	width: '200px'
  },

  nested: {
    marginLeft: theme.spacing(4),
	width: 'auto'
  },
});

class SideBar extends PureComponent {

	state = {
		submenus: {}
	}

	onClickOpenCloseSubmenu = key => e => {
		this.props.toggleMenu(key);
		e.stopPropagation();
		e.preventDefault();
	}

	renderLink = ({ label, key: _key = null, link = null, exact = true, submenu = false, matches = [], nested = false, locationPathname = null }) => {

		const key = _key || link || ("key_"+label);

		let isSubmenuActive = false;
		if (typeof(matches) === 'string') {
			matches = [ matches ];
		}
		if (submenu && matches.length > 0) {
			for (let i = 0; i < matches.length; i++) {
				if (matchPath(locationPathname, {
					path: matches[i]
				})) {
					isSubmenuActive = true;
					break;
				}
			}
		}

		return [
			<ListItem
				classes={{
					root: this.props.classes.link,
				}}

				className={classnames({
					[this.props.classes.nested]: nested,
					[this.props.classes.isSubmenuActive]: isSubmenuActive
				})}

				{...(link ? {
					component: NavLink,
					to: link,
					exact,
					activeClassName: this.props.classes.linkActive
				} : {
					onClick: submenu ? this.onClickOpenCloseSubmenu(key) : () => {}
				})}

				button
				key={key}
			>
				<ListItemText primary={label} />

				{submenu &&
					(
						this.props.submenus[key] ?
							<ExpandLess onClick={this.onClickOpenCloseSubmenu(key)} /> :
							<ExpandMore onClick={this.onClickOpenCloseSubmenu(key)} />
					)
				}


			</ListItem>,

			...(submenu ? [
				<Collapse key={"collapse_"+label} in={this.props.submenus[key]} timeout="auto" unmountOnExit>
					<List component="div"  >
						{submenu.map(props => this.renderLink({...props, nested: true}))}
					</List>
				</Collapse>
			] : [])

		];
	}

	renderContent() {

		const { classes, current_user: _current_user } = this.props;
		const { pathname: locationPathname } = this.props.location;
		const current_user = new User(_current_user.id, _current_user);

		let MENU_ITEMS = [];

		if (current_user.hasPermission('fridge', 'r')) {
			MENU_ITEMS.push({
				label: 'Fridges',
				key: 'fridges_section',
				submenu: [
					{ label: "List pos / inventory", link: '/fridges' },
					{ label: "Fridge Orders", link: '/fridges/orders' },
				],
				matches: [
					"/fridges"
				]
			})
		}

		if (current_user.hasPermission('list_clients')) {
			const menuShop = {
				label: 'Clients',
				link: '/shops',

				exact: false,
			};

			MENU_ITEMS.push(menuShop);
		}

		if (current_user.hasPermission('list_invoices', 'r')) {

			MENU_ITEMS.push({
				label: 'Factures',
				key: 'invoice',
				link: '/invoices',

				exact: false,
			})

		}

		if (current_user.hasPermission('list_all_client_orders')) {
			MENU_ITEMS.push({
				label: 'Toutes les commandes',
				link: '/client-orders',

				exact: true,
			});
		}

		if (current_user.hasPermission('kitchen', 'r')) {

			MENU_ITEMS.push({
				label: 'Cuisine',
				key: 'kitchen',
				matches: [
					"/clientorders",
					'/kitchen'
				],
				submenu: [
					{label: "Production du jour", link: "/kitchen"},
					{label: "Production de la semaine", link: "/kitchen/week"},
					{label: "Commandes à produire aujourd'hui", link: "/kitchen/clientorders/prod"},
				]
			})

		}

		if (current_user.hasPermission('list_products', 'r')) {
			MENU_ITEMS.push({
				label: 'Produits',
				key: 'products',
				matches: [
					"/products",
					"/price-lists"
				],
				submenu: [
					{ label: "Liste des produits", link: '/products' },
					{ label: "Liste de prix", link: '/price-lists' },
				]
			})
		}

		if (current_user.hasPermission('tools_access', 'r')) {
			MENU_ITEMS.push({
				label: 'Outils',
				key: 'tools',
				matches: [
					"/tools"
				],
				submenu: [
					{
						label: "Deliveroo",
						link: '/tools/deliveroo'
					},
					{
						label: "Order Email Listener Code",
						link: '/tools/email-listener'
					},
					{
						label: "Nicelabel DB Generator",
						link: '/tools/nicelabel'
					}
				]
			})
		}

		if (current_user.hasPermission('stats_access', 'r')) {
			MENU_ITEMS.push({
				label: 'Stats Prod',
				link: '/stats',
				matches: [
					"/stats"
				]
			})
		}

		if (current_user.hasPermission('list_users', 'r')) {
			MENU_ITEMS.push({
				label: 'Utilisateurs',
				link: '/users'
			})
		}

		console.log("current_user", current_user);

		return (
			<div className={Css.sidebarContainer}>

				<div><img id='logoImg' alt="" style={{height: 100}} src={Logo} /></div>

				<hr style={{width: '80%', color: 'black', boxShadow: '2px 2px 2px black', height: '1px', border: 'none'}}></hr>

				<List className={classes.ListLink}>
					{MENU_ITEMS.map(({...props}) => (
						this.renderLink({
							...props,
							locationPathname
						})
					))}
				</List>

				<Button className={Css.LinkLogout} variant="contained" color="secondary" onClick={e => this.props.logout()}>
					Déconnexion ({current_user.email})
				</Button>

			</div>
		)
	}

	render() {
		const { classes, drawerProps = {} } = this.props;

		return (
			<Drawer
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper,
				}}
				anchor="left"
				{...drawerProps}
			>

				{this.renderContent()}

			</Drawer>
		)
	}
}

export default withRouter(connect(
	state => ({
		current_user: state.user,
		submenus: state.sidebar
	}),
	{ logout, toggleMenu }
)(withStyles(styles)(SideBar)));

