import React from 'react'
import MaterialIcon from '@material-ui/core/Icon';


const Icon = ({
	style = {},
	name = null,
	size,
	color = 'primary',
	children,
	...props
}) =>
	<MaterialIcon style={style} color={color} size='large' {...props}>{name || children}</MaterialIcon>;


export default Icon;