import Constants from "AppCore/Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getProducts
*/
export const getProducts = () => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.getProducts();

		dispatch({
			type: C.SET_PRODUCTS,
			products: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createProduct
*/
export const createProduct = ({ product }) => async (dispatch, getState, { api, ws }) => {

	try {
		await api.createProduct({ product });
		await dispatch(getProducts());

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalProduct = ({ product_id, product }) => async (dispatch, getState, { api, ws }) => {
	const products = JSON.parse(JSON.stringify(getState().products));

	products.data.forEach((_product, i) => {
		if (_product.id === product_id) {
			products.data[i] = {
				...products.data[i],
				...product
			}
		}
	});

	dispatch({
		type: C.SET_PRODUCTS,
		products
	});
}

export const updateProduct = ({ product_id, product }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_products = {
		...(getState().products)
	}

	dispatch(updateLocalProduct({ product_id, product }));

	try {


		const result = await api.updateProduct({ product_id, product });
		dispatch(getProducts())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_PRODUCTS,
			products: rollback_products
		});
		throw e;
	}

}

export const deleteProduct = ({ product_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteProduct({ product_id });
		await dispatch(getProducts())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}