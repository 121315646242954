import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress'

import ClientOrdersTable from 'AppCore/Components/DataTable/ClientOrders';

import Api from 'AppCore/api';

import { PageContainer, Content } from 'Screens/App/Layout';
import Header from 'Screens/App/Layout/Header';

import CommonCss from '../common.css';

import Grid, { GridBox } from '../../../Components/Grid';

class KitchenScreen extends PureComponent {

	constructor(props) {
		super(props);

		const isProd = props.location.pathname === '/kitchen/clientorders/prod';

		var day = moment();
		if (isProd) {
			day = day.add(1, 'day');
		}

		this.state = {

			isProd,

			loading: true,
			tab_index: 0,

			orderDate: day,
			orderDay: day.format('YYYYMMDD'),

			clientOrders: [],
		}
	}

	setStateAsync = async (state) => {
		var result = await new Promise((resolve, reject) => {
			this.setState(state, resolve);
		})
		await new Promise((resolve) => {
			setTimeout(resolve, 0)
		})

		return result;
	}

	loadClientOrders = async () => {
		this.setState({
			loading: true
		})

		var clientOrders = (await Api.getAllClientOrders()).data;

		await this.setStateAsync({clientOrders, loading: false});
	}

	componentDidMount() {
		this.loadClientOrders();
	}

	render() {

		return (
			<PageContainer>

				<Header title="Toutes les commandes client" />

				<Content>

					<Grid cols={1} className={CommonCss.grid}>
						<GridBox className={CommonCss.gridbox}>

							{this._renderOrdersAllShops()}

						</GridBox>
					</Grid>
				</Content>
			</PageContainer>
		)
	}

	_renderLoading() {
		return (
			<CircularProgress />
		);
	}

	_renderOrdersAllShops() {

		return (
			<div className={CommonCss.tableContainer}>
				<ClientOrdersTable
					data={this.state.clientOrders}
					triggerReload={this.loadClientOrders}
				/>
			</div>
		)
	}


}


export default connect(
	state => ({
	}),
	{}
)(KitchenScreen);

