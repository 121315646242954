import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import { displayMobileMenu } from 'AppCore/Actions';
import Icon from 'AppCore/Components/Icons';

import css from './Header.module.css';

const useStyles = makeStyles(theme => ({
	button: {
		marginLeft: '10px'
	},

	menuButton: {
		marginRight: 20,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},

	headerContainer: {
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			height: 'auto',
			alignItems: 'flex-start'
		},
	}
}))

export const Button = (({ className, classes: {headerContainer, menuButton, button, ...classes} = useStyles() , ...props }) => (
	<MaterialButton {...props} classes={classes} className={classNames(className, button)} variant="outlined" color="primary" />
))

export const HeaderActionContainer = ({ children, ...props }) => (
	<div className={css.actionContainer}>
		{children}
	</div>
)

export default connect(
	state => ({
		mobileMenu: state.display.mobileMenu
	}),
	{ displayMobileMenu }
)((({ title, mobileMenu, displayMobileMenu, classes = useStyles(), children, ...props }) => (

	<div className={classNames(css.container, classes.headerContainer)}>

		<div className={css.titleContainer}>

			<IconButton className={classes.menuButton} onClick={() => displayMobileMenu({ display: !mobileMenu })}>
				<Icon name="menu" />
			</IconButton>

			{title && <h1 className={css.title}>{title}</h1>}
		</div>

		{children}
	</div>

)))