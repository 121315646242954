import React, { useState, useEffect, useRef } from "react";
import { withSnackbar } from 'notistack';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import Form from 'AppCore/Components/Form/formFields';
import Button from 'AppCore/Components/Button';

import css from './styles.css';

export default withSnackbar(({
	enqueueSnackbar, closeSnackbar,
	getData = async () => ({}), updateData = async () => true,
	onSaved = () => {},
	disabled = false,
	attributes: _attributes = {},
	className = "",
}) => {

	const form = useRef(null);
	const [ data, setData ] = useState({});

	const [ hasError, setHasError ] = useState(false);
	const [ loading, setLoading ] = useState(true);
	const [ initialLoading, setInitialLoading ] = useState(true);
	const [ saving, setSaving ] = useState(false);
	const [ changed, setChanged ] = useState(false);

	useEffect(() => {
		(async () => {
			const _data = (await getData()) || {};

			const data = {};
			Object.keys(_attributes).forEach(key => {
				data[key] = _data[key];
			})

			setData(data);
			setLoading(false);
			setInitialLoading(false);
		})()
	}, [])

	const onClickSave = async () => {
		if (form.current.checkAllErrors()) {
			return false;
		}

		setSaving(true);
		const key = enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await updateData(data);

			closeSnackbar(key);
			enqueueSnackbar('Saved', {variant: 'success'});

			setSaving(false);
			setChanged(false);
			await onSaved();
		} catch (e) {
			console.log(e);
			closeSnackbar(key);
			enqueueSnackbar('Error: ' + e.message, {variant: 'error'});
			setSaving(false);
		}
	}

	const attributes = {};
	Object.keys(_attributes).forEach(key => {
		attributes[key] = {..._attributes[key]};
		if (attributes[key].hasOwnProperty('disabled')) {
			return;
		}
		attributes[key].disabled = disabled || loading || saving;
	})

	if (initialLoading) {
		return <div className={className}><ActivityIndicator /></div>;
	}

	return (
		<div className={className}>
			<Form
				ref={form}
				config={{
					attributes
				}}
				values={data}
				onChange={({ values: data, hasError }) => {
					setHasError(hasError);
					setData(data);
					setChanged(true);
				}}
				className={css.form}
			/>

			<Button
				key="save"
				onClick={onClickSave}
				loading={saving}
				disabled={disabled || !changed || loading || saving || hasError}
			>
				save
			</Button>
		</div>
	)
})