export const transform_int = value => parseInt(value, 10);
export const transform_float = value => (parseFloat(value) || 0);
export const transform_lowercase = value => value.toLowerCase();
export const transform_uppercase = value => value.toUpperCase();
export const transform_alpha = _value => {
    const value = _value.match(/^[a-z]*/g);
    if (!value || !value.length) {
        return '';
    }

    return value[0];
}
export const transform_numeric = _value => {
    const value = _value.match(/^[0-9]*/g);
    if (!value || !value.length) {
        return '';
    }
    return value[0];
}
export const transform_alphanumeric = _value => {
    const value = _value.match(/^[0-9a-z]*/g);
    if (!value || !value.length) {
        return '';
    }
    return value[0];
}


export const transform_max = (_value, max) => {
    return Math.min(_value, max);
}
export const transform_min = (_value, min) => {
    return Math.max(_value, min);
}


export const transform_nospaces = _value => {
    return _value.replace(new RegExp(" ", "g"), "")
}

export const transform_cents_to_euros = _value => {
    return parseInt(_value, 10) / 100;
}
transform_cents_to_euros.reverse_name = "euros_to_cents";
transform_cents_to_euros.isFloat = true;


export const transform_euros_to_cents = _value => {
    return Math.round(_value*100);
}
transform_euros_to_cents.reverse_name = "cents_to_euros";
transform_euros_to_cents.isFloat = true;