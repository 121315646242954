import React from "react";
import { connect } from 'react-redux';

import DataTable from './index';

import { displayModal, hideModal, deletePriceList } from 'AppCore/Actions';

export default connect(() => ({

}), { displayModal, hideModal, deletePriceList })(

({
    data = [], searchQuery = '',
    sort = null,

    displayModal, hideModal, deletePriceList,

    triggerReload = () => {}

}) => {

    const onClickEdit = (id, pricelist) => displayModal({
        name: 'edit_create_pricelist',
        props: {
            pricelist_id: pricelist.id,
            onSaveOrder: () => {
                hideModal();
                triggerReload();
            },
        }
    })

    const onClickDelete = async (id, pricelist) => {

        if (!window.confirm("Voulez-vous vraiment supprimer : " + pricelist.name)) {
            return false;
        }

        try {
            await deletePriceList({ pricelist_id: pricelist.id });
            triggerReload();

        } catch (e) {
            alert(e.message);
        }
    }

    const sortedData = data

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: 'Nom', key: 'name' },
            ],
            actions: [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit },
                { icon_name: 'delete', title: "Delete", onClick: onClickDelete }
            ]
        }}
        getCell={(item, attr_name) => {
            return item.hasOwnProperty(attr_name) ? item[attr_name] : '';
        }}
    />

})