import React, { Component } from 'react';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import Cookies from 'js-cookie';

import moment from 'moment'; // eslint-disable-line
import 'Meth/libs/Moment/moment_fr'; // eslint-disable-line

import AppCss from './App.css';

import './assets/fonts/stylesheet.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { getStore } from "AppCore/Store";

import { init as InitString } from 'Meth/utils/strings';
import { initApp, initAppAsync } from 'config/init.js'; // eslint-disable-line

import Router from './Router/App';

window.Cookies = Cookies;

registerLocale('fr', fr)
setDefaultLocale('fr');

InitString();
initApp()

class App extends Component {

	state = {
		loading: true
	};

	componentDidMount() {
		initAppAsync().then(() => {
			this.setState({
				loading: false
			})
		})
	}

	render() {

		if (this.state.loading) {
			return (
				<div className={AppCss.Loading}>Loading...</div>
			)
		}

		const { store, persistor } = getStore();

		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>

					<div className={AppCss.App}>
						<Router />
				 	</div>

				</PersistGate>
			</Provider>

		);
	}
}

export default App
