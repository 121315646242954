import moment from 'moment'
import StdObject from './StdObject'

export default class MethObject extends StdObject {

	constructor(id, data) {
		super(data);
		this.id = id || null;
	}

	isMethObject() {
		return true;
	}

	getCreationDate(format) {

		// http://momentjs.com/
		let _moment = moment(this.__meta.creation_date, "X");
		if (format) {
			return _moment.format(format);
		}

		return _moment.format("ll")
	}

}