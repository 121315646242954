import React from "react";
import { withRouter } from "react-router";
import css from './HeaderUnlogged.module.css';
import Button from "AppCore/Components/Button";

import Logo from 'Images/logo/shakishaki_logo_RVB.png';

export default withRouter(({ history, ...props }) => (
	<div className={css.container}>
		<div className={css.logoContainer} onClick={() => history.push('/')}>
			<img
				alt="" className={css.logo}
				src={Logo}
			/>
		</div>

		<div className={css.actionContainer}>
			<Button onPress={() => history.push('/login')}>
				Sign in
			</Button>
		</div>
	</div>
))