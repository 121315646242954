import React, { Component } from 'react'
import { connect } from "react-redux";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { getById } from 'AppCore/Store/helper';
import User from 'AppCore/models/User';

import { withHasPermissions } from 'AppCore/Components/HasPermissions';

import { PageContainer, Content } from 'Screens/App/Layout';
import Header from 'Screens/App/Layout/Header';

import CommonCss from '../../common.css';

import Orders from './Orders'
import EditClient from './Edit'
import EditProductListInfos from './EditProductListInfos'
import Invoices from './Invoices'

const confirmLeavePageMessage = "Are you sure you want to leave without saving modifications ?";


class ShopDetailsScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tab_index: 0,
		}
	}

	componentDidMount = async () => {
		window.onbeforeunload = this.onbeforeunload

		const { state } = this.props.history.location;

		if (state && state.edit) {
			this.setState({
				tab_index: 1
			})
		}
	}

	onbeforeunload = () => {
		if (!this.canLeavePage()) {
			return confirmLeavePageMessage;
		}
	}

	canLeavePage = () => {
		return !(this.orders && this.orders.isModified() && !window.confirm(confirmLeavePageMessage))
	}

	onChangeTab = (event, value) => {
		console.log("onChangeTab", value);
		if (!this.canLeavePage()) {
			return false;
		}
		this.setState({tab_index: value})
	}

	getShop() {
		return this.props.shop;
	}

	currentUserIsAdmin = () => {
		var { current_user } = this.props;
		current_user = new User(current_user.id, current_user);
		return current_user.isAdmin()
	}

	render() {

		const shop = this.getShop();

		if (!shop) {
			return (
				<div className={CommonCss.page}>
					<div className={CommonCss.title}>
						<h1>
							Loading
						</h1>
					</div>
				</div>
			)
		}

		const TabList = [
			{ label: "Commandes", render: () => <Orders shop={shop} />, enable: true },
			{
				label: "Modifier client",
				render: () => (
					<EditClient client_id={shop.id} />
				),
				enable: this.props.hasPermissions(['edit_client'])
			},
			{
				label: "Options Produits",
				render: () => (
					<EditProductListInfos client_id={shop.id} />
				),
				enable: this.props.hasPermissions(['edit_client'])
			},

			{
				label: "Factures",
				render: () => (
					<Invoices client_id={shop.id} />
				),
				enable: this.props.hasPermissions(['edit_client'])
			}
		];

		return (
			<PageContainer fullWidth>
				<AppBar position="static" color="default">
					<Toolbar>
						<Header title={shop.name} />

					</Toolbar>

					<Tabs value={this.state.tab_index} onChange={this.onChangeTab} variant="fullWidth">
						{TabList.map(({ label, enable }, i) => (
							enable && <Tab label={label} key={i} />
						))}
					</Tabs>
				</AppBar>

				<Content>
					{TabList.map(({ render, enable }, i) => (
						enable && this.state.tab_index === i && <React.Fragment key={i}>{render()}</React.Fragment>
					))}
				</Content>
			</PageContainer>
		)
	}
}


const ShopDetailsScreenConnected = connect(
	state => ({
		shops: (state.shops && state.shops.data) || [],
		current_user: state.user
	}),
	{}
)(props => {

	const shop_id = props.match.params.id;
	const shop = getById(shop_id, props.shops);

	return <ShopDetailsScreen shop={shop} shop_id={shop_id} {...props} />
});

export default withHasPermissions(ShopDetailsScreenConnected)