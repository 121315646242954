import React, { PureComponent } from 'react'
import { connect } from "react-redux";

import { getSettings } from 'Meth/config/settings'
import Api from 'AppCore/api';
import User from 'AppCore/models/User'
import ActivityIndicator from "AppCore/Components/ActivityIndicator";

import Form from 'AppCore/Components/Form/formFields';
import Button from 'AppCore/Components/Button';

import { PageContainer, Content } from '../../Layout';
import Header from '../../Layout/Header';


class UserScreen extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
            user: null,
            loading: false,
		}
	}

	componentDidMount() {
		this.loadUser()
	}

	renderLoading() {
		return <ActivityIndicator />
	}

	loadUser = async () => {
        const { params: { id: user_id } = {} } = this.props.match;

		this.setState({
            user: await Api.getUser({ user_id }),
        });
    }

    onClickSave = async e => {
        const { user } = this.state;
        this.setState({ loading: true });
        await Api.updateUser({ user_id: user.id, user });
        await this.loadUser();
        this.setState({ loading: false });
    }

    getFormAttr = () => {

        const { user, loading } = this.state;

        const PERMISSIONS = getSettings().PERMISSIONS;

        const attributes = {};
        const values = {
            ...(user.permissions || {})
        }
        Object.keys(PERMISSIONS).forEach(permission_key => {
            attributes[permission_key] = {
                label: (PERMISSIONS[permission_key].description || '')+ `( ${permission_key} )`,
                type: 'switch',
                disabled: loading
            }

            if (values.admin && permission_key !== 'admin') {
                values[permission_key] = true;
                attributes[permission_key].disabled = true;
            }
        })

        return {
            values,
            config: { attributes }
        }
    }

    onChangeUserProps = ({ values: user, hasError }) => {
        const current_user = this.state.user || {};
        console.log("user", user);
        this.setState({
            hasError,
            user: {
                ...current_user,
                ...user
            }
        })
    }

    onChangePermissions = ({ values: permissions, hasError }) => {
        const current_permissions = this.state.user.permissions || {};
        this.setState({
            hasError,
            user: {
                ...this.state.user,
                permissions: {
                    ...current_permissions,
                    ...(
                        (current_permissions.admin || permissions.admin) ? { admin: permissions.admin } : permissions
                    )
                }
            }
        })
    }

	render() {

        const { user, loading } = this.state;

		if (!user) {
            return this.renderLoading();
        }

		return (
			<PageContainer>

				<Header title={user.email}>
				</Header>

				<Content style={{textAlign: "left"}}>
                    <Form
                        innerRef={form => this.formUser = form}
                        values={{
                            disabled: user.disabled
                        }}
                        config={{
                            attributes: {
                                disabled: {
                                    label: "Désactiver l'utilisateur", type: 'switch', disabled: loading
                                }
                            }
                        }}
                        onChange={this.onChangeUserProps}
                    />
                    <Form
                        innerRef={form => this.formPermission = form}
                        {...this.getFormAttr()}
                        onChange={this.onChangePermissions}
                    />
                    <Button onClick={this.onClickSave} loading={loading} disabled={loading}>Save</Button>
				</Content>

			</PageContainer>
		);
	}

}

export default connect(
	state => ({
		current_user: new User(state.user.id, state.user)
	}),
	{}
)(UserScreen);












