import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from "react-redux";
import { SnackbarProvider } from 'notistack';

import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import MUITheme from 'AppCore/MaterialUI/Theme';
import MUIThemeSideBar from 'AppCore/MaterialUI/Theme/sidebar';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import { getShops } from 'AppCore/Actions';
import { logout, getProducts, getPriceLists, displayMobileMenu, refreshCurrentUser } from 'AppCore/Actions';

import Modals from './Modals'
import Sidebar from './Sidebar'
import Css from './common.css'


const styles = StyleSheet.create({
	container: {
		flex:1,
		flexDirection:'row',
		minHeight:'100%',
		height: '100%'
	},

	sidebar: {
		width:'20%',
		height:'100%',
		minWidth: 200,
		maxWidth: 300,
		backgroundColor: '#263238',
		borderRightWidth: 1,
		borderRightColor: 'white'
	},

	content: {
		flex:1,
		flexGrow: 1,
		height: '100%',
		backgroundColor: '#fff'
	}
})


class AppScreen extends Component {

	constructor(props) {
		super(props);
		this.notistackRef = React.createRef();
	}
	componentDidMount() {
		if (this.props.userLogged) {
			this.props.getShops();
			this.props.getProducts();
			this.props.getPriceLists();

			this.props.refreshCurrentUser();
		}

	}

	componentDidUpdate(prevProps) {
		if (this.props.userLogged !== prevProps.userLogged) {
			this.props.getShops();
		}
	}

	renderSideBar() {

		if (!this.props.userLogged) {
			return;
		}

		return (
			<MUIThemeSideBar>
				<Hidden smUp implementation="css">
					<Sidebar
						drawerProps={{
							variant: 'temporary',
							open: this.props.mobileMenu,
							onClose: () => this.props.displayMobileMenu({ display: false })
						}}
						location={this.props.location} style={styles.sidebar}
					/>
				</Hidden>
				<Hidden smDown implementation="css">
					<Sidebar location={this.props.location} style={styles.sidebar}/>
				</Hidden>
			</MUIThemeSideBar>
		)

	}

	renderLoading() {

		const { loading } = this.props.display;
		// const loading = true;
		if (!loading) {
			return null;
		}

		return (
			<div className={Css.loadingContainer}>
				<ActivityIndicator size="big" material/>
			</div>
		)
	}

	onClickDismiss = key => () => {
		this.notistackRef.current.closeSnackbar(key);
	}

	render() {
		return (
			<MUITheme>
				<SnackbarProvider ref={this.notistackRef} maxSnack={3} autoHideDuration={2000}
					action={key => (
						<Button color="secondary" onClick={this.onClickDismiss(key)}>
							<Icon>close</Icon>
							Fermer
						</Button>
					)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					<View style={styles.container}>
						{this.renderLoading()}
						{this.renderSideBar()}
						<Modals />
						<View style={styles.content}>
							{this.props.children}
						</View>
					</View>
				</SnackbarProvider>
			</MUITheme>
		)
	}

}

export default connect(
	state => ({
		userLogged: state.authData.userLogged,
		display: state.display,
		mobileMenu: state.display.mobileMenu
	}),
	{ getShops, logout, getProducts, getPriceLists, displayMobileMenu, refreshCurrentUser }
)(AppScreen);


