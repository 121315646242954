import React, { Component } from "react";
import { connect } from "react-redux";
import { magicLinkLogin } from "AppCore/Actions";
import queryString from 'query-string';

import { Redirect } from 'react-router-dom'

class LoginScreen extends Component {

    state = { error: false }

    componentDidMount = async () => {
        const { code = null, email = null } = queryString.parse(this.props.location.search);

        if (!code || !email) {
            this.setState({
                error: "Missing code or email"
            })
            return false;
        }

        try {
            await this.props.magicLinkLogin({
                email,
                code
            });
        } catch (e) {
            console.log(e);
            this.setState({
                error: e.message
            })
        }
    }

	render() {
        const { userLogged } = this.props;
        const { error } = this.state;

        if (userLogged) {
            return <Redirect to="/" />;
        }
        if (error) {
            return <div>{error}</div>
        }
		return (
			<div>Logging in...</div>
		);
	}

}

export default connect(
	state => ({
        userLogged: state.authData.userLogged
    }),
	{ magicLinkLogin }
)(LoginScreen);