import React from 'react'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Button from 'AppCore/Components/Button';

import css from './styles.css';

class CustomInput extends React.PureComponent {
    render() {
        const { value, onClick } = this.props;
        return (
            <Button variant="outlined" color="primary" onClick={onClick}>
                {value}
            </Button>
        );
    }
}
export default class DateRangeSelector extends React.PureComponent {

    onChange = ([ startDate, endDate ]) => {
        const { onChange = () => {}, maxPeriodDay = 30 } = this.props;

        if (moment(endDate).isBefore(moment(startDate))) {
            if (startDate === this.props.startDate) {
                startDate = moment(endDate).subtract(7, 'days').toDate()

            } else if (endDate === this.props.endDate) {
                endDate = moment(startDate).add(7, 'days').toDate()
            }
        } else {
            const diff_days = Math.floor((moment(endDate).valueOf() - moment(startDate).valueOf()) / (3600*24*1000));
            if (diff_days > maxPeriodDay) {
                if (startDate === this.props.startDate) {
                    startDate = moment(endDate).subtract(maxPeriodDay, 'days').toDate()

                } else if (endDate === this.props.endDate) {
                    endDate = moment(startDate).add(maxPeriodDay, 'days').toDate()
                }
            }

        }

        return onChange([ startDate, endDate ]);
    }

    render() {

        const {
            startDate = new Date(), endDate = new Date(),
            style = {}
        } = this.props;

        return (
            <div className={css.container} style={style}>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={startDate => this.onChange([startDate, endDate])}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<CustomInput />}
                />
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={endDate => this.onChange([startDate, endDate])}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<CustomInput />}
                />
            </div>
        )
    }



}