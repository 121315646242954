import React from 'react';
import { formatTimestamp } from 'AppCore/Utils/Date';

export default ({ object, style }) => (
    <div style={{
        color:"white", backgroundColor:'black',
        padding: "10px",
        ...style,
    }}>
        {object.last_modified_by && object.last_modified_by.email &&
            <div>--> Dernière modification le {formatTimestamp(object.__meta.update_date*1000)} par {object.last_modified_by.email}</div>
        }
        {object.created_by && object.created_by.email &&
            <div>--> Créé le {formatTimestamp(object.__meta.creation_date*1000)} par {object.created_by.email}</div>
        }
    </div>
)