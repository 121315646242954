import MethObject from './MethObject'
import StdObject from './StdObject'
import Picture from './Picture'

class UserSettings extends StdObject {
	getModel() {
		return {
			all_cities: true,
			new_hot_algo: true
		};
	}
}

class UserPreferences extends StdObject {
	getModel() {
		return {
			shoeSize: true
		};
	}
}

class UserInstagramData extends StdObject {
	getModel() {
		return {
			id: true,
			username: true,
			access_token: true
		}
	}
}

var _model = {
	email: true,
	first_name: true,
	last_name: true,
	display_name: true,
	shoeSize: true,
	picture: Picture,

	is_employee: true,
	placer_id: true,

	ig_medias_crawled: true,

	instagram: UserInstagramData,
	settings: UserSettings,
	preferences: UserPreferences,
};

function getObjectKeyWithDefault(o, key, _default) {
	var return_default = () => {
		if (_default === undefined) {
			throw new Error(key + " is not defined");
		}

		return _default;
	};

	if (o === undefined || o === null) {
		return return_default();
	}

	if (o[key] === undefined) {
		return return_default();
	}

	return o[key];
}

export default class User extends MethObject {

	getModel() {
		return _model;
	}

	getDisplayName() {

		if (this.display_name !== null) {
			return this.display_name;
		}

		return this.first_name + " " + this.last_name;
	}

	isLinkedInstagram() {
		return (this.instagram !== null && this.instagram.id !== null);
	}

	getSetting(key, _default) {
		return getObjectKeyWithDefault(this.settings, key, _default);
	}


	getPreference(key, _default) {
		return getObjectKeyWithDefault(this.preferences, key, _default);
	}


}