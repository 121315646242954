import initialState from "../initialState";
import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.user, action) => {
	switch (action.type) {
	case C.SET_USER:
	case C.SET_CURRENT_USER:
		return action.user;
	default:
		return state;
	}
}