import React from "react";
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import get from 'lodash.get';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import User from 'AppCore/models/User';
import Api from 'AppCore/api';
import { displayModal, hideModal } from 'AppCore/Actions';
import DataTable from './index';

import { search } from 'AppCore/Utils/Search';
import { getMinDate, isDateAutorized } from 'AppCore/Utils/ClientOrder';

import Style from './style.module.css';

export default connect(state => ({
    current_user: state.user,
    access_token: state.authData.access_token
}), { displayModal, hideModal })(
    withSnackbar(({
        data = [], searchQuery = '',

        triggerReload = () => {},

        sort = 'date',

        current_user,
        displayModal, hideModal,

        enqueueSnackbar

    }) => {

        let user = null;
        if (current_user) {
            user = new User(current_user.id, current_user)
        }

        const filteredObjs = search({ query: searchQuery, data, attrs: ['id']})

        if (typeof sort === 'function') {
            filteredObjs.sort(sort);
        } else {
            filteredObjs.sort((order_a, order_b) => {

                const order_a_day = moment(order_a.day);
                const order_b_day = moment(order_b.day);

                if (order_a_day.isSame(order_b_day)) {
                    return order_b_day.hour_delivery > order_a_day.hour_delivery ? -1 : 1;
                }

                return order_a_day.isSameOrAfter(order_b_day)
                    ? -1
                    : 1;
            })
        }



        const onClickCopy = (id, client_order) => displayModal({
            name: 'edit_create_clientorder',
            props: {
                shop_id: client_order.shop_id,
                editOrder: {
                    ...client_order,
                    external_reference: null,
                    day: getMinDate().format('YYYYMMDD'),
                    id: null
                },
                onSaveOrder: () => {
                    triggerReload();
                    hideModal();
                },
            }
        })

        const onClickEdit = (id, client_order) => displayModal({
            name: 'edit_create_clientorder',
            props: {
                shop_id: client_order.shop_id,
                editOrder: client_order,
                onSaveOrder: () => {
                    hideModal();
                    triggerReload();
                },
            }
        })

        const onClickView = (id, client_order) => displayModal({
            name: 'edit_create_clientorder',
            props: {
                shop_id: client_order.shop_id,
                editOrder: client_order,
                read_only: true
            }
        })

        const canEdit = (client_order, permission) => {

            if (!user) {
                return false;
            }

            if (user.isAdmin() || user.hasPermission('force_edit_clientorder')) {
                return true;
            }

            if (!user.hasPermission(permission)) {
                return false;
            }

            const isAuthorized = isDateAutorized(client_order)
            return isAuthorized
        }

        const hasPermission = permission => {
            if (!user) {
                return false;
            }
            return user.hasPermission(permission);
        }

        const onDeleteClientOrder = async (id, client_order) => {

            if (!window.confirm("Supprimer la commande " + client_order.key + " ?")) {
                return false;
            }

            try {
                await Api.deleteClientOrder({ order_id: client_order.id });
            } catch (e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
            triggerReload();
        }

        return <DataTable
            data={filteredObjs}
            config={{
                cells: [
                    { title: 'Ref unique', key: 'key' },
                    { title: 'Client', key: 'shop.name' },
                    { title: 'Date Prod', key: 'day_prod' },
                    { title: 'Date de livraison', key: 'day' },
                    { title: 'Total Product', key: 'total_product' },
                ],
                actions: [
                    { icon_name: 'visibility', title: "View", onClick: onClickView },
                    () => (hasPermission('create_clientorder') ? { icon_name: 'file_copy', title: "Edit", onClick: onClickCopy } : null),
                    client_order => (canEdit(client_order, 'edit_clientorder') ? { icon_name: 'edit', title: "Edit", onClick: onClickEdit } : null),
                    client_order => (canEdit(client_order, 'delete_clientorder') ? { icon_name: 'delete', title: "Delete", onClick: onDeleteClientOrder } : null),
                    client_order => (<Button key='pdf_download' variant="contained" color="secondary" className={Style.ActionIcon} onClick={e => {
                        window.open(client_order.delivery_slip_link);
                    }}>
                        <Icon className={Style.ButtonIcon}>cloud_download</Icon>
                        download PDF
                    </Button>)
                ]
            }}
            getSeparator={(order_a, order_b) => {
                let date = null;
                if (order_a === null) {
                    date = order_b.day;
                } else if (order_b !== null && order_b.day !== order_a.day) {
                    date = order_b.day;
                } else if (order_b !== null && order_b.hour_delivery !== order_a.hour_delivery) {
                    date = order_b.day;
                }

                if (date === null) {
                    return null;
                }

                return (
                    <div className={Style.ClientOrderSeparator + (order_a === null ? (' ' + Style.ClientOrderSeparatorFirst) : '')}>
                        {moment(date).format('dddd LL')} ({order_b.hour_delivery + "h"})
                    </div>
                );
            }}
            getCell={(item, attr_name) => {

                if (attr_name === 'total_product') {
                    return (
                        item.productList && item.productList.length > 0 &&
                        item.productList.map(p => parseInt(p.amount, 10)).reduce((accumulator, amount) => accumulator + amount)
                    ) || 0;
                }

                if (attr_name === 'day') {
                    return moment(get(item, attr_name)).format("dddd LL") + ` (${item.hour_delivery + "h"})`;
                }

                if (attr_name === 'day_prod') {
                    if (!get(item, attr_name)) {
                        return "";
                    }
                    return moment(get(item, attr_name)).format("dddd LL");
                }

                return get(item, attr_name) || "";
            }}
        />

    })
)