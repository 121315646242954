import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { displayModal, hideModal } from 'AppCore/Actions'
import { search } from 'AppCore/Utils/Search';

import DataTable from './index';
import Style from './style.module.css';


export default connect(
    () => ({ }),
	{ displayModal, hideModal }
)(withRouter(({
    data = [], searchQuery = '',
    history,

    displayModal
}) => {

    const filteredObjs = search({ query: searchQuery, data, attrs: ['key', 'name', 'sku']})

    return <DataTable
        data={filteredObjs}
        config={{
            cells: [
                { title: "Key", key: 'navlink' },
                { title: 'Name', key: 'name' },
            ],
            actions: [
                item => (
                    <Button key='pdf_download' color="primary" variant="contained" className={Style.ActionIcon} onClick={e => {
                        displayModal({
                            name: 'edit_create_clientorder',
                            props: {
                                shop_id: item.id,
                                onSaveOrder: console.log
                            }
                        })
                    }}>
                        <Icon style={{marginRight: '10px'}}>add</Icon>
                        Nouvelle Commande
                    </Button>
                ),
                item => (
                    <IconButton className={Style.Navlink} key="edit_icon" onClick={() => {
                        history.push("/shops/" + item.id, { edit: true });
                    }}>
                        <Icon color="primary">edit</Icon>
                    </IconButton>
                )
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'navlink') {
                return (
                    <NavLink to={"/shops/" + item.id}>{item.key}</NavLink>
                )
            }
            if (attr_name === 'name') {
                return (
                    <NavLink to={"/shops/" + item.id}>{item.name}</NavLink>
                )
            }

            if (attr_name === 'color') {
                return (
                    <div style={{ textAlign: 'center', padding: '5px', backgroundColor: item.color, color: 'white' }}>
                        {item.color}
                    </div>
                )
            }
            return item.hasOwnProperty(attr_name) ? item[attr_name] : '';
        }}
    />

}))