import React, { PureComponent } from 'react'

import Css from './style.css';

export class GridBox extends PureComponent {

	static defaultProps = {
		className: "",
		classNameContent: "",
		title: null
	}

	render() {
		return (
			<div className={Css.boxContent + " " + this.props.className}>
				{this.props.title &&
					<div className={Css.boxContentTitle}>{this.props.title}</div>
				}
				<div className={Css.boxContentMain + " " + this.props.classNameContent}>
					{this.props.children}
				</div>
			</div>
		)
	}
}


export default class Grid extends PureComponent {

	static defaultProps = {
		className: "",
		cols: 3
	}

	render() {

		var style_grid = {}
		var style_box = {
			width: "calc("+(100/this.props.cols)+"% - 30px)"
		}

		var children;
		if (this.props.children.length === undefined) {
			children = [this.props.children]
		} else {
			children = this.props.children
		}

		return (
			<div className={[Css.grid, this.props.className].join(' ')} style={style_grid}>
				{children.map((child, index) => {

					var style = style_box;
					if (child.props.cols) {
						var cols = Math.max(1, Math.min(child.props.cols, this.props.cols));
						style = {
							width: "calc("+(cols*100/this.props.cols)+"% - 30px)"
						}
					}

					return (
						<div key={index} className={Css.box} style={style}>
							{child}
						</div>
					)
				})}
			</div>
		)
	}
}