import initialState from "AppCore/Store/initialState";
import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.orders, action) => {
	switch (action.type) {
	case C.SET_ORDER:
		return {
			...state,
			...action.orders,
			loaded: true
		};
	case C.CLEAR_ORDER:
		return initialState.orders;
	default:
		return state;
	}
}