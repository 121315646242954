import React, { PureComponent } from "react";

import Modal, { ModalContent } from 'AppCore/Components/Modal';

import PriceListScreen from 'Screens/App/PriceList';

export default class PriceListModal extends PureComponent {

	static defaultProps = {
		onSaved: () => {},
		onClose: () => {},
	}

	handleClose = () => {
		const { onClose = () => {} } = this.props;

		onClose();
    }

    getTitle() {
		const { pricelist_id } = this.props;
		return pricelist_id ? "Modifier une liste de prix" : "Nouvelle liste de prix"
	}

	render() {

		return (

			<Modal
				open
				drawer
				onClose={this.handleClose}
				title={this.getTitle()}
			>
                <ModalContent>
                    <PriceListScreen pricelist_id={this.props.pricelist_id} onSaved={this.handleClose}/>
                </ModalContent>

			</Modal>

		)
	}

}