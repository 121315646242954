import React, { Component } from "react";
import { connect } from "react-redux";
import { magicLinkLogin } from "AppCore/Actions";

import { PageContainer, Content } from '../Layout';
import HeaderUnlogged from '../Layout/HeaderUnlogged'

import LoginFlow from './loginFlow';

class LoginScreen extends Component {

	render() {
		return (
			<PageContainer>

				<HeaderUnlogged />

				<Content>
					<LoginFlow signinMethod={this.props.magicLinkLogin}/>
				</Content>

			</PageContainer>
		);
	}

}


export default connect(
	state => ({}),
	{ magicLinkLogin }
)(LoginScreen);