import React from "react";
import { withStyles } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';
import MaterialDrawer from '@material-ui/core/Drawer';
import MaterialButton from '@material-ui/core/Button';
import classNames from 'classnames';

import css from './style.module.css';

const styles = theme => ({
	paper: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		paddingTop: '10px',
		outline: 'none',

		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',

		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	},

	paperDrawer: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		paddingTop: '10px',
		outline: 'none',

		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		flex: 1,
	},

	paperContent: {
		flex: 1,
		overflow: 'auto'
	},

	modalTitle: {
		width: '100%',
		textAlign: 'left'
	},

	button: {

	}
});


const _Modal = ({ children, classes, title, actions, ...props }) => (

	<MaterialModal
		{...props}
	>
		<div className={classes.paper}>
			<div className={classes.paperContent}>
				{title &&
					<ModalTitle>{title}</ModalTitle>
				}
				{children}
			</div>
			{actions && actions.length &&
				<ModalActions>{actions}</ModalActions>
			}
		</div>
	</MaterialModal>

)
export const Modal = withStyles(styles)(_Modal);

class _ModalDrawer extends React.PureComponent {

	state = { ready: false }

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				ready: true
			})
		}, 0);
	}

	render() {
		const { children, classes, title, actions, open, ...props } = this.props;

		return (
			<MaterialDrawer
				{...props}
				open={open && this.state.ready}
				anchor="right"
				className={css.materialDrawer}
			>
				<div className={css.drawerContent}>

					{title &&
						<ModalTitle>{title}</ModalTitle>
					}

					{children}

					{actions && actions.length &&
						<ModalActions>{actions}</ModalActions>
					}
				</div>
			</MaterialDrawer>
		)
	}
}
export const ModalDrawer = withStyles(styles)(_ModalDrawer);



export const ModalTitle = withStyles(styles)(({ classes, children, ...props }) => (
	<div className={css.modalTitle} {...props}>
		<h1>{children}</h1>
	</div>
))

export const ModalContent = withStyles(styles)(({ classes, children, ...props }) => (
	<div className={css.modalContentContainer} {...props}>
		{children}
	</div>
))

export const ModalActions = withStyles(styles)(({ classes, children, ...props }) => (
	<div className={css.modalActionContainer} {...props}>
		{children}
	</div>
))

export const ModalButton = withStyles(styles)(({ className, classes: { button } , ...props }) => (
	<MaterialButton className={classNames(className, button)} {...props} />
))



export default ({ drawer = false, ...props }) => (drawer ? <ModalDrawer {...props} /> : <Modal {...props} />);




