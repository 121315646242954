import assign from 'object-assign';

var AppSettings = assign({
	'user-agent' : '',
}, {});


export function setSetting(key, value) {
	AppSettings[key] = value;
}

export function getSetting(key, _default) {
	if (AppSettings[key] === undefined) {
		if (_default !== undefined) {
			return _default;
		}
		throw new Error(key + " is not defined in AppSettings")
	}

	return AppSettings[key];
}


export default AppSettings;