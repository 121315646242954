import React from 'react';

import { getSettings } from 'Meth/config/settings'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export default ({ value, onChange = value => {}, ...props }) => (
    <TextField
        {...props}
        select
        variant="outlined"
        value={value || ""}
        onChange={
            ({ target: { value } }) => {
                onChange(value);
            }
        }
    >
        {Object.keys(getSettings().CONTAINERS).map(container_type => (
            <MenuItem key={container_type} value={container_type}>{getSettings().CONTAINERS[container_type].name}</MenuItem>
        ))}

    </TextField>
)