import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { debounce } from 'Meth/utils/functions'
import Icon from 'AppCore/Components/Icons';

import { setSearchQueries } from 'AppCore/Actions';



const SearchFieldInput = ({id, onChange = () => {}, value = '', ...props}) => (
	<TextField
		id={id}
		label="Search"
		placeholder="Search"
		value={value}
		onChange={onChange}

		InputProps={{
			startAdornment: <InputAdornment position="start"><Icon name="search" /></InputAdornment>
		}}
	/>
)


class SearchField extends PureComponent {

	static defaultProps = {
		placeholder: "",
		debounce: 10,
		className: "",
		onChange: () => {}
	}

	constructor(props) {
		super(props);

		let value = "";
		if (this.props.connectTo) {
			value = this.props.searchQueries[this.props.connectTo] || "";
		}

		this.state = {
			value
		}

		this.triggerTextChange_debounced = debounce(this.triggerTextChange, this.props.debounce)
	}

	onChange = (e) => {
		const text = e.target.value;
		this.setState({
			value: text
		});

		this.triggerTextChange_debounced(text);
	}

	triggerTextChange = value => {
		const { connectTo, setSearchQueries } = this.props;
		if (connectTo) {
			setSearchQueries({ name: connectTo, value });
		} else {
			this.props.onChange(value);
		}
	}

	render() {
		return (
			<SearchFieldInput
				id="outlined-with-placeholder"
				label="Rechercher"
				placeholder="Rechercher"
				variant="outlined"
				onChange={this.onChange}
				value={this.state.value}
			/>
		);
	}
}

export default connect(
	state => ({
		searchQueries: (state.searchQueries) || {}
	}),
	{ setSearchQueries }
)(SearchField);