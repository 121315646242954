import Constants from "AppCore/Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getShops
*/
export const getShops = () => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.getShops();

		dispatch({
			type: C.SET_SHOPS,
			shops: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createShop
*/
export const createShop = ({ shop }) => async (dispatch, getState, { api, ws }) => {

	try {
		await api.createShop({ shop });
		await dispatch(getShops());

	} catch (e) {
        console.error(e);

        throw e;
	}
}


export const updateLocalShop = ({ shop_id, shop }) => async (dispatch, getState, { api, ws }) => {
	const shops = JSON.parse(JSON.stringify(getState().shops));

	shops.data.forEach((_shop, i) => {
		if (_shop.id === shop_id) {
			shops.data[i] = {
				...shops.data[i],
				...shop
			}
		}
	});

	dispatch({
		type: C.SET_SHOPS,
		shops
	});
}

export const updateClientProductListInfos = ({ shop_id, shop }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_shops = {
		...(getState().shops)
	}

	dispatch(updateLocalShop({ shop_id, shop }));

	try {


		const result = await api.updateClientProductListInfos({ shop_id, shop });
		dispatch(getShops())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_SHOPS,
			shops: rollback_shops
        });

        throw e;
	}

}

export const updateShop = ({ shop_id, shop }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_shops = {
		...(getState().shops)
	}

	dispatch(updateLocalShop({ shop_id, shop }));

	try {


		const result = await api.updateShop({ shop_id, shop });
		dispatch(getShops())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_SHOPS,
			shops: rollback_shops
        });

        throw e;
	}

}

export const deleteShop = ({ shop_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteShop({ shop_id });
		await dispatch(getShops())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}