import React, { PureComponent } from "react";
import MuiButton from '@material-ui/core/Button';
import Icon from 'AppCore/Components/Icons';
import { ActivityIndicator } from "react-native";

class Button extends PureComponent {

	render() {
		const {
			children,
			icon,
			color = 'primary',
			loading = false,
			...props
		} = this.props;

		// "inherit","primary","secondary","action","error","disabled"

		if (props.onPress) {
			props.onClick = props.onPress;
			delete props.onPress;
		}

		return (
			<MuiButton color={color} variant="contained" {...props} >
				{loading &&
					<ActivityIndicator size='small' style={{marginRight: 4}} />
				}
				{icon && !loading &&
					<Icon style={{marginRight: 4}} color='inherit' name={icon} />
				}
				{children}

			</MuiButton>
		);
	}
}

export default Button;