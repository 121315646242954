import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ActivityIndicator from "AppCore/Components/ActivityIndicator";

import Api from 'AppCore/api'

import { PageContainer, Content } from '../../Layout';
import Header from '../../Layout/Header';


class DeliverooTool extends Component {

	state = {
        loaded: false,
        products : null, modalEdit: null, modalCreate: null, searchQuery: '',

        prices: []
    }

	componentDidMount = async () => {
		this.setState({
            prices: await Api.getDeliverooPrices(),
            loaded: true
        });
	}

	renderLoading() {
		return <ActivityIndicator />
	}

	render() {
        const { loaded, prices } = this.state;

		if (!loaded) {
			return this.renderLoading();
		}

        let restaurants = [];
        if (Object.keys(prices).length > 0) {
            restaurants = Object.keys(prices[Object.keys(prices)[0]].price)
        }

		return (
			<PageContainer>

				<Header title="Export Deliveroo Orders" />

				<Content>
                    <Paper>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    {restaurants.map(restaurant => (
                                        <TableCell key={restaurant} align="right">{restaurant}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(prices).map(product_key => (
                                    <TableRow key={product_key}>
                                        <TableCell component="th" scope="row">
                                            {product_key + ": " + prices[product_key].name_values[0]}
                                        </TableCell>
                                        {restaurants.map(restaurant => (
                                            <TableCell key={restaurant} align="right">
                                                {prices[product_key].price[restaurant] || 0}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
				</Content>

			</PageContainer>
		);
	}

}


export default connect(
	state => ({

	}),
	{}
)(withSnackbar(DeliverooTool));