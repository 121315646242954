import React, { PureComponent } from 'react'

import { PageContainer, Content } from '../Layout';
import Header from 'Screens/App/Layout/Header';

export default class HomeScreen extends PureComponent {
	render() {
		return (
			<PageContainer>
				<Header title="Home !" />
				<Content>
					<div>
						HOME
					</div>
				</Content>
			</PageContainer>

		)
	}
}