import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import get from 'lodash.get';

import Button from 'AppCore/Components/Button';
import Form from 'AppCore/Components/Form';

import { displayModal, hideModal } from 'AppCore/Actions';
import Api from 'AppCore/api';

import SendInvoiceEmailBtn from 'Components/ActionButtons/SendInvoiceEmail';

import css from './styles.css';


class InvoiceEdit extends PureComponent {

	static defaultProps = {
        onSaved: () => {},
        onChange: async () => {}
	}

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            saving: false,
		}
    }

    getInvoiceData = async () => {
        const { invoice_id } = this.props;
        return await Api.getInvoice({ invoice_id });
    }
    updateInvoiceData = async invoice => {
        const { invoice_id } = this.props;
        const result = await Api.updateInvoice({ invoice_id, invoice });

        await this.props.onChange();
        return result;
    }

    onClickMarkAsSent = async () => {

        const { invoice : { id: invoice_id } } = this.props;
        this.setState({ btnSentLoading: true });
        await Api.markInvoiceAsSent({ invoice_id });
        await this.props.onChange();
        this.setState({ btnSentLoading: false });

        return true;
    }

    onClickMarkAsPaid = async () => {

        const { invoice : { id: invoice_id } } = this.props;
        this.setState({ btnPaidLoading: true });
        await Api.markInvoiceAsPaid({ invoice_id });
        await this.props.onChange();
        this.setState({ btnPaidLoading: false });

        return true;
    }

    onClickUnsend = async () => {
        if (!window.confirm("Enlever le status 'envoyée' ?")) {
            return false;
        }

        const { invoice : { id: invoice_id } } = this.props;
        await Api.markInvoiceAsUnsent({ invoice_id });
        await this.props.onChange();
    }

    onClickMarkAsUnpaid = async () => {
        if (!window.confirm("Enlever le status 'payée' ?")) {
            return false;
        }

        const { invoice : { id: invoice_id } } = this.props;
        await Api.markInvoiceAsUnpaid({ invoice_id });
        await this.props.onChange();
    }

	render() {
        const { invoice } = this.props;

		return (
            <div className={css.formContainer}>

                <Paper className={css.paper}>
					<h2>Infos</h2>

                    <div className={css.content}>
                        {invoice && <React.Fragment>
                            <div>Client: {get(invoice, "client.name", "")}</div>
                            <div>{invoice.name}</div>
                            <div>N°: {invoice.autoincrement}</div>
                            <div>Total TTC: {Math.round(invoice.total_price_ttc) / 100} €</div>
                            <div>#Commandes: {invoice.orders.total}</div>

                            <div className={css.chipContainer}>
                                {!invoice.status && <Chip label="Non envoyée" color="secondary" />}
                                {invoice.status && <Chip label="Envoyée" color="primary" onDelete={this.onClickUnsend} />}
                                {invoice.status !== 'paid' && <Chip label="Non Payée" color="secondary" />}
                                {invoice.status === 'paid' && <Chip label="Payée" color="primary" onDelete={this.onClickMarkAsUnpaid} />}
                            </div>

                            <div className={css.btnContainer}>
                                <Button className={css.btn} icon="cloud_download" color="primary" onClick={e => {
                                    window.open(invoice.pdf_link);
                                }}>Télécharger</Button>
                                <SendInvoiceEmailBtn className={css.btn} label="Envoyer par email" invoice={invoice} />
                                {!invoice.status &&
                                    <Button
                                        className={css.btn} icon="check" color="primary" onClick={this.onClickMarkAsSent}
                                        disabled={this.state.btnSentLoading}
                                    >Marquer comme envoyée</Button>
                                }
                                {invoice.status !== 'paid' &&
                                    <Button
                                        disabled={this.state.btnPaidLoading}
                                        className={css.btn} icon="check" color="primary"
                                        onClick={this.onClickMarkAsPaid}
                                    >Marquer comme payé</Button>
                                }
                            </div>
                        </React.Fragment>}
                    </div>

                </Paper>

				<Paper className={css.paper}>
					<h2>Editer</h2>

					<div className={css.content}>
                        {invoice && <Form
                            getData={this.getInvoiceData}
                            data={invoice}
                            updateData={this.updateInvoiceData}
                            onSaved={this.handleClose}
                            disabled={!invoice.editable}
                            attributes={{
                                name: {
                                    label: "Label / Nom", type: 'text', validators: ['notempty'],
                                },
                                discount: {
                                    label: "Remise (en %)",
                                    type: 'text',
                                    transformers: [
                                        'float',
                                        { name: 'max', value: 100 }
                                    ]
                                },
                            }}
                        />}
					</div>

				</Paper>

            </div>
		)
	}

}

export default connect(
    state => ({}),
    { displayModal, hideModal }
)(withSnackbar(InvoiceEdit));