import React, { Component } from 'react'
import { connect } from "react-redux";

import Css from './style.css';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CoreTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';

const ProductNameCell = withStyles(theme => ({
  head: {
    width: 400,
    fontSize: 18,
  },
  body: {

    width: 400
  },
}))(CoreTableCell);

const BoldCell = withStyles(theme => ({
  head: {
    padding: 0,
    fontSize: 18,
	fontWeight: 'bold'
  },
  body: {
	fontWeight: 'bold',
    padding: 0
  },
}))(CoreTableCell);

const BoldItalicCell = withStyles(theme => ({
  head: {
    padding: 0,
    fontSize: 18,
	fontWeight: 'bold',
	fontStyle: 'italic'
  },
  body: {
	fontWeight: 'bold',
	fontStyle: 'italic',
    padding: 0
  },
}))(CoreTableCell);

const TableCell = withStyles(theme => ({
  head: {
  	padding:0
  },
  body: {
  	padding:0
  },
}))(CoreTableCell);


class CatProductTableEditor extends Component {

	static defaultProps = {
		values: [],
		onChange: () => {},
		onChangeItem: () => {},
		expandCategories: false
	}

	getProductValue = ({ index, cat_id, product_id }) => {

		if (!this.props.values[index].categories[cat_id]) {
			return "";
		}

		if (!this.props.values[index].categories[cat_id].productList[product_id]) {
			return "";
		}

		return String(this.props.values[index].categories[cat_id].productList[product_id].amount);
	}

	getCatValue = ({ index, cat_id }) => {

		if (!this.props.values[index].categories[cat_id]) {
			return "";
		}

		return String(this.props.values[index].categories[cat_id].amount);
	}

	constructor(props) {
		super(props);

		var states = {};
		for (var i = 0; i < this.props.products.length; i++) {
			var category = this.props.products[i]
			states["category_opened_" + category.id] = this.props.expandCategories;
		}

		this.state = {
			...states
		}
	}

	getCatProductValuesForIndex = ({ index, cat_id, value }) => {

		const { products } = this.props;
		var values = {};

		for (var i = 0; i < products.length; i++) {

			const category = products[i];

			values[category.id] = {
				amount: this.getIntValue(this.getCatValue({ index, cat_id: category.id }), 0),
				productList: {}
			};

			if (category.id === cat_id) {
				values[category.id].amount = value;
			}

			for (var j = 0; j < category.products.length; j++) {

				values[category.id].productList[category.products[j].id] = {
					product_id : category.products[j].id,
					product_name: category.products[j].name,
					category_id: category.id,
					category_name: category.name,
					amount: this.getProductValueFromCatPourcent(category.id, category.products[j].id, index, values[category.id].amount)
				}
			}
		}

		return values;
	}


	getValuesForIndex = ({ index, product_id, value = null }) => {

		const { products } = this.props;
		var categories = {};

		for (var i = 0; i < products.length; i++) {

			const category = products[i];
			let cat_id = category.id;

			categories[cat_id] = {
				productList: {},
				amount: this.getIntValue(this.getCatValue({ index, cat_id: category.id }), 0),
			};

			for (var j = 0; j < category.products.length; j++) {

				const amount = this.getIntValue(this.getProductValue({ index, cat_id, product_id: category.products[j].id }), 0);

				categories[cat_id].productList[category.products[j].id] = {
					product_id : category.products[j].id,
					product_name: category.products[j].name,
					category_id: category.id,
					category_name: category.name,
					amount
				}

				if (product_id === category.products[j].id) {
					categories[cat_id].productList[category.products[j].id].amount = value;
				}

				// categories[cat_id].amount += categories[cat_id].productList[category.products[j].id].amount;
			}
		}

		return categories;
	}

	getIntValue(value, _default) {

		var value_int = parseInt(value, 10);

		if (!isNaN(value_int)) {
			return value_int;
		}

		return _default
	}

	getProductValueFromCatPourcent(cat_id, product_id, index, cat_value = null) {

		const { cat_pourcents } = this.props;

		if (!cat_pourcents[cat_id] || !cat_pourcents[cat_id][product_id]) {
			return 0;
		}

		const total = cat_value === null ? this.getIntValue(this.getCatValue({ cat_id, index}), 0) : cat_value;

		return Math.ceil(cat_pourcents[cat_id][product_id] * total / 100);
	}

	handleChangeCatValue = ({ index, cat_id, product_id }) => event => {

		const value = this.getIntValue(event.target.value.trim(), 0);

		let newShopProductList = this.getCatProductValuesForIndex({ index, cat_id, value });
		this.props.onChangeItem(index, newShopProductList);
	}

	handleChangeProductValue =  ({ index, cat_id, product_id }) => event => {
		const value = this.getIntValue(event.target.value.trim(), 0);

		this.props.onChangeItem(index, this.getValuesForIndex({ index, cat_id, product_id, value }));
	}

	handleOpenCategoryClick = cat_id => event => {
		this.setState({
			["category_opened_" + cat_id]: !this.state["category_opened_" + cat_id]
		});
	}

	render() {

		this.tab_index = 0;
		return (

			<Table>
				<TableHead>
					<TableRow>
						<ProductNameCell>Category / Product</ProductNameCell>
						{this.props.values.map((values, index) => (
							<TableCell key={index}>{values.title || ''}</TableCell>
						))}
						<TableCell></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{this.props.products.map((category, i) => this._renderCategory(category, i))}
				</TableBody>
			</Table>

		)
	}

	_renderCategory(category, category_index) {

		const cat_id = category.id;
		let rows = [];

		rows.push(this._renderCategoryRow(category))

		if (!this.state["category_opened_" + category.id]) {
			return rows;
		}

		for (let i = 0; i < category.products.length; i++) {

			const index = this.tab_index++;

			rows.push(this._renderProductRow(category.products[i], category.id, index));
		}

		if (this.props.displayTotalByCat) {

			let total_cells = [];

			for (let index = 0; index < this.props.values.length; index++) {
				let total = 0;
				for (let i = 0; i < category.products.length; i++) {
					let product_id = category.products[i].id;
					total += this.getIntValue(this.getProductValue({ index, cat_id, product_id }), 0);
				}

				total_cells.push(
					<BoldCell key={'total_' + index}>
						{total}
					</BoldCell>
				);
			}

			rows.push(
				<TableRow hover key={"total_" + category.id}>
					<ProductNameCell><b>TOTAL</b></ProductNameCell>
					{total_cells}
					<TableCell/>
				</TableRow>
			)
		}

		return rows;
	}


	_renderCategoryRow(category) {

		const { useCatPourcent, readOnly } = this.props;
		const cat_id = category.id;

		return (
			<TableRow hover key={category.id}>
				<ProductNameCell><b>{category.name}</b></ProductNameCell>
				{this.props.values.map((v, index) => (
					<BoldItalicCell key={category.id + '_' + index}>
						{readOnly || useCatPourcent
							? 	readOnly ? <span>{this.getCatValue({ cat_id, index})}</span> : <TextField
									value={this.getCatValue({ cat_id, index})}
									InputProps={{ inputProps: { tabIndex: (index+1)	 } }}
									onChange={this.handleChangeCatValue({ index, cat_id })}
								/>
							: 	null
						}
					</BoldItalicCell>
				))}
				<TableCell>
					<Icon
						onClick={this.handleOpenCategoryClick(category.id)}
						className={Css.expandIcon}
					>
						{this.state["category_opened_" + category.id] ? 'expand_more' : 'chevron_right'}
					</Icon>
				</TableCell>
			</TableRow>
		)
	}

	_renderProductRow(product, cat_id, _index = 0) {

		const { useCatPourcent, canEditProducts = false, readOnly = false } = this.props;
		const product_id = product.id;

		return (
			<TableRow key={product_id}>
				<ProductNameCell>{product.sku + ' - ' + product.name}</ProductNameCell>

				{this.props.values.map((v, index) => (
					<TableCell key={product_id + '_' + index}>
						{useCatPourcent && !canEditProducts
							? 	this.getProductValueFromCatPourcent(cat_id, product_id, index)

							: 	(readOnly ? this.getProductValue({ cat_id, product_id, index}) : <TextField
									value={this.getProductValue({ cat_id, product_id, index})}
									InputProps={{ inputProps: { tabIndex: (index+1)*1000+_index	 } }}
									onChange={this.handleChangeProductValue({ index, cat_id, product_id })}
								/>)
						}
						{}
					</TableCell>
				))}

				<TableCell></TableCell>
			</TableRow>
		)
	}


}


const filterDisabled = groupByCat => {
	groupByCat.forEach((cat, i) => {
		groupByCat[i].products = groupByCat[i].products.filter(p => !p.disabled);
	})
	return groupByCat.filter(g => g.products.length);
}

export default connect(
	state => ({
		products: filterDisabled(state.products.groupByCat)
	}),
	{}
)(CatProductTableEditor);




