import assign from 'object-assign';

var settings = assign({

	'OPEN': true,
	'ORDER_HOUR_LIMIT': 2000,

	'FORCE_UPDATE_APP' : false,

	'ENV' : 'prod',
	'DOMAIN' : '',
	'DOMAIN_COOKIE' : '',
	'DOMAIN_LIVE' : '',
	'HOST_SITE' : '',
	'HOST_BO' : '',
	'HOST_API' : '',
	'HOST_STATIC' : '',
	'HOST_IMAGES' : '',
	'STATIC_VERSION' : '',
	'HOST_WS' : '',

	'IPHONE_STORE_ID': null,
	'IPHONE_STORE_URL': null,

	'FB_APP_ID': null,
	'FB_SCOPES': null,
	'FB_API_VERSION': null,

	'GOOGLE_MAP_KEY': null,

	'BRANCH_KEY': null,


	FAQ_URL : 'http://faq.gotzm.com',
	PRIVACY_POLICY_URL : 'http://www.gotzm.com/privacy-policy',

	order_status: {
		'status': {},
		'status_seller' : {},
		'status_buyer': {}
	}

}, {});

settings.getStaticImgUrl = (img_path) => {

	var img_path_splitted = img_path.split('.');
	var img_ext = img_path_splitted[img_path_splitted.length - 1];
	var img_path_name = img_path_splitted.slice(0, img_path_splitted.length - 1).join('.');

	return settings.HOST_STATIC + '/image/' + img_path_name + '-v' + settings.STATIC_VERSION + '.' + img_ext;
};

settings.getImgUrl = (md5, {width, height}, ext) => {

	// d/f/2/df2126fa2edeebc92ee45ffdc857e558.{ext}
	//var md5_array = md5.split('');

	var _width = null;
	var _height = null;
	var options = "";

	if (width) {
		_width = width;
		_height = width;
	}

	if (height) {
		_height = height;
		if (!_width) {
			_width = height;
		}
	}

	if (_width && _height) {
		_width = Math.round(_width);
		_height = Math.round(_height);
		options = "/" + _width + "x" + _height;
	}

	return settings.HOST_IMAGES + '/' + md5 + options + '.' + ext;
};

export function getSettings() {
	return settings;
}

export function setSettings(_settings) {

	settings.OPEN = _settings.open;
	settings.ORDER_HOUR_LIMIT = _settings.order_hour_limit;

	settings.STATIC_VERSION = _settings.static_version;

	settings.HOST_API = _settings.hosts.api;
	settings.HOST_IMAGES = _settings.hosts.images;
	settings.HOST_SITE = _settings.hosts.site;
	settings.HOST_STATIC = _settings.hosts.static;
	settings.HOST_WS = _settings.hosts.ws;

	if (_settings.facebook) {
		settings.FB_APP_ID = _settings.facebook.client_id;
		settings.FB_SCOPES = _settings.facebook.scopes;
		settings.FB_API_VERSION = _settings.facebook.api_version;
	}

	settings.FAQ_URL = _settings.faq_url;
	settings.PRIVACY_POLICY_URL = _settings.privacy_policy_url;

	if (_settings.force_update_app) {
		settings.FORCE_UPDATE_APP = _settings.force_update_app;
	}

	if (_settings.env) {
		settings.ENV = _settings.env;
	}

	settings.PERMISSIONS = _settings.permissions || {};
	settings.CONTAINERS = _settings.containers;
}

export function setCustomSetting(customSettingKey, customSettingValue) {
	settings[customSettingKey] = customSettingValue;
}

export default settings;













