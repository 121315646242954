import ApiSettings from 'Meth/api/Settings'
import { setOnAccessTokenExpiredCallback } from 'Meth/api/request';
import { setSettings } from 'Meth/config/settings'
import { setAccessTokenGetter } from 'Meth/api/request'
import api from 'AppCore/api';
import { initStore, getStore } from "./Store";
import { logout } from 'AppCore/Actions';

export const initAppAsync = async () => {
	var conf = await ApiSettings.load()
	setSettings(conf);
}

let access_token = null;
export const initApp = ({ settings = {}, onStoreChange = () => {}, onRehydrateStore = () => {} } = {}) => {

	const storeExtraArguments = { api };
	const { store } = initStore(storeExtraArguments, _store => {
		onRehydrateStore(_store.getState());
	});

	const { authData : { access_token: store_access_token } } = store.getState();
	access_token = store_access_token;

	setAccessTokenGetter(() => {
		const { authData : { access_token } } = store.getState();
		return access_token || null;
	});

	store.subscribe(() => {

		const { authData : { access_token: store_access_token } } = store.getState();
		if (access_token !== store_access_token) {
			access_token = store_access_token;
		}

		onStoreChange(store.getState())
	});

	setOnAccessTokenExpiredCallback(() => {
		const { store } = getStore();
		store.dispatch(logout());
	})
}
