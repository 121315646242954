import get from 'lodash.get';

const match = (value, r) => {
    return value && value.match(r);
}

const matchAll = (obj, attrs, r) => {
    for (let i = 0; i < attrs.length; i++) {
        const attr = attrs[i];
        if (match(String(get(obj, attr)), r)) {
            return true;
        }
    }

    return false;
}


export const search = ({ query, data, attrs }) => {
    const r = new RegExp(query, 'i');
    return data.filter(obj => matchAll(obj, attrs, r));
}