import React, { PureComponent } from 'react'
import { connect } from "react-redux";

import { getSettings } from 'Meth/config/settings'
import Api from 'AppCore/api';
import User from 'AppCore/models/User'
import { search } from 'AppCore/Utils/Search';

import SearchField from 'AppCore/Components/SearchField';
import ActivityIndicator from "AppCore/Components/ActivityIndicator";

import { PageContainer, Content } from '../Layout';
import Header, { HeaderActionContainer } from '../Layout/Header';

import UsersTable from 'AppCore/Components/DataTable/users';

class UsersScreen extends PureComponent {

	constructor(props) {
		super(props);

		this.users_list_default = null;

		this.state = {
			users: [],
		}

		console.log('getSettings', getSettings());
	}

	setStateAsync = async (state) => {
		var result = await new Promise((resolve, reject) => {
			this.setState(state, resolve);
		})
		await new Promise((resolve) => {
			setTimeout(resolve, 0)
		})

		return result;
	}

	componentDidMount() {
		this.loadUsers()
	}

	renderLoading() {
		return <ActivityIndicator />
	}

	setListUsers = async (data) => {

		var _users = [];

		for (var i = 0; i < data.length; i++) {
			_users.push(data[i])
		}

		await this.setStateAsync({
			users: _users
		})

		return _users;
	}

	loadUsers = async () => {
		await this.setListUsers((await Api.getAllUsers()).data);
	}

	canEdit = () => {
		var { current_user } = this.props;
		current_user = new User(current_user.id, current_user);
		return current_user.hasPermission('edit_user')
	}

	render() {

        const { searchQuery, users: data } = this.state;
		const filteredObjs = search({ query: searchQuery, data, attrs: ['first_name', 'last_name', 'email']})

		console.log(data, filteredObjs);

		return (
			<PageContainer>

				<Header title="Utilisateurs">
					<HeaderActionContainer>
						<SearchField
							id="outlined-with-placeholder"
							label="Rechercher"
							placeholder="Rechercher"
							variant="outlined"
							value={searchQuery}
							onChange={({ target: { value: searchQuery }}) => this.setState({ searchQuery })}
						/>
					</HeaderActionContainer>
				</Header>

				<Content>
					<UsersTable
						data={filteredObjs}
						canEdit={this.canEdit()}
						onClickEdit={id => this.props.history.push('/users/' + id)}
					/>
				</Content>

			</PageContainer>
		);
	}


}

export default connect(
	state => ({
		current_user: state.user
	}),
	{}
)(UsersScreen);











