import React, { PureComponent } from "react";

import Modal from 'AppCore/Components/Modal';
import Form from 'AppCore/Components/Form/formFields';
import Button from 'AppCore/Components/Button';

export default class ModalForm extends PureComponent {

	static defaultProps = {
        onConfirm: () => {},
        onCancel: () => {},
		onClose: () => {},
	}

	constructor(props) {
		super(props);
		this.state = {
            loading: false,
            hasError: false,
            data: this.props.data || {}
		}
	}

	onClickConfirm = async () => {
		if (this.form.checkAllErrors() || this.state.hasError) {
			return false;
        }

        this.setState({ loading: true });
        await this.props.onConfirm(this.state.data);
	}

	render() {

        const { attributes: _attributes, title, confirmLabel = "Confirmer", cancelLabel = "Annuler" } = this.props;
        const { data, hasError, loading } = this.state;

        const attributes = {};
        Object.keys(_attributes).forEach(key => {
            attributes[key] = _attributes[key];
            if (attributes.hasOwnProperty('disabled')) {
                return;
            }
            attributes[key].disabled = loading;
        })

		return (

			<Modal
				open
				onClose={() => !loading && this.props.onClose()}
				title={title}
				actions={[
					<Button key="cancel" variant="text" color="primary" disabled={loading} onClick={this.props.onClose}>
						{cancelLabel}
					</Button>,
					<Button
						key="save"
						onClick={this.onClickConfirm}
                        disabled={loading || hasError}
                        loading={loading}
					>
						{confirmLabel}
					</Button>
				]}
			>

                <Form
                    innerRef={form => this.form = form}
                    config={{
                        attributes
                    }}
                    values={data}
                    onChange={({values: data, hasError}) => this.setState({ data, hasError })}
                />

			</Modal>

		)
	}

}