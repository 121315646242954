import moment from 'moment';

import { getSettings } from 'Meth/config/settings'

const HOUR_DELIVERY_TO_DIFF_DAY = {
    5: 1,
    20: 0
}

const getDateHour = date => date.hours()*100 + date.minutes()
const getNowHour = () => getDateHour(moment())

const getDayFromMoment = date => moment((date || moment()).format('YYYYMMDD'), 'YYYYMMDD');

export const getProdDayFromDeliveryDayHour = (day_delivery, hour_delivery) => {
    let day_prod = moment(day_delivery);

    let diff_day = 0;
    if (hour_delivery <= 5) {
        diff_day = -1;
    }

    day_prod.add(diff_day, 'days')

    return day_prod;
}

export const isTomorrowAutorized = () => getNowHour() < getSettings().ORDER_HOUR_LIMIT
export const isProdTodayAuthorized = () => getNowHour() < getSettings().ORDER_HOUR_LIMIT

const getMinProdDate = () => {
    const minProdDate = moment();
    if (!isProdTodayAuthorized()) {
        minProdDate.add(1, 'days')
    }

    return minProdDate;
}
export const getMinDate = (hour_delivery = 5) => {

    const minDeliveryDate = getMinProdDate().add(HOUR_DELIVERY_TO_DIFF_DAY[hour_delivery], 'days')

    return minDeliveryDate;
};



export const isDateAutorized = client_order => {

	const date = getDayFromMoment(moment(client_order.day, 'YYYYMMDD'));
	const min_date = getDayFromMoment(getMinDate(client_order.hour_delivery));

	return date.isSameOrAfter(min_date);
}