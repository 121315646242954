const BASE_CONFIG = {

	primaryColor: '#FF754C',
	pageBackgroundColor: '#F2F4F7',
	textColor: '#000000',

	ws: {
		host: "ws.shakishaki.com",
		port: "443",
		protocol: "wss"
	},

	app_version: '1.0',
	cookie_domain: ".shakishaki.com",
	default_api_url: "https://api.shakishaki.com",
	sentry_dsn: "https://ba2851d174e547da9cb3a815ca8a6f47@sentry.io/1296724'",

};

const CONFIG_DEV = {
	default_api_url: "https://api.shakishaki.dev",
	cookie_domain: ".shakishaki.dev",
	ws: {
		host: "ws.shakishaki.dev",
		port: "443",
		protocol: "wss"
	},
	IS_DEV: true
};

const CONFIG_STAGING = {
	default_api_url: "https://api.shakishaki.com",
	cookie_domain: ".shakishaki.com",
	ws: {
		host: "ws.shakishaki.com",
		port: "443",
		protocol: "wss"
	},
	IS_PROD: true
};


let CONFIG = BASE_CONFIG;
switch (window.location.hostname) {
	case 'www.shakishaki.dev':
	case 'app.shakishaki.dev':
	case 'kitchen.shakishaki.dev':
	case 'tools.shakishaki.dev':
	case 'localhost':
		CONFIG = {
			...CONFIG,
			...CONFIG_DEV
		};
		break;
	case 'www.shakishaki.com':
	case 'app.shakishaki.com':
		CONFIG = {
			...CONFIG,
			...CONFIG_STAGING
		};
		break;
	default:
		break;
}

export default CONFIG;