import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;

export default (state = {}, action) => {
	switch (action.type) {
	case C.SET_SEARCH_QUERIES:
		return {
			...state,
			...action.searchQueries,
		};
	default:
		return state;
	}
}