import initialState from "../initialState";
import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;

export default (state = initialState.sidebar, action) => {

	switch (action.type) {
	case C.SIDEBAR_TOGGLE_MENU:
		return {
			...state,
			[action.menu_key]: !state[action.menu_key]
		};
	default:
		return state;
	}
}