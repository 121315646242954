import React, { PureComponent } from "react";

import Modal, { ModalContent } from 'AppCore/Components/Modal';
import Form from 'AppCore/Components/Form';
import Api from 'AppCore/api';


export default class InvoiceModal extends PureComponent {

	static defaultProps = {
		onSaved: async () => {},
		onClose: () => {},
	}

	handleClose = () => {
		const { onClose = () => {} } = this.props;
		onClose();
    }

    getInvoiceData = async () => {
        const { invoice_id } = this.props;
        return await Api.getInvoice({ invoice_id });
    }
    updateInvoiceData = async invoice => {
        const { invoice_id, onSaved } = this.props;
        const result = await Api.updateInvoice({ invoice_id, invoice });
        await onSaved();
        return result;
    }

	render() {

		return (
			<Modal
				open
				drawer
				onClose={this.handleClose}
				title={"Modifier Facture"}
			>
                <ModalContent>
                    <Form
                        getData={this.getInvoiceData}
                        updateData={this.updateInvoiceData}
                        onSaved={this.handleClose}
                        attributes={{
                            name: { label: "Label / Nom", type: 'text', validators: ['notempty'] },
                            discount: {
                                label: "Remise (en %)",
                                type: 'text',
                                transformers: [
                                    'float',
                                    { name: 'max', value: 100 }
                                ]
                            },
                        }}
                    />
                </ModalContent>
			</Modal>
		)
	}

}