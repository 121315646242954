import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";


import reducer from './reducer';

// Redux-persist Config.
const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
	whitelist: [
		"settings",
		"user", "authData", "language", "deviceToken",

		"products",

		"sidebar"
	],
	stateReconciler: autoMergeLevel2
};
const persistedReducer = persistReducer(persistConfig, reducer);

let store;
let persistor;

export const initStore = (extraArgument, onRehydrate) => {
	const withExtraArgument = thunk.withExtraArgument(extraArgument);
	store = createStore(
		persistedReducer,
		composeWithDevTools(applyMiddleware(withExtraArgument))
	);
	persistor = persistStore(store, null, () => {
		onRehydrate(store);
	});

	return { store, persistor };
};

export const getStore = () => ({ store, persistor });
