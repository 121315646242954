import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import SearchField from 'AppCore/Components/SearchField';

import ActivityIndicator from "AppCore/Components/ActivityIndicator";
import OrderTable from 'AppCore/Components/DataTable/orders';

import { getOrders } from 'AppCore/Actions';

import { PageContainer, Content } from '../../Layout';
import Header, { HeaderActionContainer } from '../../Layout/Header';

class OrdersScreen extends Component {

	state = {
        modalEdit: null, modalCreate: null, searchQuery: ''
    }

	componentDidMount = async () => {
		this.props.getOrders();
	}

	renderLoading() {
		return <ActivityIndicator />
	}

	onClickEdit = location_id => {
        alert("Fonction non disponible");
        return false;
		// this.setState({
		// 	modalEdit: {
		// 		location_id,
		// 		open: true
		// 	}
		// })
	}

	onClickCreate = () => {
        alert("Fonction non disponible");
        return false;
		// this.setState({
		// 	modalCreate: {
		// 		open: true
		// 	}
		// })
	}

	render() {

		const { searchQuery } = this.state;
        const { orders: { data, loaded} } = this.props;

		if (!loaded) {
			return this.renderLoading();
		}

		return (
			<PageContainer>

				<Header title="Commandes">
					<HeaderActionContainer>
						<SearchField
							id="outlined-with-placeholder"
							label="Search"
							placeholder="Search"
							variant="outlined"
							value={searchQuery}
							onChange={({ target: { value: searchQuery }}) => this.setState({ searchQuery })}
						/>
					</HeaderActionContainer>
				</Header>

				<Content>
					<OrderTable
						data={data}
						onClickEdit={this.onClickEdit}
					/>
				</Content>

			</PageContainer>
		);
	}

}


export default connect(
	state => ({
		orders: state.orders
	}),
	{ getOrders }
)(withSnackbar(OrdersScreen));