import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { updateClientProductListInfos, createShop, displayError } from 'AppCore/Actions';
import Api from 'AppCore/api';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import Button from 'AppCore/Components/Button';

import ClientProductListInfos from 'Components/ClientProductListInfos';

import css from './styles.css';

class EditProductListInfos extends PureComponent {

	static defaultProps = {
		onSaved: () => {},
	}

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            saving: false,
			client: null,
		}
	}

	componentDidMount = async () => {
        try {
            await this.loadData()
        } catch (e) {
            console.log(e);
        }
    }

    loadData = async () => {
		const client = await this.getClientData();
		this.setState({
			client,
			loading: false
		})
	}

	getClientData = async () => {
		const { client_id } = this.props;
		if (client_id) {
            const client = await Api.getShop({ shop_id: client_id });
			return client || {};
		}

		return {};
	}

	save = async () => {

		const { client_id: shop_id , displayError } = this.props;
        const { client } = this.state;

		try {

			await this.props.updateClientProductListInfos({
                shop_id,
                shop: client
            });

		} catch (error) {
            console.log(error);
            displayError({ error })

            throw error;
		}
	}

	onClickSave = async () => {

		this.setState({ saving: true });
		const key = this.props.enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await this.save();

			this.props.closeSnackbar(key);
			this.props.enqueueSnackbar('Saved', {variant: 'success'});

			this.setState({ saving: false });
			this.props.onSaved();

		} catch (e) {
			this.props.closeSnackbar(key);
			this.props.enqueueSnackbar('Error', {variant: 'error'});
			this.setState({ saving: false });
		}
	}

	render() {

		const { client, loading = false, saving = false, hasError = false } = this.state;

		if (client === null) return <ActivityIndicator />;

		const { productListInfos } = client;

		return (
            <div className={css.formContainer}>

                <div className={css.formContent}>
                    <ClientProductListInfos
						values={productListInfos}
						columns={['external_id']}
                        onChange={productListInfos => this.setState({
							client:  {
								...this.state.client,
								productListInfos
							}
						})}
                    />
                </div>

                <div className={css.formActions}>
                    <Button
                        key="save"
                        onClick={this.onClickSave}
                        loading={saving}
                        disabled={loading || saving || hasError}
                    >
                        save
                    </Button>
                </div>
            </div>
		)
	}

}

export default connect(
	state => ({

	}),
	{ updateClientProductListInfos, createShop, displayError }
)(withSnackbar(EditProductListInfos));