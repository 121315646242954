import Constants from "AppCore/Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getLocations
*/
export const getLocations = () => async (dispatch, getState, { api, ws }) => {
    console.log("result", "okkok");
	try {
		const result = await api.getLocations();
        console.log("result", result);
		dispatch({
			type: C.SET_LOCATIONS,
			locations: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createLocation
*/
export const createLocation = ({ location }) => async (dispatch, getState, { api, ws }) => {

	try {
		await api.createLocation({ location });
		await dispatch(getLocations());

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalLocation = ({ location_id, location }) => async (dispatch, getState, { api, ws }) => {
	const locations = JSON.parse(JSON.stringify(getState().locations));

	locations.data.forEach((_location, i) => {
		if (_location.id === location_id) {
			locations.data[i] = {
				...locations.data[i],
				...location
			}
		}
	});

	dispatch({
		type: C.SET_LOCATIONS,
		locations
	});
}

export const updateLocation = ({ location_id, location }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_locations = {
		...(getState().locations)
	}

	dispatch(updateLocalLocation({ location_id, location }));

	try {


		const result = await api.updateLocation({ location_id, location });
		dispatch(getLocations())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_LOCATIONS,
			locations: rollback_locations
		});
	}

}

export const deleteLocation = ({ location_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteLocation({ location_id });
		await dispatch(getLocations())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}