import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'

import moment from 'moment';
import get from 'lodash.get';

import { displayModal, hideModal } from 'AppCore/Actions';
import Api from 'AppCore/api';
import { search } from 'AppCore/Utils/Search';

import SendInvoiceEmailBtn from 'Components/ActionButtons/SendInvoiceEmail';

import DataTable from './index';
export default connect(state => ({

}), { displayModal, hideModal })(

withRouter(({
    data = [], searchQuery = '', triggerReload = () => {},
    hideCells = [],

    displayModal, hideModal,

    history

}) => {

    const filteredObjs = search({
        query: searchQuery, data,
        attrs: [
            'name', 'total_price_ttc', 'client.name',
            'total_price_ttc', 'total_price_ht',
            'autoincrement'
        ]
    })

    const onClickDelete = async (id, invoice) => {

        if (!window.confirm("Supprimer facture N°" + invoice.autoincrement + " ?")) {
            return false;
        }

        await Api.deleteInvoice({ invoice_id: invoice.id });
        triggerReload();
    }

    return <DataTable
        data={filteredObjs}
        config={{
            hideCells,
            cells: [
                { title: "N°", key: 'autoincrement' },
                { title: "Client", key: 'client.name' },
                { title: "Titre", key: 'name' },
                { title: "Date de création", key: 'date' },
                { title: 'Status', key: 'status' },
                { title: 'Total HT', key: 'total_price_ht' },
                { title: 'Total HT avec remise', key: 'total_price_ht_discount' },
                { title: 'Total TTC', key: 'total_price_ttc' }
            ],
            actions: [
                { icon_name: 'edit', title: "Edit", onClick: id => {
                    history.push('/invoices/' + id)
                }},
                invoice => (<SendInvoiceEmailBtn key="sendbyemailbtn" invoice={invoice} />),
                { icon_name: 'cloud_download', title: "Download", onClick: (id, invoice) => {
                    window.open(invoice.pdf_link);
                }},
                { icon_name: 'delete', title: "Delete", onClick: onClickDelete },
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'autoincrement') {
                return (
                    <NavLink to={"/invoices/" + item.id}>{item.autoincrement}</NavLink>
                )
            }

            if (attr_name === 'total_price_ht') {
                return (Math.round(item.total_price_ht) / 100) + "€";
            }
            if (attr_name === 'total_price_ht_discount') {
                return (Math.round(item.total_price_ht_discount) / 100) + "€";
            }
            if (attr_name === 'total_price_ttc') {
                return (Math.round(item.total_price_ttc) / 100) + "€";
            }


            if (attr_name === 'date') {
                return moment(item.date).format("D/MM/Y");
            }

            if (attr_name === 'status') {
                return get(item, attr_name) || "Non envoyée";
            }

            return get(item, attr_name) || "";
        }}
    />

})

)