

export const getById = (id, data) => {

	let o = null;
	data.forEach(_object => {
		if (_object.id === id) {
			o = _object;
			return false;
		}
	});

	return o;
}