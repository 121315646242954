import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { indigo } from '@material-ui/core/colors';


const theme = createMuiTheme({

	palette: {
		// primary: {
		// 	main: '#1A60C6'
		// },
		// secondary: {
		// 	main: '#1A60C6'
		// },

		primary: indigo,
		secondary: {
			main: '#bf360c',
		},

		type: "light",

	},

	overrides: {
		MuiInput: {
			root: {
				disableUnderline: true,
			  },
		},
		// Name of the component ⚛️ / style sheet

		/*MuiIcon: {
			root: {
				cursor: 'pointer',
				'&:hover': {
					color: red[800],
				},
			}
		}*/
	},
	typography: {},
});

export default ({ children }) => (
	<ThemeProvider theme={theme}>
		{children}
	</ThemeProvider>
)