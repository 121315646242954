import keyMirror from 'keymirror';

export default {
	REDUCER : keyMirror({

		SET_SEARCH_QUERIES: true,

		SET_SETTINGS: true,
		CLEAR_SETTINGS: true,

		SET_AUTH_DATA: true,
		SET_USER: true,
		SET_CURRENT_USER: true,

		SET_PRODUCTS: true,
		CLEAR_PRODUCTS: true,

		SET_SHOPS: true,
		CLEAR_SHOPS: true,

		SET_PRICELISTS: true,
		CLEAR_PRICELISTS: true,

		SET_LOCATIONS: true,
		CLEAR_LOCATIONS: true,

		SET_PRODUCTITEMS: true,
		CLEAR_PRODUCTITEMS: true,

		SET_ORDER: true,
		CLEAR_ORDER: true,


		// DISPLAY
		DISPLAY_LOGIN: true,
		DISPLAY_LOADING: true,
		DISPLAY_ERROR: true,
		DISPLAY_MOBILE_MENU: true,
		DISPLAY_MODAL: true,

		SIDEBAR_TOGGLE_MENU: true,
	})
}