import React from 'react';

import ContenantSelector from 'AppCore/Components/Selector/Contenant';
import HourSelector from 'AppCore/Components/Selector/Hours';

export default ({ selectType, value, onChange, ...props }) => {
    switch (selectType) {
        case 'hours':
            return (
                <HourSelector
                    {...props}
                    value={value}
                    onChange={onChange}
                />
            );
        case 'contenant':
            return (
                <ContenantSelector
                    {...props}
                    value={value}
                    onChange={onChange}
                />
            );
        default:
            return null;
    }
}