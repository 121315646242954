export default {
	OS : 'browser',
	select(options) {
		return options.browser || undefined;
	},

	isIPhoneX: false,

	getLang() {
		return window.navigator.userLanguage || window.navigator.language;
	}
}