import React, { Component } from 'react'
import { connect } from "react-redux";
import SearchField from 'Components/SearchField';

import InvoiceTable from 'AppCore/Components/DataTable/Invoices';
import Api from 'AppCore/api';

import { PageContainer, Content } from '../Layout';
import Header, { HeaderActionContainer } from '../Layout/Header';

class ShopsScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
            invoices: []
        };
	}

	componentDidMount = () => {
		this.reloadData();
	}

	reloadData = async () => {
		this.setState({
            invoices: (await Api.getInvoices()).data
        })
	}

	render() {

		const { invoices } = this.state;
		const { searchQuery } = this.props;

		return (

			<PageContainer>

				<Header title="Factures">
					<HeaderActionContainer>
						<SearchField
							connectTo="invoice_list"
						/>
					</HeaderActionContainer>
				</Header>

				<Content>
					<InvoiceTable triggerReload={this.reloadData} data={invoices} searchQuery={searchQuery} />
				</Content>

			</PageContainer>

		)
	}
}

export default connect(
	state => ({
		searchQuery: (state.searchQueries && state.searchQueries.invoice_list) || ""
	}),
	{}
)(ShopsScreen);

