import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import {
  Grid, Typography,
  Divider as MuiDivider,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import Button from 'AppCore/Components/Button';
import DateRangeSelector from 'AppCore/Components/DateRangeSelector';

import Api from 'AppCore/api';

import { PageContainer, Content } from '../../Layout';

import Stats from './Stats'
import css from './style.css';

const Divider = styled(MuiDivider)(spacing);


export default ({...props }) => {

    const [buckets, setBuckets] = useState({});
    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').toDate());
    const [endDate, setEndDate] = useState(new Date());

    const [periodTotal, setPeriodTotal] = useState(0);
    const [lastPeriodTotal, setLastPeriodTotal] = useState(0);

    const [ intervalChanged, setIntervalChanged ] = useState(false);

    const getDiffDays = () => {
        const date_start = moment(startDate);
        const date_end = moment(endDate);
        return Math.floor((date_end.valueOf() - date_start.valueOf()) / (3600*24*1000));
    }

    const getLastPeriodDates = () => {
        const date_start = moment(startDate);
        const date_end = moment(endDate);
        const diff_days = getDiffDays();

        return {
            startDate: date_start.subtract(diff_days, 'days').toDate(),
            endDate: date_end.subtract(diff_days, 'days').toDate(),
        }
    }

    const loadData = async () => {
        setLoading(true);

        const date_start = moment(startDate);
        const date_end = moment(endDate);
        const diff_days = getDiffDays();

        const result = await Api.getStatsOrders({
            date_start: date_start.format("YYYYMMDD"), date_end: date_end.format("YYYYMMDD")
        });


        const resultLastPeriod = await Api.getStatsOrders({
            date_start: date_start.subtract(diff_days, 'days').format("YYYYMMDD"),
            date_end: date_end.subtract(diff_days, 'days').format("YYYYMMDD")
        });


        setBuckets(result.buckets || {});
        setPeriodTotal(result.total);
        setLastPeriodTotal(resultLastPeriod.total);

        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    const bucketsKeys = Object.keys(buckets);
    const data = {
        labels: bucketsKeys.map(date => moment(date, 'YYYYMMDD').format('LL')),
        datasets: [
            {
                label: 'Prod',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: Object.keys(buckets).map(key => buckets[key])
            },
        ]
    };

    const lastPeriod = getLastPeriodDates();

    return (

        <PageContainer>

            <Content style={{marginTop: '20px'}}>
                <Grid justify="flex-start" container spacing={6}>
                    <Grid item>
                        <Typography variant="h3" display="inline">
                            Stats Prod
                        </Typography>

                    </Grid>

                    <Grid item style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                        width:'365px'
                    }}>
                        <DateRangeSelector
                            style={{width: '230px'}}
                            startDate={startDate}
                            endDate={endDate}
                            maxPeriodDay={30}
                            onChange={([startDate, endDate]) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                                setIntervalChanged(true);
                            }}
                        />
                        <Button disabled={!intervalChanged} onClick={() => {
                            loadData()
                            setIntervalChanged(false);
                        }}>Apply</Button>
                    </Grid>
                </Grid>

                <Divider my={6} />

                <Grid container spacing={6}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={6} xl={6}>
                                <Stats
                                    title={moment(startDate).format("LL") + " - " + moment(endDate).format("LL")}
                                    amount={loading ? "--" : periodTotal}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} xl={6}>
                                <Stats
                                    title={moment(lastPeriod.startDate).format("LL") + " - " + moment(lastPeriod.endDate).format("LL")}
                                    amount={loading ? "--" : lastPeriodTotal}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <div className={css.Container}>
                            {!loading && <Bar
                                data={data}
                                width={100}
                                height={50}
                                options={{
                                    maintainAspectRatio: false,
                                    onClick: (e, chartElement) => console.log(chartElement)
                                }}
                            />}
                        </div>
                    </Grid>
                </Grid>

            </Content>


        </PageContainer>

    )
}

