import React from 'react';
import { connect } from "react-redux";

import { hideModal } from 'AppCore/Actions'

import EditCreateClientModal from 'Screens/App/Modals/Client/editCreate';
import EditCreatePriceListModal from 'Screens/App/Modals/PriceList';
import EditInvoiceModal from 'Screens/App/Modals/Invoice';
import OrderDialog from 'Components/OrderDialog'
import FormModal from 'Screens/App/Modals/Form';

export default connect(state => ({
    modal: state.display.modal
}), { hideModal })(

    ({ hideModal, modal,  ...props }) => {

        if (!modal) {
            return null;
        }

        let Modal = null;
        switch (modal.name) {
            case 'edit_create_client':
                Modal = EditCreateClientModal;
                modal.props.onSaved = hideModal;
                break;
            case 'edit_create_pricelist':
                Modal = EditCreatePriceListModal;
                modal.props.onSaved = hideModal;
                break;
            case 'edit_create_clientorder':
                Modal = OrderDialog;
                break;
            case 'edit_invoice':
                Modal = EditInvoiceModal;
                break;

            case 'form':
                Modal = FormModal;
                break;
            default:
                break;
        }

        if (Modal === null) {
            return null;
        }

        return (
            <Modal open {...(modal.props || {})} onClose={hideModal}/>
        )

    }
)