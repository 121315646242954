// Initial State Object Definition.

const getInitialList = () => ({
	data: [],
	total: 0,
	loaded: false
})

export default {

	settings: {
		loaded: false
	},

	userLogged: false,
	authData: {},
	user: {},

	shops: getInitialList(),
	pricelists: getInitialList(),
	locations: getInitialList(),
	productItems: getInitialList(),
	orders: getInitialList(),

	products: {
		...getInitialList(),
		groupByCat: [],
	},

	display: {
		login: null,
		loading: false,
		error: null,
		mobileMenu: false,
		modal: null
	},

	sidebar: {}
};
