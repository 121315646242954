import React from 'react'
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CoreTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { sort as sortProduct } from 'AppCore/Utils/Product'
import InputField from 'AppCore/Components/Form/inputField'

import css from './style.css';

const ProductNameCell = withStyles(theme => ({
  head: {
    width: 400,
    fontSize: 18,
  },
  body: {

    width: 400
  },
}))(CoreTableCell);

const TableCell = withStyles(theme => ({
  head: {
  	padding:0
  },
  body: {
	  padding:5,
	  paddingLeft: 0,
	  border:0
  },
}))(CoreTableCell);



const COLUMNS = ['external_id', 'price_ht', 'tva_percent'];
const COLUMNS_CONFIG = {
	external_id: {
		title: "Identifiant client",
		transformers: ['alphanumeric', 'lowercase']
	},
	price_ht: {
		title: "Price HT (euros)",
		onsave_transformers: ['euros_to_cents'],
        transformers: ['float']
	},
	tva_percent: {
		type: 'select',
		values: [{ label: "5.5", value: 5.5 }, { label: "10", value: 10 }],
		defaultValue: 10,
		title: "TVA (percent)",
		transformers: ['float']
	}
};


class ClientProductListInfos extends React.PureComponent {

	static defaultProps = {
		values: [],
		onChange: () => {},
		columns: COLUMNS
	}

	constructor(props) {
		super(props);

		var states = {};
		for (var i = 0; i < this.props.products.length; i++) {
			var category = this.props.products[i]
			states["category_opened_" + category.id] = this.props.expandCategories;
		}

		this.state = {
			...states
		}
	}

	getProductValue = ({ column_key, sku }) => {

		if (!this.props.values[sku]) {
			return "";
		}

		if (!this.props.values[sku][column_key]) {
			return "";
		}

		return this.props.values[sku][column_key];
	}

	handleChangeProductValue = ({ column_key, sku, value }) => {

		this.props.onChange({
			...this.props.values,
			[sku]: {
				...this.props.values[sku],
				sku,
				[column_key]: value
			}
		})
	}

	render() {

		this.separatorCategoryValue = null;
		return (

			<Table stickyHeader className={css.Table}>
				<TableHead>
					<TableRow className={css.TableRow}>
						<ProductNameCell>Produit</ProductNameCell>
						{this.props.columns.map((column, index) => (
							<TableCell key={index}>{COLUMNS_CONFIG[column].title || column}</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{this.props.products.sort(sortProduct).map(this._renderProductRow)}
				</TableBody>
			</Table>

		)
	}

	_renderCategorySeparator = category => {
		this.separatorCategoryValue = category;
		return (
			<TableRow key={"cat_"+this.separatorCategoryValue} className={css.TableRow}>
				<ProductNameCell>{category}</ProductNameCell>

				{this.props.columns.map(column_key => (<TableCell key={column_key} className={css.inputCell}></TableCell>))}

			</TableRow>
		)
	}

	_renderProductRow = (product, rowIndex) => {

		const sku = product.sku;

		return [
			...(product.category !== this.separatorCategoryValue ? [
				this._renderCategorySeparator(product.category)
			] : []),
			<TableRow key={"row_"+sku} className={css.TableRow}>
				<ProductNameCell>{product.sku + ' - ' + product.name}</ProductNameCell>

				{this.props.columns.map(this._renderProductRowCell(sku, rowIndex))}

			</TableRow>
		]
	}

	_renderProductRowCell = (sku, rowIndex) => (column_key, index) => {
		return (
			<TableCellInput
				key={sku + '_' + column_key}
				sku={sku} column_key={column_key}
				value={this.getProductValue({ sku, column_key })}
				onChange={this.handleChangeProductValue}
				tabIndex={(index+1)*1000+rowIndex}
			/>
		)
	}

}

const TableCellInput = React.memo(({sku, column_key, value, onChange = () => {}, tabIndex}) => {

		return (
			<TableCell className={css.inputCell}>
				<InputField
					key={"input_" + sku + '_' + column_key}
					{...(COLUMNS_CONFIG[column_key] || {})}
					onChange={value => {
						onChange({ value, sku, column_key })
					}}
					selectOnFocus
					value={value}
					InputProps={{ inputProps: { tabIndex } }}
				/>
			</TableCell>
		)
	})

export default connect(
	state => ({
		products: state.products.data.filter(p => !p.disabled)
	}),
	{}
)(ClientProductListInfos);




