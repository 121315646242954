import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import moment from 'moment';
import classNames from 'classnames'

import DatePicker from 'react-datepicker';

import { withHasPermissions } from 'AppCore/Components/HasPermissions'
import { getSettings } from 'Meth/config/settings'

import CommonCss from '../common.css';

import Grid, { GridBox } from '../../../Components/Grid';
import TableRender from '../../../Components/TableRender';

import { withStyles } from '@material-ui/core/styles';
import CoreTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress'

import Api from 'AppCore/api';

import { PageContainer, Content } from 'Screens/App/Layout';
import Header from 'Screens/App/Layout/Header';


const TableCell = withStyles(theme => ({
  head: {
  	padding:'10px'
  },
  body: {
  	padding:'10px'
  },
}))(CoreTableCell);


class KitchenScreen extends PureComponent {

	constructor(props) {
		super(props);

        const isWeek = props.location.pathname === '/kitchen/week';

        var day = moment();
        const dayPlus7 = moment().add(7, 'days');
		this.state = {
            loading: true,
            isWeek,

			orderDayFrom: day.format('YYYYMMDD'),
			orderDayTo: (isWeek ? dayPlus7 : day).format('YYYYMMDD'),

			ordersRecap: null,

			loading_sendmail_kitchen: false,
			loading_sendmail_all: false
		}
	}

	setStateAsync = async (state) => {
		var result = await new Promise((resolve, reject) => {
			this.setState(state, resolve);
		})
		await new Promise((resolve) => {
			setTimeout(resolve, 0)
		})

		return result;
	}

	loadRecap = async () => {
		this.setState({
			loading: true
		})

		const from = this.state.orderDayFrom;
		const to = this.state.orderDayTo;

		let ordersRecap = Api.kitchenGetAllComputed({ from, to, type: 'products', dateProd: true });

		ordersRecap = await ordersRecap;

		await this.setStateAsync({ordersRecap, loading:false});
	}

	componentDidMount() {
		this.loadRecap();
	}

	getPdfLink = (shop_id, type) =>
		getSettings().HOST_API + '/kitchen/pdf?' +
			'dayFrom=' + this.state.orderDayFrom +
			'&dayTo=' + this.state.orderDayTo +
			(shop_id === 'all' ? "&all=true" : "&shop_id="+shop_id) +
			'&type=' + type;

	onChangeDayProd = day => {

		const { isWeek } = this.state;
		const delivery_day = moment(day);

		this.setState({
			orderDayFrom: delivery_day.format('YYYYMMDD'),
			orderDayTo: isWeek ? delivery_day.clone().add(7, 'days').format('YYYYMMDD') : delivery_day.format('YYYYMMDD')
		}, () => {
			this.loadRecap();
		})
	}

	render() {

        const { isWeek } = this.state;
		const { hasPermissions } = this.props;

		const selected_date = (this.state.orderDayFrom && moment(this.state.orderDayFrom, 'YYYYMMDD').toDate())
		const selected_date_display = (selected_date && moment(selected_date).format('DD/MM/YYYY')) || '';
		return (
			<PageContainer className={CommonCss.page}>

                <Header className={CommonCss.title} title="Production" />

				<Content>

					{hasPermissions(['admin']) ? (

						<DatePicker
							dateFormat="dd/MM/yyyy"
							selected={
								selected_date
							}
							onChange={this.onChangeDayProd}
							locale="fr"

							customInput={<Button children={selected_date_display} variant="contained" onClick={console.log} color="primary"/>}

						/>

					) : (
						<Grid cols={1} className={CommonCss.grid}>
							<GridBox className={classNames(CommonCss.gridbox)}>
								{isWeek ?
								<div className={CommonCss.DatePickerContainer}>
									A produire aujourd'hui et les 7 prochains jours
								</div>
								: <div className={CommonCss.DatePickerContainer}>
									A produire aujourd'hui pour livraison demain
								</div>}
							</GridBox>

						</Grid>
					)}



					<Grid cols={1} className={CommonCss.grid}>
						<GridBox className={CommonCss.gridbox}>

							{this._renderRecapAll()}

						</GridBox>
					</Grid>
				</Content>
			</PageContainer>
		)
	}

	_renderLoading() {
		return (
			<CircularProgress />
		);
	}

	_renderRecapAll() {

		if (this.state.loading) {
			return this._renderLoading()
		}

		return (
			[
				<div key={'all_products'} className={CommonCss.tableContainer}>
					{this._renderTableProduct(this.state.ordersRecap.all, this.getPdfLink('all', 'products'))}
				</div>
			]
		)
	}


	_renderTableProduct(computedProducts, pdfLink) {

		if (computedProducts.head) {
			return (
				<TableRender table={computedProducts}>
					{pdfLink &&
						<TableRow>
							<TableCell>
								<Button variant="contained" color="secondary" className={CommonCss.ActionIcon} onClick={e => {
									window.open(pdfLink);
								}}>
									<Icon className={CommonCss.ButtonIcon}>cloud_download</Icon>
									download PDF
								</Button>
							</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
						</TableRow>
					}
				</TableRender>
			);
		}

	}

}


export default connect(
	state => ({
	}),
	{}
)(withHasPermissions(KitchenScreen));

