import { combineReducers } from 'redux';

import SettingReducer from './Settings';
import userReducer from "./user";
import authDataReducer from "./auth";
import shopsReducer from "./shops";
import productsReducer from './products';
import priceListsReducer from './priceList';

import LocationsReducer from './locations';
import OrdersReducer from './orders';

import DisplayReducer from './display';
import SidebarReducer from './sidebar'

import SearchQueriesReducer from './searchQueries';


export default combineReducers({

    settings: SettingReducer,

    user: userReducer,
    authData: authDataReducer,

    shops: shopsReducer,
    products: productsReducer,
    pricelists: priceListsReducer,
    locations: LocationsReducer,
    orders: OrdersReducer,

    display: DisplayReducer,
    sidebar: SidebarReducer,

    searchQueries: SearchQueriesReducer
});