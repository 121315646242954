import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export default ({ value, onChange = value => {}, ...props }) => (
    <TextField
        {...props}
        select
        variant="outlined"
        value={value || ""}
        onChange={
            ({ target: { value } }) => {
                onChange(value);
            }
        }
    >
        {[...Array(24).keys()].map(hour => (
            <MenuItem key={hour} value={hour}>{hour}h</MenuItem>
        ))}

    </TextField>
)