import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { updatePriceList, createPriceList, displayError } from 'AppCore/Actions';
import Api from 'AppCore/api';

import InputField from 'AppCore/Components/Form/inputField'
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import Button from 'AppCore/Components/Button';

import ClientProductListInfos from 'Components/ClientProductListInfos';

import css from './styles.css';

class EditProductListInfos extends PureComponent {

	static defaultProps = {
		onSaved: () => {},
	}

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            saving: false,
			pricelist: {},
			errorName: null,
			hasError: false
		}
	}

	componentDidMount = async () => {
        try {
            await this.loadData()
        } catch (e) {
            console.log(e);
        }
    }

    loadData = async () => {
		this.setState({
			pricelist: await this.loadPriceList(),
			loading: false
		})
	}

	loadPriceList = async () => {
		const { pricelist_id } = this.props;
		if (pricelist_id) {
            const pricelist = await Api.getPriceList({ pricelist_id });
			return pricelist;
		}

		return {};
	}

	save = async () => {

		const { pricelist_id , displayError } = this.props;
		const { pricelist } = this.state;

		if (!pricelist.name || pricelist.name === "" ) {
			const message = "Entrez un nom!";
			const error = new Error(message);
			error.name = {
				message
			}
			throw error;
		}

		try {

			if (pricelist_id) {
				await this.props.updatePriceList({
					pricelist_id,
					pricelist
				});
			} else {
				await this.props.createPriceList({
					pricelist
				});
			}


		} catch (error) {
            console.log(error);
            displayError({ error })

            throw error;
		}
	}

	onClickSave = async () => {

		this.setState({ saving: true });
		const key = this.props.enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await this.save();

			this.props.closeSnackbar(key);
			this.props.enqueueSnackbar('Saved', {variant: 'success'});

			this.setState({ saving: false });
			this.props.onSaved();

		} catch (e) {
			this.props.closeSnackbar(key);
			this.props.enqueueSnackbar(e.message, {variant: 'error'});
			this.setState({
				saving: false,
				hasError: true,
				errorName: (e.name) || null
			});

		}
	}

	render() {

		const { pricelist, loading = false, saving = false, hasError = false, errorName } = this.state;

		if (loading) {
			return <ActivityIndicator />
		}

		return (
            <div className={css.formContainer}>

				<div className={css.name}>
					<InputField
						placeholder="Nom de la liste"
						label="Nom de la liste"
						hasError={hasError}
						helperText={(errorName && errorName.message) || null}
						onChange={name => {
							this.setState({
								errorName: false,
								hasError: false,
								pricelist: {
									...this.state.pricelist,
									name
								}
							})
						}}
						value={pricelist.name}
					/>
				</div>

                <div className={css.formContent}>
                    <ClientProductListInfos
						columns={['price_ht', 'tva_percent']}
                        values={pricelist.prices || {}}
                        onChange={prices => this.setState({
							pricelist: {
								...this.state.pricelist,
								prices
							}
						})}
                    />
                </div>

                <div className={css.formActions}>
					<Button
                        key="save"
                        onClick={this.onClickSave}
                        loading={saving}
                        disabled={loading || saving || hasError}
                    >
                        Enregistrer
                    </Button>

					<Button
						color="secondary"
                        key="cancel"
                        onClick={this.props.onSaved}
                        loading={saving}
                        disabled={loading || saving || hasError}
                    >
                        Annuler
                    </Button>
                </div>
            </div>
		)
	}

}

export default connect(
	state => ({

	}),
	{ updatePriceList, createPriceList, displayError }
)(withSnackbar(EditProductListInfos));