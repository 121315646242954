import Api from './request';

var Settings = {
	load() {
		return Api.call({
			uri : "conf",
			request_method : Api.request_method.GET,
			raw_promise: true
		});
	},
}

export default Settings;