import React, { PureComponent } from "react";
import { withSnackbar } from 'notistack';
import moment from 'moment';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Api from 'AppCore/api';
import InvoicesTable from 'AppCore/Components/DataTable/Invoices';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import Css from './styles.css';
import CommonCss from 'Screens/App/common.css';

class EditProductListInfos extends PureComponent {

	static defaultProps = {
		onSaved: () => {},
	}

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            saving: false,
            invoices: null,

            generate_loading: false,
            generate_month: "",
		}
	}

	componentDidMount = async () => {
        try {
            await this.loadData()
        } catch (e) {
            console.log(e);
        }
    }

    loadData = async () => {
        const { client_id } = this.props;
        const client = await Api.getShop({ shop_id: client_id });
		this.setState({
            invoices: await this.getClientData(),
            has_price_list: !!client.pricelist_id,
			loading: false
		})
	}

	getClientData = async () => {
		const { client_id } = this.props;
		if (client_id) {
            const invoices = await Api.getClientInvoices({ shop_id: client_id });
			return invoices.data || [];
		}

		return {};
    }

    generateInvoice = async () => {

        const { generate_month: month, has_price_list = false } = this.state;
        const { client_id } = this.props;

        if (month === "") {
            window.alert("Vous devez selectionner un mois");
            return false;
        }

        if (!has_price_list) {
            window.alert("Vous devez selectionner une liste de prix avant de générer une facture.");
            return false;
        }

        this.setState({
            generate_loading: true
        })

        try {
            const invoice = await Api.generateInvoicePdf({ client_id, month });
            await this.loadData();

            setTimeout(() => {
                window.alert(`Facture N°${invoice.autoincrement} créée à partir de ${invoice.orders.data.length} commandes.`);
            }, 0)

        } catch (e) {
            window.alert("Aucune facture générée");
        }

        this.setState({
            generate_loading: false
        })
    }

    renderMenuItemMonths() {

        let now = moment();
        const months = [];
        for (let i = 0; i < 12; i++) {

            months.push(
                <MenuItem
                    key={now.format("YYYYMM")}
                    value={now.format("YYYYMM")}
                >
                    {now.format("MM / YYYY")}
                </MenuItem>
            )

            now.subtract(1, "month");
        }

        return months;
    }

	render() {

        const { invoices, generate_month, generate_loading = false } = this.state;

        if (invoices === null) return <ActivityIndicator />;

		return (
            <div className={Css.container}>
                <div className={CommonCss.tableContainer}>

                    <div>
                        <Select
                            value={generate_month}
                            onChange={
                                ({ target: { value: generate_month } }) => {
                                    this.setState({
                                        generate_month
                                    })
                                }
                            }
                        >
                            {this.renderMenuItemMonths()}
                        </Select>
                        <Button variant="contained" disabled={generate_loading || generate_month === ""} color="primary" onClick={e => this.generateInvoice()}>
                            <AddIcon /> Générer une facture
                        </Button>
                    </div>

                    <InvoicesTable
                        triggerReload={this.loadData}
                        data={invoices}
                        hideCells={['client.name']}
                    />
                </div>
            </div>
		)
	}

}

export default withSnackbar(EditProductListInfos);