import React from 'react'
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import User from 'AppCore/models/User';

import AppScreen from '../../Screens/App'
import HomeScreen from '../../Screens/App/Home'
import LoginScreen from '../../Screens/App/Login'
import LoginCodeScreen from '../../Screens/App/Login/loginCode'
import UsersScreen from '../../Screens/App/Users'
import UserScreen from '../../Screens/App/Users/User'
import ProductsScreen from '../../Screens/App/Products'
import PriceListScreens from '../../Screens/App/Products/PriceList'
import InvoicesScreen from '../../Screens/App/Invoices'
import InvoiceDetailScreen from '../../Screens/App/Invoices/Invoice'
import ShopsScreen from '../../Screens/App/Shops'
import ShopDetailScreen from '../../Screens/App/Shops/Shop'
import KitchenDayScreen from '../../Screens/App/Kitchen/day'
import ClientOrdersScreen from '../../Screens/App/ClientOrders'
import AllClientOrdersScreen from '../../Screens/App/ClientOrders/allOrders'
import LocationsScreen, { LocationScreen } from '../../Screens/App/Locations'
import OrdersScreen from '../../Screens/App/Locations/Orders'
import DeliverooToolScreen from '../../Screens/App/Tools/Deliveroo'
import OrderEmailListenerScreen from '../../Screens/App/Tools/OrderEmailListener'
import NicelabelScreen from '../../Screens/App/Tools/Nicelabel'
import StatsProdScreen from '../../Screens/App/Stats/Prod'

import UnauthorizedScreen from 'Screens/App/Unauthorized';



const withLoginCheck = (Component, { needPermissions = [] } = {}) =>
connect(
	state => ({
		userLogged: state.authData.userLogged,
		current_user: state.user
	}),
	{}
)(
	({ location, userLogged = false, current_user,  ...props } = {}) => {

		if (!userLogged && needLogin(location.pathname)) {
			return <Redirect to="/login" />;
		}

		if (needPermissions.length > 0) {
			if (!userLogged) {
				return <Redirect to="/login" />;
			}

			const user = new User(current_user.id, current_user);
			for (let i = 0; i < needPermissions.length; i++) {
				const needPermission = needPermissions[i];
				if (!user.hasPermission(needPermission)) {
					return (
						<Redirect to="/unauthorized" />
					)
				}
			}
		}

		return (
			<Component
				location={location}
				userLogged={userLogged}
				{...props}
			/>
		);
	}
)
export const ROUTES = [

	{ path: '/', component: (HomeScreen), needLogin: false, exact: true },
	{ path: '/login', component: (LoginScreen), needLogin: false, exact: true },

	{ path: '/shops', component: (ShopsScreen), needLogin: true, exact: true, needPermissions: ['list_clients'] },
	{ path: '/shops/:id', component: (ShopDetailScreen), needLogin: true, exact: true, needPermissions: ['list_clients'] },

	{ path: '/invoices', component: (InvoicesScreen), needLogin: true, exact: true, needPermissions: ['list_invoices'] },
	{ path: '/invoices/:id', component: (InvoiceDetailScreen), needLogin: true, exact: true, needPermissions: ['list_invoices'] },

	{ path: '/client-orders', component: (AllClientOrdersScreen), needLogin: true, exact: true, needPermissions: ['list_all_client_orders'] },

	{ path: '/users', component: (UsersScreen), needLogin: true, exact: true, needPermissions: ['list_users'] },
	{ path: '/users/:id', component: (UserScreen), needLogin: true, exact: true, needPermissions: ['edit_user'] },

	{ path: '/products', component: (ProductsScreen), needLogin: true, exact: true, needPermissions: ['list_products'] },
	{ path: '/price-lists', component: (PriceListScreens), needLogin: true, exact: true, needPermissions: ['list_pricelists'] },

	{ path: '/kitchen', component: (KitchenDayScreen), needLogin: true, exact: true, needPermissions: ['kitchen'] },
	{ path: '/kitchen/week', component: (KitchenDayScreen), needLogin: true, exact: true, needPermissions: ['kitchen'] },

	{ path: '/kitchen/clientorders/prod', component: (props => <ClientOrdersScreen canSelectDate {...props}/>), needLogin: true, exact: true, needPermissions: ['kitchen'] },
	{ path: '/kitchen/clientorders/dispatch', component: (ClientOrdersScreen), needLogin: true, exact: true, needPermissions: ['kitchen'] },

	{ path: '/fridges', component: (LocationsScreen), needLogin: true, exact: true, needPermissions: ['fridge'] },
	{ path: '/fridges/orders', component: (OrdersScreen), needLogin: true, exact: true, needPermissions: ['fridge'] },
	{ path: '/fridge/:id', component: (LocationScreen), needLogin: true, exact: true, needPermissions: ['fridge'] },


	{ path: '/tools/deliveroo', component: (DeliverooToolScreen), needLogin: true, exact: true, needPermissions: ['tools_access'] },
	{ path: '/tools/email-listener', component: (OrderEmailListenerScreen), needLogin: true, exact: true, needPermissions: ['tools_access'] },
	{ path: '/tools/nicelabel', component: (NicelabelScreen), needLogin: true, exact: true, needPermissions: ['admin'] },

	{ path: '/stats', component: (StatsProdScreen), needLogin: true, exact: true, needPermissions: ['stats_access'] },

	{ path: '/unauthorized', component: UnauthorizedScreen, needLogin: false, exact: true}

];

export function needLogin(path) {
	let needLogin = false;
	ROUTES.forEach(conf => {
		var testPath = conf.path;
		testPath = testPath.replace(new RegExp(":[a-z]+", "g"), "[a-z0-9]+");
		var regexp = new RegExp("^" + testPath + "$", "g");
		if (regexp.test(path)) {
			needLogin = conf.needLogin;
			return false;
		}
	});

	return needLogin;
}

export default connect(
	state => ({
		userLogged: state.authData.userLogged
	}),
	{}
)(({ userLogged = false }) => (

		<BrowserRouter>
				<Switch>
					<Route exact path="/login-code" component={LoginCodeScreen}/>
					<Route path="/" component={(props) => (
						<AppScreen {...props}>
							<Switch>

								{!userLogged &&
									<Redirect exact from="/" to="/login" />
								}

								{userLogged &&
									<Redirect exact from="/login" to="/" />
								}

								{ROUTES.map(({ component, ...routeConf }) => {
									return <Route key={routeConf.path} exact={routeConf.exact} path={routeConf.path} component={withLoginCheck(component, routeConf)} />
								})}

								<Redirect from="/home" to="/" />

								<Route component={()=>(<div>NoMatch</div>)} />

							</Switch>
						</AppScreen>
					)}/>
				</Switch>

		</BrowserRouter>
	)
)