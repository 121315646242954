import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { search } from 'AppCore/Utils/Search';
import { getPriceLists } from 'AppCore/Actions';

import HasPermissions from 'AppCore/Components/HasPermissions';

import SearchField from 'AppCore/Components/SearchField';
import ActivityIndicator from "AppCore/Components/ActivityIndicator";
import PriceListsTable from 'AppCore/Components/DataTable/PriceLists';

import EditCreatePriceListModal from 'Screens/App/Modals/PriceList';

import { PageContainer, Content } from '../../Layout';
import Header, { HeaderActionContainer, Button as HeaderButton } from '../../Layout/Header';

class PriceListsScreen extends Component {

	state = {
        pricelists : null, modalEdit: null, modalCreate: null, searchQuery: ''
    }

	componentDidMount = async () => {
		this.props.getPriceLists();
	}

	renderLoading() {
		return <ActivityIndicator />
	}

	onClickEdit = id => {
		this.setState({
			modalEdit: {
				id,
				open: true
			}
		})
	}

	onClickCreate = () => {
		this.setState({
			modalCreate: {
				open: true
			}
		})
	}

	render() {

		const { searchQuery } = this.state;
        const { pricelists: { data, loaded} } = this.props;

		const filteredObjs = search({ query: searchQuery, data, attrs: ['name', 'sku', 'category']})

		if (!loaded) {
			return this.renderLoading();
		}

		return (
			<PageContainer>

				<Header title="List de Prix">
					<HeaderActionContainer>
						<SearchField
							id="outlined-with-placeholder"
							label="Search"
							placeholder="Search"
							variant="outlined"
							value={searchQuery}
							onChange={({ target: { value: searchQuery }}) => this.setState({ searchQuery })}
						/>
						<HasPermissions permissions={['create_pricelist']}>
							<HeaderButton onClick={this.onClickCreate}>
								Créer
							</HeaderButton>
						</HasPermissions>

					</HeaderActionContainer>
				</Header>

				<Content>
					<PriceListsTable
						data={filteredObjs}
						onClickEdit={this.onClickEdit}
					/>

					{(this.state.modalEdit || this.state.modalCreate) &&
						<EditCreatePriceListModal
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							open
							id={this.state.modalEdit ? this.state.modalEdit.id : null}
							onClose={() => this.setState({ modalEdit: null, modalCreate: null })}
							onSaved={() => this.setState({ modalEdit: null, modalCreate: null })}
						/>
					}
				</Content>

			</PageContainer>
		);
	}

}


export default connect(
	state => ({
		pricelists: state.pricelists
	}),
	{ getPriceLists }
)(withSnackbar(PriceListsScreen));