import React from "react";
import { connect } from "react-redux";

import TextField from 'AppCore/Components/TextField';
import MenuItem from '@material-ui/core/MenuItem';


const ClientSelector = ({ clients, clientId = "", onChange = () => {} }) => {
    return (

        <TextField
            select
            label={"Selection client"}
            placeholder={"Selectionnez un client"}
            value={clientId}

            onChange={({ target: { value: clientId} }) => onChange(clientId)}

        >
            <MenuItem key={""} value={""}>
                TOUS
            </MenuItem>
            {clients.map( ({id, name}) => (
                <MenuItem key={id} value={id}>
                    {name}
                </MenuItem>
            ))}
        </TextField>
    )
}


export default (connect(
	state => ({
		clients: (state.shops && state.shops.data) || [],
	}),
	{}
)((ClientSelector)));