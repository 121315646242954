import Api from 'Meth/api/request';

export const checkParam = (param, type, param_name, required = false) => {

	if ((param === undefined || param === null) && !required) {
		return true;
	}

	let error = false;
	switch (true) {
		case type === 'string' && typeof param !== 'string' :
		case type === 'array' && !Array.isArray(param) :
			error = true;
			break;
		default:
			break;
	}

	if (error) {
		throw new Error(`${param_name} must be a ${type} !`);
	}

	return true;
}

export default {
	
	/*
		
	*/
	getConf: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `conf`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		List all api end points availalble
	*/
	getSchema: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `schema`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		Auth method for Users
	*/
	userLogin: ({ username, password, filter_debug = true } = {}) => {
		checkParam(username, 'string', 'username', true)
		checkParam(password, 'string', 'password', true)
		return Api.call({
			uri : `auth/login`,
			request_method: Api.request_method.POST,
			data: {
				username,
				password,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	userLogout: ({ revoke_token, filter = null, filter_debug = true } = {}) => {
		checkParam(revoke_token, 'string', 'revoke_token', true)
		return Api.call({
			uri : `auth/logout`,
			request_method: Api.request_method.GET,
			params: {
				revoke_token,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	magicLinkSend: ({ email, is_offline, from_email, filter = null, filter_debug = true } = {}) => {
		checkParam(email, 'string', 'email', true)
		checkParam(is_offline, 'boolean', 'is_offline', false)
		checkParam(from_email, 'string', 'from_email', false)
		return Api.call({
			uri : `auth/magiclink/send`,
			request_method: Api.request_method.GET,
			params: {
				email,
				is_offline,
				from_email,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	magicLinkLogin: ({ email, code, from_email, filter = null, filter_debug = true } = {}) => {
		checkParam(email, 'string', 'email', true)
		checkParam(code, 'string', 'code', true)
		checkParam(from_email, 'string', 'from_email', false)
		return Api.call({
			uri : `auth/magiclink/login`,
			request_method: Api.request_method.GET,
			params: {
				email,
				code,
				from_email,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getAllUsers: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `user/all`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		create user
	*/
	createUser: ({ user: { id, disabled, username, email, picture_id, picture, first_name, last_name, display_name, permissions } = {}, filter_debug = true } = {}) => {
		checkParam(id, 'string', 'id', false)
		checkParam(disabled, 'boolean', 'disabled', false)
		checkParam(username, 'string', 'username', false)
		checkParam(email, 'string', 'email', true)
		checkParam(picture_id, 'string', 'picture_id', false)
		checkParam(picture, 'object', 'picture', false)
		checkParam(first_name, 'string', 'first_name', false)
		checkParam(last_name, 'string', 'last_name', false)
		checkParam(display_name, 'string', 'display_name', false)
		checkParam(permissions, 'object', 'permissions', false)
		return Api.call({
			uri : `user/create`,
			request_method: Api.request_method.POST,
			data: {
				user: {
						id,
						disabled,
						username,
						email,
						picture_id,
						picture,
						first_name,
						last_name,
						display_name,
						permissions,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get a user with its id
	*/
	getUser: ({ user_id = "me", filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `user/${user_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateUser: ({ user_id = "me", user: { disabled, username, picture_id, first_name, last_name, permissions } = {}, filter_debug = true } = {}) => {
		checkParam(disabled, 'boolean', 'disabled', false)
		checkParam(username, 'string', 'username', false)
		checkParam(picture_id, 'string', 'picture_id', false)
		checkParam(first_name, 'string', 'first_name', false)
		checkParam(last_name, 'string', 'last_name', false)
		checkParam(permissions, 'object', 'permissions', false)
		return Api.call({
			uri : `user/${user_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				user: {
						disabled,
						username,
						picture_id,
						first_name,
						last_name,
						permissions,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		delete a user with its id
	*/
	deleteUser: ({ user_id = "me", filter_debug = true } = {}) => {
		return Api.call({
			uri : `user/${user_id}`,
			request_method: Api.request_method.DELETE,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateUserPassword: ({ user_id = "me", password, filter_debug = true } = {}) => {
		checkParam(password, 'undefined', 'password', true)
		return Api.call({
			uri : `user/${user_id}/password`,
			request_method: Api.request_method.POST,
			data: {
				password,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		Upload media
	*/
	upload: ({ file, filter_debug = true } = {}) => {
		checkParam(file, 'file', 'file', true)
		return Api.call({
			uri : `media`,
			request_method: Api.request_method.POST,
			data: {
				file,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get a Media with its id
	*/
	getMedia: ({ media_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `media/${media_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getLocations: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `location`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createLocation: ({ location: { code, name, address, type } = {}, filter_debug = true } = {}) => {
		checkParam(code, 'string', 'code', true)
		checkParam(name, 'string', 'name', true)
		checkParam(address, 'string', 'address', false)
		checkParam(type, 'string', 'type', false)
		return Api.call({
			uri : `location`,
			request_method: Api.request_method.POST,
			data: {
				location: {
						code,
						name,
						address,
						type,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get a location with its id or key
	*/
	getLocation: ({ location_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `location/${location_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateLocation: ({ location_id, location: { name, address, type } = {}, filter_debug = true } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(address, 'string', 'address', false)
		checkParam(type, 'string', 'type', false)
		return Api.call({
			uri : `location/${location_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				location: {
						name,
						address,
						type,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getLocationProducts: ({ location_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `location/${location_id}/products`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getLocationOrders: ({ location_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `location/${location_id}/orders`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	checkLocationKey: ({ location_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `location/${location_id}/check-key`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getProducts: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `product`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createProduct: ({ product: { sku, ean13, dlc_days, name, category, color, cost_price, picture_id } = {}, filter_debug = true } = {}) => {
		checkParam(sku, 'string', 'sku', true)
		checkParam(ean13, 'string', 'ean13', false)
		checkParam(dlc_days, 'integer', 'dlc_days', false)
		checkParam(name, 'string', 'name', true)
		checkParam(category, 'string', 'category', false)
		checkParam(color, 'string', 'color', false)
		checkParam(cost_price, 'integer', 'cost_price', false)
		checkParam(picture_id, 'string', 'picture_id', false)
		return Api.call({
			uri : `product`,
			request_method: Api.request_method.POST,
			data: {
				product: {
						sku,
						ean13,
						dlc_days,
						name,
						category,
						color,
						cost_price,
						picture_id,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getProductsByCategories: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `product/categories`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get a product with its id or key
	*/
	getProduct: ({ product_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `product/${product_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateProduct: ({ product_id, product: { ean13, dlc_days, disabled, name, category, color, cost_price, picture_id } = {}, filter_debug = true } = {}) => {
		checkParam(ean13, 'string', 'ean13', false)
		checkParam(dlc_days, 'integer', 'dlc_days', false)
		checkParam(disabled, 'boolean', 'disabled', false)
		checkParam(name, 'string', 'name', true)
		checkParam(category, 'string', 'category', false)
		checkParam(color, 'string', 'color', false)
		checkParam(cost_price, 'integer', 'cost_price', false)
		checkParam(picture_id, 'string', 'picture_id', false)
		return Api.call({
			uri : `product/${product_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				product: {
						ean13,
						dlc_days,
						disabled,
						name,
						category,
						color,
						cost_price,
						picture_id,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createProductItem: ({ product_item: { product_id, epc, location_id } = {}, filter_debug = true } = {}) => {
		checkParam(product_id, 'string', 'product_id', true)
		checkParam(epc, 'string', 'epc', true)
		checkParam(location_id, 'string', 'location_id', false)
		return Api.call({
			uri : `product-item`,
			request_method: Api.request_method.POST,
			data: {
				product_item: {
						product_id,
						epc,
						location_id,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createOrReplaceProductItem: ({ product_item: { product_id, epc, location_id } = {}, filter_debug = true } = {}) => {
		checkParam(product_id, 'string', 'product_id', true)
		checkParam(epc, 'string', 'epc', true)
		checkParam(location_id, 'string', 'location_id', false)
		return Api.call({
			uri : `product-item/create-or-replace`,
			request_method: Api.request_method.POST,
			data: {
				product_item: {
						product_id,
						epc,
						location_id,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getProductItemsByEpcList: ({ epcList, filter_debug = true } = {}) => {
		checkParam(epcList, 'undefined', 'epcList', true)
		return Api.call({
			uri : `product-item/by-epc`,
			request_method: Api.request_method.POST,
			data: {
				epcList,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get a product_item with its id or key
	*/
	getProductItem: ({ product_item_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `product-item/${product_item_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateProductItem: ({ product_item_id, product_item: { product_id } = {}, filter_debug = true } = {}) => {
		checkParam(product_id, 'string', 'product_id', true)
		return Api.call({
			uri : `product-item/${product_item_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				product_item: {
						product_id,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateProductItemLocation: ({ product_item_id, product_item: { location_id } = {}, filter_debug = true } = {}) => {
		checkParam(location_id, 'string', 'location_id', false)
		return Api.call({
			uri : `product-item/${product_item_id}/set-location`,
			request_method: Api.request_method.POST,
			data: {
				product_item: {
						location_id,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getOrders: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `order`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createPosOrder: ({ product_item_ids, filter_debug = true } = {}) => {
		checkParam(product_item_ids, 'undefined', 'product_item_ids', false)
		return Api.call({
			uri : `order/pos-create`,
			request_method: Api.request_method.POST,
			params: {
				product_item_ids,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get a order with its id or key
	*/
	getOrder: ({ order_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `order/${order_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	commitOrder: ({ order_id, transaction_infos, filter_debug = true } = {}) => {
		checkParam(transaction_infos, 'undefined', 'transaction_infos', false)
		return Api.call({
			uri : `order/${order_id}/commit`,
			request_method: Api.request_method.POST,
			data: {
				transaction_infos,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getShops: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `shop`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getAllShops: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `shop/all`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createShop: ({ shop: { name, pricelist_id, key, contact_name, contact_email, tel, mobile, address, address_infos, cp, city, billing_address, contact_accounting, container_type, split_bl_orders, stripe_invoice_payment, truck_delivery, hour_delivery, hour_dropoff, delivery_picture, delivery_signature, settings, category_product_pourcent } = {}, filter_debug = true } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(pricelist_id, 'string', 'pricelist_id', false)
		checkParam(key, 'string', 'key', false)
		checkParam(contact_name, 'string', 'contact_name', false)
		checkParam(contact_email, 'string', 'contact_email', false)
		checkParam(tel, 'string', 'tel', false)
		checkParam(mobile, 'string', 'mobile', false)
		checkParam(address, 'string', 'address', false)
		checkParam(address_infos, 'string', 'address_infos', false)
		checkParam(cp, 'string', 'cp', false)
		checkParam(city, 'string', 'city', false)
		checkParam(billing_address, 'string', 'billing_address', false)
		checkParam(contact_accounting, 'object', 'contact_accounting', false)
		checkParam(container_type, 'string', 'container_type', false)
		checkParam(split_bl_orders, 'boolean', 'split_bl_orders', false)
		checkParam(stripe_invoice_payment, 'boolean', 'stripe_invoice_payment', false)
		checkParam(truck_delivery, 'integer', 'truck_delivery', false)
		checkParam(hour_delivery, 'integer', 'hour_delivery', false)
		checkParam(hour_dropoff, 'integer', 'hour_dropoff', false)
		checkParam(delivery_picture, 'boolean', 'delivery_picture', false)
		checkParam(delivery_signature, 'boolean', 'delivery_signature', false)
		checkParam(settings, 'object', 'settings', false)
		checkParam(category_product_pourcent, 'object', 'category_product_pourcent', false)
		return Api.call({
			uri : `shop/create`,
			request_method: Api.request_method.POST,
			data: {
				shop: {
						name,
						pricelist_id,
						key,
						contact_name,
						contact_email,
						tel,
						mobile,
						address,
						address_infos,
						cp,
						city,
						billing_address,
						contact_accounting,
						container_type,
						split_bl_orders,
						stripe_invoice_payment,
						truck_delivery,
						hour_delivery,
						hour_dropoff,
						delivery_picture,
						delivery_signature,
						settings,
						category_product_pourcent,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getShop: ({ shop_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `shop/${shop_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateShop: ({ shop_id, shop: { name, pricelist_id, key, contact_name, contact_email, tel, mobile, address, address_infos, cp, city, billing_address, contact_accounting, container_type, split_bl_orders, stripe_invoice_payment, truck_delivery, hour_delivery, hour_dropoff, delivery_picture, delivery_signature, settings, category_product_pourcent } = {}, filter_debug = true } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(pricelist_id, 'string', 'pricelist_id', false)
		checkParam(key, 'string', 'key', false)
		checkParam(contact_name, 'string', 'contact_name', false)
		checkParam(contact_email, 'string', 'contact_email', false)
		checkParam(tel, 'string', 'tel', false)
		checkParam(mobile, 'string', 'mobile', false)
		checkParam(address, 'string', 'address', false)
		checkParam(address_infos, 'string', 'address_infos', false)
		checkParam(cp, 'string', 'cp', false)
		checkParam(city, 'string', 'city', false)
		checkParam(billing_address, 'string', 'billing_address', false)
		checkParam(contact_accounting, 'object', 'contact_accounting', false)
		checkParam(container_type, 'string', 'container_type', false)
		checkParam(split_bl_orders, 'boolean', 'split_bl_orders', false)
		checkParam(stripe_invoice_payment, 'boolean', 'stripe_invoice_payment', false)
		checkParam(truck_delivery, 'integer', 'truck_delivery', false)
		checkParam(hour_delivery, 'integer', 'hour_delivery', false)
		checkParam(hour_dropoff, 'integer', 'hour_dropoff', false)
		checkParam(delivery_picture, 'boolean', 'delivery_picture', false)
		checkParam(delivery_signature, 'boolean', 'delivery_signature', false)
		checkParam(settings, 'object', 'settings', false)
		checkParam(category_product_pourcent, 'object', 'category_product_pourcent', false)
		return Api.call({
			uri : `shop/${shop_id}`,
			request_method: Api.request_method.POST,
			data: {
				shop: {
						name,
						pricelist_id,
						key,
						contact_name,
						contact_email,
						tel,
						mobile,
						address,
						address_infos,
						cp,
						city,
						billing_address,
						contact_accounting,
						container_type,
						split_bl_orders,
						stripe_invoice_payment,
						truck_delivery,
						hour_delivery,
						hour_dropoff,
						delivery_picture,
						delivery_signature,
						settings,
						category_product_pourcent,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getClientInvoices: ({ shop_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `shop/${shop_id}/invoices`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateClientProductListInfos: ({ shop_id, shop: { productListInfos } = {}, filter_debug = true } = {}) => {
		checkParam(productListInfos, 'object', 'productListInfos', false)
		return Api.call({
			uri : `shop/${shop_id}/productlist-infos`,
			request_method: Api.request_method.POST,
			data: {
				shop: {
						productListInfos,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getShopSettings: ({ shop_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `shop/${shop_id}/settings`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateShopSettings: ({ shop_id, settings, filter_debug = true } = {}) => {
		checkParam(settings, 'undefined', 'settings', true)
		return Api.call({
			uri : `shop/${shop_id}/settings`,
			request_method: Api.request_method.POST,
			data: {
				settings,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getKitchenOrderByShopDay: ({ shop_id, day, filter = null, filter_debug = true } = {}) => {
		checkParam(shop_id, 'undefined', 'shop_id', true)
		checkParam(day, 'undefined', 'day', true)
		return Api.call({
			uri : `kitchenorder/get-by-shop-day`,
			request_method: Api.request_method.GET,
			params: {
				shop_id,
				day,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	setKitchenOrder: ({ kitchenorder: { shop_id, day, comment, shopProductList } = {}, kitchenorder_id, filter_debug = true } = {}) => {
		checkParam(shop_id, 'string', 'shop_id', true)
		checkParam(day, 'string', 'day', true)
		checkParam(comment, 'string', 'comment', false)
		checkParam(shopProductList, 'object', 'shopProductList', false)
		checkParam(kitchenorder_id, 'string', 'kitchenorder_id', false)
		return Api.call({
			uri : `kitchenorder/set`,
			request_method: Api.request_method.POST,
			data: {
				kitchenorder: {
						shop_id,
						day,
						comment,
						shopProductList,
					},
				kitchenorder_id,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getKitchenOrder: ({ kitchenorder_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchenorder/${kitchenorder_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenGetAllComputed: ({ from, to, type, filter = null, filter_debug = true } = {}) => {
		checkParam(from, 'undefined', 'from', false)
		checkParam(to, 'undefined', 'to', false)
		checkParam(type, 'undefined', 'type', false)
		return Api.call({
			uri : `kitchen/computed`,
			request_method: Api.request_method.GET,
			params: {
				from,
				to,
				type,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenDownloadPdf: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchen/pdf`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenDownloadPdfDailyRecap: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchen/pdf/daily`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenDownloadPdfNext30days: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchen/pdf/next30days`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenGetAllComputedByDay: ({ day, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchen/${day}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenGetAllComputedPeriod: ({ from, to, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchen/${from}-${to}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	kitchenGetAllComputedPeriodWithType: ({ from, to, type, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `kitchen/${from}-${to}/${type}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getAllClientOrders: ({ shop_id, day, from, to, filter = null, filter_debug = true } = {}) => {
		checkParam(shop_id, 'undefined', 'shop_id', false)
		checkParam(day, 'undefined', 'day', false)
		checkParam(from, 'undefined', 'from', false)
		checkParam(to, 'undefined', 'to', false)
		return Api.call({
			uri : `clientorder/all`,
			request_method: Api.request_method.GET,
			params: {
				shop_id,
				day,
				from,
				to,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getClientOrdersByShopDay: ({ shop_id, day, filter = null, filter_debug = true } = {}) => {
		checkParam(shop_id, 'undefined', 'shop_id', true)
		checkParam(day, 'undefined', 'day', true)
		return Api.call({
			uri : `clientorder/get-by-shop-day`,
			request_method: Api.request_method.GET,
			params: {
				shop_id,
				day,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getClientOrdersByShop: ({ shop_id, filter = null, filter_debug = true } = {}) => {
		checkParam(shop_id, 'undefined', 'shop_id', true)
		return Api.call({
			uri : `clientorder/get-by-shop`,
			request_method: Api.request_method.GET,
			params: {
				shop_id,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getClientOrdersByDay: ({ day, day_prod, filter = null, filter_debug = true } = {}) => {
		checkParam(day, 'string', 'day', false)
		checkParam(day_prod, 'string', 'day_prod', false)
		return Api.call({
			uri : `clientorder/get-by-day`,
			request_method: Api.request_method.GET,
			params: {
				day,
				day_prod,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	clientOrderDownloadAllPdfForDay: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `clientorder/all-pdf-for-day`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	clientOrderGetAllBlDataForDay: ({ day, day_prod, filter = null, filter_debug = true } = {}) => {
		checkParam(day, 'string', 'day', false)
		checkParam(day_prod, 'string', 'day_prod', false)
		return Api.call({
			uri : `clientorder/all-bldata-for-day`,
			request_method: Api.request_method.GET,
			params: {
				day,
				day_prod,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	generateNiceLabelsForDay: ({ client_id, day_prod, filter = null, filter_debug = true } = {}) => {
		checkParam(client_id, 'string', 'client_id', false)
		checkParam(day_prod, 'string', 'day_prod', false)
		return Api.call({
			uri : `clientorder/generate-nicelabels`,
			request_method: Api.request_method.GET,
			params: {
				client_id,
				day_prod,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createClientOrder: ({ clientorder: { shop_id, key, external_reference, day, day_prod, hour_delivery, hour_dropoff, delivery_picture, delivery_signature, comment, client, container_type, productList, truck_delivery, onfleet_task } = {}, filter_debug = true } = {}) => {
		checkParam(shop_id, 'string', 'shop_id', true)
		checkParam(key, 'string', 'key', false)
		checkParam(external_reference, 'string', 'external_reference', false)
		checkParam(day, 'string', 'day', true)
		checkParam(day_prod, 'string', 'day_prod', true)
		checkParam(hour_delivery, 'integer', 'hour_delivery', false)
		checkParam(hour_dropoff, 'integer', 'hour_dropoff', false)
		checkParam(delivery_picture, 'boolean', 'delivery_picture', false)
		checkParam(delivery_signature, 'boolean', 'delivery_signature', false)
		checkParam(comment, 'string', 'comment', false)
		checkParam(client, 'object', 'client', false)
		checkParam(container_type, 'string', 'container_type', true)
		checkParam(productList, 'array', 'productList', false)
		checkParam(truck_delivery, 'integer', 'truck_delivery', false)
		checkParam(onfleet_task, 'object', 'onfleet_task', false)
		return Api.call({
			uri : `clientorder/create`,
			request_method: Api.request_method.POST,
			data: {
				clientorder: {
						shop_id,
						key,
						external_reference,
						day,
						day_prod,
						hour_delivery,
						hour_dropoff,
						delivery_picture,
						delivery_signature,
						comment,
						client,
						container_type,
						productList,
						truck_delivery,
						onfleet_task,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getClientOrder: ({ order_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `clientorder/${order_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateClientOrder: ({ order_id, clientorder: { shop_id, key, external_reference, day, day_prod, hour_delivery, hour_dropoff, delivery_picture, delivery_signature, comment, client, container_type, productList, truck_delivery, onfleet_task } = {}, filter_debug = true } = {}) => {
		checkParam(shop_id, 'string', 'shop_id', true)
		checkParam(key, 'string', 'key', false)
		checkParam(external_reference, 'string', 'external_reference', false)
		checkParam(day, 'string', 'day', true)
		checkParam(day_prod, 'string', 'day_prod', true)
		checkParam(hour_delivery, 'integer', 'hour_delivery', false)
		checkParam(hour_dropoff, 'integer', 'hour_dropoff', false)
		checkParam(delivery_picture, 'boolean', 'delivery_picture', false)
		checkParam(delivery_signature, 'boolean', 'delivery_signature', false)
		checkParam(comment, 'string', 'comment', false)
		checkParam(client, 'object', 'client', false)
		checkParam(container_type, 'string', 'container_type', true)
		checkParam(productList, 'array', 'productList', false)
		checkParam(truck_delivery, 'integer', 'truck_delivery', false)
		checkParam(onfleet_task, 'object', 'onfleet_task', false)
		return Api.call({
			uri : `clientorder/${order_id}`,
			request_method: Api.request_method.POST,
			data: {
				clientorder: {
						shop_id,
						key,
						external_reference,
						day,
						day_prod,
						hour_delivery,
						hour_dropoff,
						delivery_picture,
						delivery_signature,
						comment,
						client,
						container_type,
						productList,
						truck_delivery,
						onfleet_task,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	deleteClientOrder: ({ order_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `clientorder/${order_id}`,
			request_method: Api.request_method.DELETE,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	clientOrderDownloadPdf: ({ order_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `clientorder/${order_id}/pdf`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getInvoices: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	generateInvoicePdf: ({ client_id, month, filter_debug = true } = {}) => {
		checkParam(client_id, 'string', 'client_id', true)
		checkParam(month, 'string', 'month', true)
		return Api.call({
			uri : `invoice/generate-month`,
			request_method: Api.request_method.POST,
			data: {
				client_id,
				month,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getInvoiceWithToken: ({ invoice_id, invoice_token, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}-${invoice_token}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getInvoice: ({ invoice_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updateInvoice: ({ invoice_id, invoice: { name, discount } = {}, filter_debug = true } = {}) => {
		checkParam(name, 'string', 'name', false)
		checkParam(discount, 'float', 'discount', false)
		return Api.call({
			uri : `invoice/${invoice_id}`,
			request_method: Api.request_method.POST,
			data: {
				invoice: {
						name,
						discount,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	deleteInvoice: ({ invoice_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}`,
			request_method: Api.request_method.DELETE,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getInvoicePaymentSession: ({ invoice_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}/get-payment-session`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	markInvoiceAsPaid: ({ invoice_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}/mark-as-paid`,
			request_method: Api.request_method.POST,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	markInvoiceAsSent: ({ invoice_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}/mark-as-sent`,
			request_method: Api.request_method.POST,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	markInvoiceAsUnpaid: ({ invoice_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}/remove-paid-status`,
			request_method: Api.request_method.POST,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	markInvoiceAsUnsent: ({ invoice_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}/remove-sent-status`,
			request_method: Api.request_method.POST,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	sendInvoiceByEmail: ({ invoice_id, email, filter_debug = true } = {}) => {
		checkParam(email, 'string', 'email', true)
		return Api.call({
			uri : `invoice/${invoice_id}/send`,
			request_method: Api.request_method.POST,
			data: {
				email,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getInvoicePdf: ({ invoice_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `invoice/${invoice_id}/pdf`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getPriceLists: ({ filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `pricelist`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	createPriceList: ({ pricelist: { name, prices } = {}, filter_debug = true } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(prices, 'object', 'prices', false)
		return Api.call({
			uri : `pricelist/create`,
			request_method: Api.request_method.POST,
			data: {
				pricelist: {
						name,
						prices,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	getPriceList: ({ pricelist_id, filter = null, filter_debug = true } = {}) => {
		return Api.call({
			uri : `pricelist/${pricelist_id}`,
			request_method: Api.request_method.GET,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	updatePriceList: ({ pricelist_id, pricelist: { name, prices } = {}, filter_debug = true } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(prices, 'object', 'prices', false)
		return Api.call({
			uri : `pricelist/${pricelist_id}`,
			request_method: Api.request_method.POST,
			data: {
				pricelist: {
						name,
						prices,
					},
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		
	*/
	deletePriceList: ({ pricelist_id, filter_debug = true } = {}) => {
		return Api.call({
			uri : `pricelist/${pricelist_id}`,
			request_method: Api.request_method.DELETE,
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		logWorkerOrderEmailListener
	*/
	logWorkerOrderEmailListener: ({ key, code, filter_debug = true } = {}) => {
		checkParam(key, 'undefined', 'key', true)
		checkParam(code, 'undefined', 'code', true)
		return Api.call({
			uri : `tools/order-email-listener/worker-login-code`,
			request_method: Api.request_method.POST,
			data: {
				key,
				code,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	},

	
	/*
		get stats for clientorders
	*/
	getStatsOrders: ({ client_ids, group_by, date_start, date_end, filter = null, filter_debug = true } = {}) => {
		checkParam(client_ids, 'string', 'client_ids', false)
		checkParam(group_by, 'string', 'group_by', false)
		checkParam(date_start, 'string', 'date_start', false)
		checkParam(date_end, 'string', 'date_end', false)
		return Api.call({
			uri : `stats/orders`,
			request_method: Api.request_method.GET,
			params: {
				client_ids,
				group_by,
				date_start,
				date_end,
			}
		}).then(result => {
			if (filter_debug && result.__DEBUG__) {
				delete result.__DEBUG__;
			}
			return result;
		}) 
	}
}