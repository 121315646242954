import React from "react";
import styled from "styled-components";

import {
  Box,
  Card,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 12px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;

  span {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const Percentage = styled(MuiTypography)`

  span {
    color: #f44336;
    padding-right: 10px;
    font-weight: bold;
  }
`;

function Stats({ title, amount, chip, percentageText, percentagecolor }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" mb={6}>
          {title}
        </Typography>
        <Typography variant="h3" mb={6}>
          <Box fontWeight="fontWeightRegular">{amount}</Box>
        </Typography>
        {percentageText && <Percentage
          variant="subtitle1"
          mb={6}
          percentagecolor={percentagecolor}
        >
          <span>{percentageText}</span> Since last week
        </Percentage>}
        {chip && <Chip label={chip} />}
      </CardContent>
    </Card>
  );
}

export default Stats;
