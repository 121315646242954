import React, { useState } from 'react'
import moment from 'moment';

import DatePicker from 'react-datepicker';
import Button from 'AppCore/Components/Button';
import ClientSelector from 'AppCore/Components/Selector/Client'

import Api from 'AppCore/api';

import { PageContainer, Content } from '../../Layout';
import Header from '../../Layout/Header';

import css from './style.css';


export default ({ location: { search }, ...props}) => {


    const [ loading, setLoading ] = useState(false);
    const [ result, setResult ] = useState(null);
    const [ selectedDate, setDate ] = useState(moment().toDate());

    const [ client_id, setClientId ] = useState("");

    const onChangeClientId = clientId => {
        setClientId(clientId);
    }

    const onClickGenerate = async () => {

        setLoading(true);
        setResult(null);

        const day_prod = moment(selectedDate).format('YYYYMMDD');

        const result = await Api.generateNiceLabelsForDay({
            day_prod,
            ...(client_id && client_id !== "" ? { client_id } : {})
        });

        console.log(result);
        setResult(result);

        setLoading(false);
    }

    return (

        <PageContainer>

            <Header title="Generate BL DB for nicelabel"></Header>

            <Content>
                <div className={css.Container}>

                    <ClientSelector onChange={onChangeClientId} clientId={client_id} />

                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                            selectedDate
                        }
                        highlightDates={[new Date()]}
                        onChange={date => {
                            setDate(date)
                        }}
                        locale="fr"
                        placeholderText="Selectionner une date de production"
                    />

                    <Button loading={loading} disabled={loading} variant="outlined" color="primary" onClick={onClickGenerate}>Generate Labels in DB</Button>

                </div>

                {result &&
                <div className={css.Container}>
                    {result.length} genéré!
                </div>
                }
            </Content>


        </PageContainer>

    )
}



