import React from "react";
import DataTable from './index';

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    canEdit = true
}) => {

    const match = (value, r) => {
        return value && value.match(r);
    }
    const r = new RegExp(searchQuery, 'i');
    const filteredProducts = data.filter(product => match(product.email, r) || match(product.display_name, r));

    return <DataTable
        data={filteredProducts}
        config={{
            cells: [
                { title: 'Email', key: 'email' },
                { title: 'Nom', key: 'display_name' }
            ],
            actions: canEdit ? [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ] : []
        }}
        getCell={(product, attr_name) => {
            if (attr_name === 'color') {
                return (
                    <div style={{ textAlign: 'center', padding: '5px', backgroundColor: product.color, color: 'white' }}>
                        {product.color}
                    </div>
                )
            }
            return product.hasOwnProperty(attr_name) ? product[attr_name] : '';
        }}
    />

}