import React, { useState } from 'react'
import queryString from 'query-string';

import TextField from 'AppCore/Components/TextField';
import Button from 'AppCore/Components/Button';

import Api from 'AppCore/api';

import { PageContainer, Content } from '../../Layout';
import Header from '../../Layout/Header';

import css from './style.css';


export default ({ location: { search }, ...props}) => {

    const { key: keyFromProp = null } = queryString.parse(search);

    const [ key, setKey ] = useState(keyFromProp || '');
    const [ code, setCode ] = useState('');

    const [ loading, setLoading ] = useState(false);

    const onClickSet = async () => {

        if (key === '' && code === '') {
            alert("missing infos");
            return false;
        }

        setLoading(true);

        console.log(await Api.logWorkerOrderEmailListener({ key, code }));

        setKey("");
        setCode("");
        setLoading(false);
    }

    return (

        <PageContainer>

            <Header title="Code Manager"></Header>

            <Content>
                <div className={css.Container}>
                    <TextField
                        label={"KEY"}
                        placeholder={"key"}
                        className={css.TextField}
                        value={key}
                        disabled={loading}
                        onChange={({ target: { value }}) => setKey(value)}
                    />

                    <TextField
                        label={"CODE"}
                        placeholder={"code"}
                        className={css.TextField}
                        value={code}
                        disabled={loading}
                        onChange={({ target: { value }}) => setCode(value)}
                    />


                    <Button loading={loading} disabled={loading} variant="outlined" color="primary" onClick={onClickSet}>SET</Button>

                </div>
            </Content>


        </PageContainer>

    )
}

