import Api from 'Meth/api/request';
import base64 from 'base-64';
import Generated, { checkParam } from './Generated';

export default {
	...Generated,


	getDeliverooPrices: () => {
		
		let username = 'admin';
		let password = '@shakishaki_2019$';
		let headers = new Headers();
		headers.set('Authorization', 'Basic ' + base64.encode(username + ":" + password));

		return fetch("http://localhost:8080/deliveroo-prices.json", {
			headers: headers
		}).then(res => res.json());
	},
	
	/*
		List all folders and files
	*/
	upload: ({ file, onUploadProgress = () => {} } = {}) => {
		checkParam(file, 'file', 'file', true)

		var data = new FormData();
		data.append('file', file);

		return Api.call({
			uri : `media`,
			config: {
				onUploadProgress
			},
			request_method: Api.request_method.POST,
			data
		})
	},
}