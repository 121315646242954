import React from "react";
import { NavLink } from 'react-router-dom'
import get from 'lodash.get';

import DataTable from './index';

const DefaultNavLinkComponent =
    ({ object, children, ...props }) => <NavLink {...props} to={"/admin/order/" + object.id}>{children}</NavLink>

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    getNavLink = null,
    NavLinkComponent = DefaultNavLinkComponent,
}) => {

    const match = (value, r) => {
        return value && value.match(r);
    }

    const r = new RegExp(searchQuery, 'i');
    const filteredObjs = data.filter(obj => {
        if (match(obj.location.name, r) || match(obj.location.code, r)) {
            return true;
        }

        for (var i = 0; i < obj.product_items.data.length; i++) {
            const product_item = obj.product_items.data[i];
            if (match(product_item.epc, r) || match(product_item.product.name, r)) {
                return true;
            }
        }

        return false;
    });

    return <DataTable
        data={filteredObjs}
        config={{
            cells: [
                ...(getNavLink === null ? [] : [
                    { title: "", key: 'navlink' }
                ]),
                { title: 'POS CODE', key: 'location.code' },
                { title: 'POS NAME', key: 'location.name' },
                { title: 'Product', key: 'product_items' },
                { title: 'Prix', key: 'price_total' },
            ],
            actions: [
                { icon_name: 'visibility', title: "View", Component: NavLinkComponent || React.Fragment },
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(obj, path_attr) => {

            if (path_attr === 'navlink') {
                if (NavLinkComponent) {
                    return <NavLinkComponent object={obj}>{obj.id.substr(-5)}</NavLinkComponent>
                }
                return getNavLink(obj)
            }

            if (path_attr === 'product_items') {
                return (
                    <div>
                        {obj.product_items.data.map(product_item => (
                            <div key={product_item.id}>{product_item.epc} => {product_item.product.name}</div>
                        ))}
                    </div>
                )
            }

            if (path_attr === 'price_total') {
                return (parseInt(get(obj, path_attr, ''), 10) / 100) + "euros";
            }
            return get(obj, path_attr, '');
        }}
    />

}