import React from "react";
import { ActivityIndicator as NativActivityIndicator } from "react-native";

import classNames from 'classnames';
import css from './index.module.css';

export const Content = ({ className, style = {}, ...props }) => (
	<div style={style} className={classNames(className, css.contentContainer)} {...props} />
)

export const PageContainer = ({ className, fullWidth = false, ...props }) => (
	<div className={classNames(css.PageContainer, {
		[css.PageContainerFull]: fullWidth
	}, className)} {...props} />
)


export const ActivityIndicator = () => <NativActivityIndicator />