import validator from 'validator';


export const not_empty = value => value.trim() !== ''
not_empty.error_msg = "Ne peut pas être vide";


export const url = url => validator.isURL(url);
url.error_msg = "Mauvais format d'url";


export const email = email => validator.isEmail(email);
email.error_msg = "Mauvais format d'email";

export const phone = phone => !!((/^((\+)33|0)[1-9](\d{2}){4}$/g).exec(phone))
phone.error_msg = "Mauvais format de téléphone";