import Constants from "AppCore/Constants";
import { ApiError } from 'Meth/utils/errors';

const { REDUCER: C } = Constants;

export const toggleMenu = menu_key => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.SIDEBAR_TOGGLE_MENU,
		menu_key
	});
}

export const displayModal = modal => async (dispatch) => {
	dispatch({
		type: C.DISPLAY_MODAL,
		modal
	});
}
export const hideModal = () => async (dispatch) => {
	dispatch({
		type: C.DISPLAY_MODAL,
		modal: null
	});
}


export const displayLogin = ({ display = true } = {}) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_LOGIN,
		display
	});
}

export const displayError = ({ display = true, error_msg = null, error = null }) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_ERROR,
		error,
		error_msg,
		display
	});
	if (!(error instanceof ApiError)) {
		console.error(error);
	}
}

export const displayLoading = ({ display = true } = {}) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_LOADING,
		display
	});
}

export const displayMobileMenu = ({ display = true } = {}) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_MOBILE_MENU,
		display
	});
}