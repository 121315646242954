import Constants from "AppCore/Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;


/*
	getOrders
*/
export const getOrders = () => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.getOrders();

		dispatch({
			type: C.SET_ORDER,
			orders: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createOrder
*/
export const createOrder = ({ order }) => async (dispatch, getState, { api, ws }) => {

	try {
		await api.createOrder({ order });
		await dispatch(getOrders());

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalOrder = ({ order_id, order }) => async (dispatch, getState, { api, ws }) => {
	const orders = JSON.parse(JSON.stringify(getState().orders));

	orders.data.forEach((_order, i) => {
		if (_order.id === order_id) {
			orders.data[i] = {
				...orders.data[i],
				...order
			}
		}
	});

	dispatch({
		type: C.SET_ORDER,
		orders
	});
}

export const updateOrder = ({ order_id, order }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_orders = {
		...(getState().orders)
	}

	dispatch(updateLocalOrder({ order_id, order }));

	try {


		const result = await api.updateOrder({ order_id, order });
		dispatch(getOrders())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_ORDER,
			orders: rollback_orders
		});
	}

}

export const deleteOrder = ({ order_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteOrder({ order_id });
		await dispatch(getOrders())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}