import initialState from "AppCore/Store/initialState";
import Constants from "AppCore/Constants";

const { REDUCER: C } = Constants;


const groupByCat = products => {

    const cats = {};

    products.data.forEach(product => {
        if (!cats[product.category]) {
            cats[product.category] = {
                id: product.category,
                name: product.category,
                products: []
            }
        }

        cats[product.category].products.push(product);
    })

    return Object.keys(cats).map(cat_id => cats[cat_id]);
}

export default (state = initialState.products, action) => {
	switch (action.type) {
	case C.SET_PRODUCTS:
		return {
			...state,
			...action.products,
			loaded: true,

            groupByCat: groupByCat(action.products)
		};
	case C.CLEAR_PRODUCTS:
		return initialState.products;
	default:
		return state;
	}
}