import Cookies from 'js-cookie';
import Constants from "AppCore/Constants";

import settings from 'config/settings';

export * from './locations';
export * from './orders';
export * from './productitems';
export * from './Product';
export * from './Shop';
export * from './PriceList';
export * from './display';

const { REDUCER: C } = Constants;

const setUser = ({ user }) => ({
	type: C.SET_CURRENT_USER,
	user
});

const setAuthData = ({ authData }) => ({
	type: C.SET_AUTH_DATA,
	authData
});

export const setSearchQueries = ({ name, value }) => async (dispatch, getState, { api }) => {

	const { searchQueries = {} } = getState();

	dispatch({
		type: C.SET_SEARCH_QUERIES,
		searchQueries: {
			...searchQueries,
			[name]: value
		}
	})
}

export const refreshCurrentUser = () => async (dispatch, getState, { api }) => {
	const { authData: { userLogged } } = getState();

	if (!userLogged) {
		return true;
	}

	dispatch(setUser({ user: await api.getUser({ user_id: 'me' }) }))

	return true;
}

export const logout = () => async (dispatch, getState, { api }) => {

	dispatch(setAuthData({ authData: {} }));
	dispatch(setUser({ user: {} }));

}

export const login = ({ username, password }) => async (dispatch, getState, { api }) => {
	const { authData, user, error } = await api.userLogin({
		username, password
	});
	if (error) {
		throw new Error("Error signin !");
	}
	dispatch(setAuthData({ authData }));
	dispatch(setUser({ user }));

	return user.id;
}

const processAuthData = async ( { authData, user, organisation, error } , dispatch) => {

	if (error) {
		throw new Error("Error signin !");
	}

	dispatch({
		type: C.SET_CURRENT_USER,
		user
	});

	dispatch({
		type: C.SET_AUTH_DATA,
		authData
	});

	Cookies.set("access_token", authData.access_token, { domain: settings.cookie_domain, expires: 365 })

	return user.id;
}


export const magicLinkLogin = ({ email, code }) => async (dispatch, getState, { api }) => {

	const authData = await api.magicLinkLogin({
			email,
			code
	});

	processAuthData(authData, dispatch);

	return authData;

}
