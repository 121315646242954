import StdObject from './StdObject'

var _model = {
	md5: true,
	type: true,
	ext: true,
	path: true,
};

export default class Picture extends StdObject {

	getModel() {
		return _model;
	}

}