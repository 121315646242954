import React, { PureComponent } from "react";

import Modal from 'AppCore/Components/Modal';

import Form from 'AppCore/Components/Form/formFields';
import Button from 'AppCore/Components/Button';

export default class ModalForm extends PureComponent {

	static defaultProps = {
		onSaved: () => {},
		onClose: () => {},
	}

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			saving: false
		}
	}

	componentDidMount = async () => {
        try {
            await this.loadData()
        } catch (e) {
            console.log(e);
        }
	}

	onClickSave = async () => {

		if (this.form.checkAllErrors()) {
			return false;
		}

		this.setState({ saving: true });
		const key = this.props.enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await this.save();

			this.props.closeSnackbar(key);
			this.props.enqueueSnackbar('Saved', {variant: 'success'});

			this.setState({ saving: false });
			this.props.onSaved();

		} catch (e) {
			this.props.closeSnackbar(key);
			this.props.enqueueSnackbar('Error', {variant: 'error'});
			this.setState({ saving: false });
		}
	}

	handleClose = () => {
		const { onClose = () => {} } = this.props;
		const { saving = false } = this.state;

		if (saving) {
			return false;
		}

		onClose();
	}

	renderForm = ({ formClassName = '' }) => {

		const { data } = this.state;

		return (
			<Form
				innerRef={form => this.form = form}
				config={{
					attributes: this.getFormAttributeConfig()
				}}
				values={data}
				onChange={({values: data, hasError}) => this.setState({ data, hasError })}
				className={formClassName}
			/>
		)
	}

	render() {

		const { loading = false, saving = false, hasError = false } = this.state;

		return (

			<Modal
				open
				drawer
				onClose={this.handleClose}
				title={this.getTitle()}
				actions={[
					<Button key="cancel" variant="text" color="primary" disabled={saving || loading} onClick={this.handleClose}>
						Cancel
					</Button>,
					<Button
						key="save"
						onClick={this.onClickSave}
						loading={saving}
						disabled={loading || saving || hasError}
					>
						save
					</Button>
				]}
			>

				{this.renderModalContent()}

			</Modal>

		)
	}

}