import Constants from "AppCore/Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getProductItems
*/
export const getProductItems = () => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.getProductItems();

		dispatch({
			type: C.SET_PRODUCTITEMS,
			productItems: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createProductItem
*/
export const createProductItem = ({ productItem }) => async (dispatch, getState, { api, ws }) => {

	try {
		await api.createProductItem({ productItem });
		await dispatch(getProductItems());

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalProductItem = ({ productItem_id, productItem }) => async (dispatch, getState, { api, ws }) => {
	const productItems = JSON.parse(JSON.stringify(getState().productItems));

	productItems.data.forEach((_productItem, i) => {
		if (_productItem.id === productItem_id) {
			productItems.data[i] = {
				...productItems.data[i],
				...productItem
			}
		}
	});

	dispatch({
		type: C.SET_PRODUCTITEMS,
		productItems
	});
}

export const updateProductItem = ({ productItem_id, productItem }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_productItems = {
		...(getState().productItems)
	}

	dispatch(updateLocalProductItem({ productItem_id, productItem }));

	try {


		const result = await api.updateProductItem({ productItem_id, productItem });
		dispatch(getProductItems())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_PRODUCTITEMS,
			productItems: rollback_productItems
		});
	}

}

export const deleteProductItem = ({ productItem_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteProductItem({ productItem_id });
		await dispatch(getProductItems())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}