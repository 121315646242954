import React, { PureComponent, Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect } from 'react-router-dom'
import { withSnackbar } from 'notistack';

import SearchField from 'AppCore/Components/SearchField';
import ActivityIndicator from "AppCore/Components/ActivityIndicator";
import DataTable from 'AppCore/Components/DataTable';

import Api from 'AppCore/api';
import { getLocations } from 'AppCore/Actions';

import FridgeScreen from './Fridge';

// import EditCreateUserModal from 'Screens/Modals/User/editCreate';

import { PageContainer, Content } from '../Layout';
import Header, { HeaderActionContainer, Button as HeaderButton } from '../Layout/Header';

export class LocationScreen extends PureComponent {

    state = { loading: true, location: null, data: null }
    getId() {
        const { match: { params: { id = null } = {} } = {} } = this.props;

        return id;
    }
    async componentDidMount() {
        const id = this.getId();

        if (!id) {
            return;
        }

        this.loadData();
    }
    async loadData() {
        const location_id = this.getId();
        if (!location_id) {
            return;
        }
        const location = await Api.getLocation({ location_id });

        const ApiCalls = {};

        switch (location.type) {
            case 'fridge':
                ApiCalls.products = Api.getLocationProducts({ location_id });
                ApiCalls.orders = Api.getLocationOrders({ location_id });
                break;
            default:
                break;
        }

        const ApiCallsKeys = Object.keys(ApiCalls);
        await Promise.all(Object.keys(ApiCalls).map(key => ApiCalls[key]));

        const data = {};
        for (var i = 0; i < ApiCallsKeys.length; i++) {
            const key = ApiCallsKeys[i];
            data[key] = await ApiCalls[key];
        }

        this.setState({
            location,
            data,
            loading: false
        });

    }
    render() {
        const id = this.getId();
        const { location = null, data = {} } = this.state;

        if (!id) {
            return <Redirect to="/error" />
        }

        if (this.state.loading) {
            return <ActivityIndicator />
        }

        switch (location.type) {
            case 'fridge':
                return <FridgeScreen id={id} fridge={location} {...data} reload={this.loadData} />;
            default:
                break;
        }

        return null;
    }
}

class LocationsScreen extends Component {

	state = {
        locations : null, modalEdit: null, modalCreate: null, searchQuery: ''
    }

	componentDidMount = async () => {
		this.props.getLocations();
	}

	renderLoading() {
		return <ActivityIndicator />
	}

	onClickEdit = location_id => {
        alert("Fonction non disponible");
        return false;
		// this.setState({
		// 	modalEdit: {
		// 		location_id,
		// 		open: true
		// 	}
		// })
	}

	onClickCreate = () => {
        alert("Fonction non disponible");
        return false;
		// this.setState({
		// 	modalCreate: {
		// 		open: true
		// 	}
		// })
	}

	render() {

		const { searchQuery } = this.state;
        const { locations: { data, loaded} } = this.props;

		if (!loaded) {
			return this.renderLoading();
		}

		const match = (value, r) => {
			return value && value.match(r);
		}

		const r = new RegExp(searchQuery, 'i');
		const filteredObjs = data.filter(obj => match(obj.name, r) || match(obj.code, r) || match(obj.address, r));

		return (
			<PageContainer>

				<Header title="POS / Others Points">
					<HeaderActionContainer>
						<SearchField
							id="outlined-with-placeholder"
							label="Search"
							placeholder="Search"
							variant="outlined"
							value={searchQuery}
							onChange={({ target: { value: searchQuery }}) => this.setState({ searchQuery })}
						/>
						<HeaderButton onClick={this.onClickCreate}>
							New Location
						</HeaderButton>
					</HeaderActionContainer>
				</Header>

				<Content>
					<DataTable
						data={filteredObjs}
						config={{
							cells: [
								{ title: 'Code', key: 'code' },
								{ title: 'Name', key: 'name' },
								{ title: 'Adresse', key: 'address' },
								{ title: 'Type', key: 'type' },
								{ title: 'Nb Produits', key: 'count_items_not_sold' },
							],
							actions: [
								{ icon_name: 'edit', title: "Edit", onClick: this.onClickEdit }
							]
						}}
						getCell={(obj, attr_name) => {

                            if (attr_name === 'code') {
                                return (
                                    <NavLink to={"/fridge/"+obj.id}>{obj[attr_name]}</NavLink>
                                )
                            }

							return obj[attr_name] || '';
						}}
					/>

					{/* {(this.state.modalEdit || this.state.modalCreate) &&
						<EditCreateUserModal
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							open
							user_id={this.state.modalEdit ? this.state.modalEdit.user_id : null}
							onClose={() => this.setState({modalEdit: null, modalCreate: null})}
							onSaved={() => this.setState({modalEdit: null, modalCreate: null})}
						/>
					} */}
				</Content>

			</PageContainer>
		);
	}

}


export default connect(
	state => ({
		locations: state.locations
	}),
	{ getLocations }
)(withSnackbar(LocationsScreen));