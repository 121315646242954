import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { ModalContent } from 'AppCore/Components/Modal';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import { updateShop, createShop, displayError } from 'AppCore/Actions';
import Api from 'AppCore/api';

import css from './editCreate.module.css';

import ModalForm from '../ModalForm'


class EditClientModal extends ModalForm {

	getFormAttributeConfig = () => {

		const { loading = false, saving = false } = this.state;

		return {
			key: { type: 'text', validators: ['not_empty'], disabled: loading || saving},
			name: { type: 'text', disabled: loading || saving },
            contact_name: { label: "Nom du contact", type: 'text', disabled: loading || saving },
            contact_email: { label: "Email du contact", type: 'text', disabled: loading || saving},
            tel: { label: "Telephone du contact", type: 'text', disabled: loading || saving},
            address: { label: "Adresse de livraison", type: 'text', multiline: 4, disabled: loading || saving },
            billing_address: { label: "Adresse de facturation", type: 'text', multiline: 4, disabled: loading || saving },
		};
	}

	loadData = async () => {
		this.setState({
			data: await this.getClientData(),
			loading: false
		})
	}

	getClientData = async () => {
		const { client_id } = this.props;
		if (client_id) {
            const client = await Api.getShop({ shop_id: client_id });
			return client;
		}

		return {};
	}

	save = async () => {

		const { client_id, displayError } = this.props;
        const { data: client } = this.state;

        console.log("client", client);

        client.settings = {
            has_day_orders: client['settings.has_day_orders']
        }

		try {

			if (client_id) {
				await this.props.updateShop({ shop_id: client_id, shop: client });
			} else {
				await this.props.createShop({ shop: client });
			}

		} catch (error) {
            console.log(error);
            displayError({ error })

            throw error;
		}
	}

	renderModalContent() {

		const { loading = false } = this.state;

		return (loading ? <ActivityIndicator /> :

			<ModalContent>

				<div className={css.formContainer}>
					{this.renderForm({ formClassName: css.form })}
				</div>
			</ModalContent>);
	}

	getTitle() {
		const { client_id } = this.props;
		return client_id ? "Modifier un client" : "Nouveau client"
	}


}


export default connect(
	state => ({

	}),
	{ updateShop, createShop, displayError }
)(withSnackbar(EditClientModal));