import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Api from "AppCore/api";
import { magicLinkLogin, displayError, displayLoading } from "AppCore/Actions";

import Button from "AppCore/Components/Button";

class LoginScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			code: '',
			domain: 'shakishaki.com',
			step: 1,
			loading: false
		}
	}

	checkEmailAndSendCode = async () => {
		const { email, domain } = this.state;

		this.setState({ loading: true });

		try {

			const sendCodeMethod = this.props.sendCodeMethod || Api.magicLinkSend;

			const result = await sendCodeMethod({ email, domain, is_offline: !window.navigator.onLine });
			if (!result.success) {
				throw new Error("Error unknown");
			}
			if (result.pin_code) {
				alert(result.pin_code);
			}

			this.setState({
				step: 2
			})
		} catch (e) {
			this.props.enqueueSnackbar(e.message, {variant: 'error'});
		}

		this.setState({ loading: false });
	}

	signin = async () => {

		this.setState({ loading: true });
		try {

			this.props.displayLoading();
			const signinMethod = this.props.signinMethod || this.props.magicLinkLogin;
			await signinMethod({
				email: this.state.email,
				code: this.state.code,
			});

			this.props.history.push('/');

		} catch (e) {
			this.props.displayError({ error_msg: "Signin error", error: e });
		}

		this.props.displayLoading({ display: false });

	}

	renderStep1() {
		return (
			<div>
				<Paper style={{padding: '3em',  paddingTop: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center'}} elevation={1}>
					<h1 style={{textAlign: 'center', fontWeight: '300'}}>Connectez vous</h1>
					<TextField
						id="email"
						label="Email"
						onChange={({ target: { value: email } }) => this.setState({ email })}
						value={this.state.email}
						type="email"
						name="email"
						autoComplete="email"
						margin="normal"
						variant="outlined"
						style={{width: '500px'}}
	        		/>
					<Button
						key="sendCode"
						disabled={this.state.loading}
						loading={this.state.loading}
						onPress={this.checkEmailAndSendCode}
					>Cliquez pour recevoir un code par email</Button>
				</Paper>
			</div>
		)
	}

	renderStep2() {
		return (
			<Paper style={{padding: '3em', paddingTop: '1em', display: 'flex', flexDirection: 'column'}} elevation={1}>

				<h1
					style={{textAlign: 'center', fontWeight: '400'}}
				>Check your email !</h1>
				<p>
					We've emailed a special link to <span style={{fontWeight: 'bold'}}>{this.state.email}</span><br/>
					Enter the code received by email to connect
				</p>
				<div style={{width: '100%', textAlign: 'center'}}>
					<TextField
						label="Code"
						onChange={({ target: { value: code } }) => this.setState({ code })}
						value={this.state.code}
						margin="normal"
						variant="outlined"
					/>
					<br/>
					<Button
						key="login"
						disabled={this.state.loading}
						loading={this.state.loading}
						onPress={this.signin}
					>Login</Button>
				</div>
			</Paper>
		)
	}


	render() {
		if (this.state.step === 1) return this.renderStep1();
		if (this.state.step === 2) return this.renderStep2();
	}

}


export default withRouter(connect(
	state => ({}),
	{ magicLinkLogin, displayError, displayLoading }
)(withSnackbar(LoginScreen)));