import React, { Component } from 'react'
import { connect } from "react-redux";
import SearchField from 'Components/SearchField';

import ClientsTable from 'AppCore/Components/DataTable/Clients';
import { getShops } from 'AppCore/Actions';

import HasPermissions from 'AppCore/Components/HasPermissions';

import { displayModal } from 'AppCore/Actions'

import { PageContainer, Content } from '../Layout';
import Header, { HeaderActionContainer, Button as HeaderButton } from '../Layout/Header';

class ShopsScreen extends Component {

	componentDidMount() {
		this.props.getShops();
	}

	onClickCreate = () => {
		this.props.displayModal({
			name: 'edit_create_client',
			props: {
				client_id: null
			}
		})
	}

	render() {

		const { searchQuery } = this.props;
		const data = this.props.shops;

		return (

			<PageContainer>

				<Header title="Clients">
					<HeaderActionContainer>
						<SearchField
							connectTo="shop_list"
						/>

						<HasPermissions permissions={['create_client']}>
							<HeaderButton onClick={this.onClickCreate}>
								Nouveau Client
							</HeaderButton>
						</HasPermissions>
					</HeaderActionContainer>
				</Header>

				<Content>
					<ClientsTable data={data} searchQuery={searchQuery} />
				</Content>

			</PageContainer>

		)
	}
}

export default connect(
	state => ({
		shops: (state.shops && state.shops.data) || [],
		searchQuery: (state.searchQueries && state.searchQueries.shop_list) || ""
	}),
	{ getShops, displayModal }
)(ShopsScreen);

