import React from "react";
import DataTable from './index';

import { sort as sortProduct } from 'AppCore/Utils/Product'

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,

}) => {

    const sortedData = data

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    } else {
        sortedData.sort(sortProduct);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: 'SKU', key: 'sku' },
                { title: 'Categorie', key: 'category' },
                { title: 'Name', key: 'name' },
                { title: 'Color', key: 'color' },
            ],
            actions: [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {
            if (attr_name === 'color') {
                return (
                    <div style={{ textAlign: 'center', padding: '5px', backgroundColor: item.color, color: 'white' }}>
                        {item.color}
                    </div>
                )
            }
            return item.hasOwnProperty(attr_name) ? item[attr_name] : '';
        }}
    />

}