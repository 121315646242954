import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { getSettings } from 'Meth/config/settings'

import ClientOrdersTable from 'AppCore/Components/DataTable/ClientOrders';

import Api from 'AppCore/api';

import { PageContainer, Content } from 'Screens/App/Layout';
import Header from 'Screens/App/Layout/Header';

import CommonCss from '../common.css';

import Grid, { GridBox } from '../../../Components/Grid';

class CustomDateInput extends React.PureComponent {
    render() {
        const { value, onClick } = this.props;
        return (
            <Button variant="outlined" color="primary" onClick={onClick}>
                {value}
            </Button>
        );
    }
}

class KitchenScreen extends PureComponent {

	constructor(props) {
		super(props);

		var day = moment();

		this.state = {

			loading: true,
			tab_index: 0,

			isToday: true,
			orderDate: day.toDate(),
			orderDay: day.format('YYYYMMDD'),

			clientOrders: [],
		}
	}

	setStateAsync = async (state) => {
		var result = await new Promise((resolve, reject) => {
			this.setState(state, resolve);
		})
		await new Promise((resolve) => {
			setTimeout(resolve, 0)
		})

		return result;
	}

	loadClientOrders = async () => {
		this.setState({
			loading: true
		})

		var day_prod = this.state.orderDay;

		var clientOrders = (await Api.getClientOrdersByDay({ day_prod })).data;

		await this.setStateAsync({clientOrders, loading: false});
	}

	componentDidMount() {
		this.loadClientOrders();
	}

	setDate = date => {
		const day = moment(date);

		this.setState({
			orderDay: day.format('YYYYMMDD'),
			orderDate: date,
			isToday: day.format('YYYYMMDD') === moment().format('YYYYMMDD')
		}, () => {
			this.loadClientOrders();
		})
	}

	render() {

		const { canSelectDate = false } = this.props;
		const { orderDate, isToday } = this.state;

		return (
			<PageContainer>

				<Header title="Commandes client">
					{canSelectDate &&
					<DatePicker
						dateFormat="dd/MM/yyyy"
						selected={orderDate}
						showPopperArrow={false}
						onChange={this.setDate}
						customInput={<CustomDateInput />}

						popperModifiers={{
							offset: {
								enabled: true,
								offset: "5px, 10px"
							},
							preventOverflow: {
								enabled: true,
								escapeWithReference: false,
								boundariesElement: "viewport"
							}
						}}
					/>
					}
				</Header>

				<Content>

					<Grid cols={1} className={CommonCss.grid}>
						<GridBox className={CommonCss.gridbox}>
							<div className={CommonCss.gridboxContent}>
								{isToday && <div>
									Toutes les commandes à produire aujourd'hui pour livrer ce soir ou demain
								</div>}
								{!isToday && <div>
									Toutes les commandes à produire le {moment(orderDate).format('LL')} pour livrer le soir ou le lendemain
								</div>}
								<Button variant="contained" color="secondary" onClick={e => {
									window.open(getSettings().HOST_API + "/clientorder/all-pdf-for-day?day_prod=" + this.state.orderDay);
								}}>
									<Icon className={CommonCss.ButtonIcon}>cloud_download</Icon>
									Télécharger tous les bons de livraison
								</Button>
							</div>

						</GridBox>
					</Grid>

					<Grid cols={1} className={CommonCss.grid}>
						<GridBox className={CommonCss.gridbox}>

							{this._renderOrdersAllShops()}

						</GridBox>
					</Grid>
				</Content>
			</PageContainer>
		)
	}

	_renderLoading() {
		return (
			<CircularProgress />
		);
	}

	_renderOrdersAllShops() {

		if (this.state.loading) {
			return this._renderLoading()
		}

		return (
			<div className={CommonCss.tableContainer}>
				<ClientOrdersTable
					data={this.state.clientOrders}
					sort={(a, b) => {
						console.log(",", a);
						return a.hour_delivery > b.hour_delivery ? -1: 1;
					}}
					canView={false}
					canEdit={() => false}
				/>
			</div>
		)
	}


}


export default connect(
	state => ({
	}),
	{}
)(KitchenScreen);

