import React from 'react';
import Icons from './icons'
import { TouchableOpacity } from 'react-native';

export default ({ onPress, ...props }) => {
	if (onPress) {
		return <TouchableOpacity onPress={onPress}><Icons {...props} /></TouchableOpacity>
	}

	return (<Icons {...props} />);
}