
const UNCATEGORIZED_CAT = 200;
const CAT_SORT = {
    onigiri: 1000,
    daifuku: 900,
    misc: 100
}
export const sort = (a, b) => {

    const cat_a = CAT_SORT[a.category] || UNCATEGORIZED_CAT;
    const cat_b = CAT_SORT[b.category] || UNCATEGORIZED_CAT;

    if (cat_a === cat_b) {
        return a.sku > b.sku ? 1 : -1
    }

    return cat_a > cat_b ? -1 : 1;
}