import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Form from 'AppCore/Components/Form';
import FormAttr from 'AppCore/Components/Form/formFields';

import { updateShop, createShop, displayError } from 'AppCore/Actions';
import Api from 'AppCore/api';

import css from './styles.css';
import { Button } from "@material-ui/core";

class ClientEdit extends PureComponent {

	static defaultProps = {
		onSaved: () => {},
	}

	constructor(props) {
		super(props);
		this.state = {
			changed: false,
			loading: false,
            saving: false,
            data: null,
		}
	}

	getClientData = async () => {
		const { client_id } = this.props;
		return await Api.getShop({ shop_id: client_id });
	}
	updateClientData = async data => {
		const { client_id, updateShop } = this.props;

		try {
			await updateShop({
				shop_id: client_id,
				shop: {
					...(await this.getClientData()),
					...data
				}
			});
		} catch (error) {
			throw error;
		}
	}

	onClickSave = async () => {
		this.setState({loading: true})
		await this.updateClientData(this.state.data)
		this.setState({ data: await this.getClientData() })
		this.setState({ loading: false, changed: false })
		this.props.enqueueSnackbar('Saved', {variant: 'success'});
	}

	async componentDidMount() {
		this.setState({ data: await this.getClientData() })
	}

	render() {

		const  { pricelists } = this.props;

		return (
            <div className={css.formContainer}>

				<Paper className={css.paper}>

				<h2>Infos</h2>

				<div className={css.content}>

					<FormAttr

						values={this.state.data}
						onChange={({ values: data, hasError }) => {
							this.setState({
								data: {
									...this.state.data,
									...data
								},
								changed: true
							})
						}}
						config={{
							attributes: {
								key: { type: 'text', validators: ['not_empty'] },
								name: { type: 'text', validators: ['not_empty'] },
								stripe_invoice_payment: { type: 'switch', label: "Lien stripe dans les factures" },
								truck_delivery: {
									type: 'number', label: "Numéro de camion (1 à 10)",
									transformers: [
										{ name: 'min', value: 0 },
										{ name: 'max', value: 10 }
									]
								},
								contact_name: { label: "Nom du contact", type: 'text', },
								contact_email: { label: "Email du contact", type: 'text', validators: ['email'] },
								tel: { label: "Telephone du contact", type: 'text', transformers: ["nospaces"], validators: ['phone'] },
								mobile: { label: "Mobile", type: "text", transformers: ["nospaces"], validators: ['tel', 'number'] },
								address: { label: "Adresse de livraison", type: 'text', multiline: 4 },
								address_infos: { label: "Complément d'adresse", type: 'text', multiline: 2 },
								cp: { label: "Code Postal", type: 'text', transformers: ["numeric"] },
								city: { label: "Ville", type: 'text' },
								pricelist_id: {
									label: "Liste de prix",
									type: 'select',
									values: [
										{label: "Aucune", value: null},
										...pricelists.map(pricelist => ({
											label: pricelist.name,
											value: pricelist.id
										}))
									]
								},

								container_type: { type: 'select', selectType: 'contenant', label: "Type de contenant" },

								hour_delivery: { type: 'select', selectType: 'hours', label: "Heure de récupération" },
								hour_dropoff: { type: 'select', selectType: 'hours', label: "Heure de livraison" },
								delivery_picture: { type: 'switch', label: "Photo obligatoire à la livraison" },
								delivery_signature: { type: 'switch', label: "Signature obligatoire à la livraison" },
							}
						}}
					/>

					<Button disabled={!this.state.changed || this.state.loading} variant="contained" color="primary" onClick={this.onClickSave}>Save</Button>
				</div>
				</Paper>

				<Paper className={css.paper}>
					<h2>Infos compta</h2>

					<div className={css.content}>
						<Form
							getData={async () => (await this.getClientData()).contact_accounting}
							updateData={contact_accounting => this.updateClientData({ contact_accounting })}
							attributes={{
								first_name: { type: 'text' },
								last_name: { label: "Nom du contact", type: 'text' },
								tel: { label: "Téléphone", type: "text", transformers: ["nospaces"], validators: ['phone'] },
								mobile: { label: "Mobile", type: "text", transformers: ["nospaces"], validators: ['phone'] },
								email: { label: "Email du contact", type: 'text', validators: ['email'] },
								address: { label: "Adresse de facturation", type: 'text', multiline: 4 },
								address_infos: { label: "Complément d'adresse", type: 'text', multiline: 2 },
								cp: { label: "Code Postal", type: 'text', transformers: ["numeric"] },
								city: { label: "Ville", type: 'text' },
							}}
						/>
					</div>

				</Paper>








            </div>
		)
	}

}

export default connect(
	state => ({
		pricelists: state.pricelists.data || []
	}),
	{ updateShop, createShop, displayError }
)(withSnackbar(ClientEdit));