import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import get from 'lodash.get';
import IconButton from '@material-ui/core/IconButton';

import Icon from 'AppCore/Components/Icons';
import Button from 'AppCore/Components/Button';

import { displayModal, hideModal } from 'AppCore/Actions';
import Api from 'AppCore/api';

export default connect(
    () => ({}),
    { displayModal, hideModal }
)(withSnackbar(

    ({
        displayModal, hideModal, invoice,
        closeSnackbar,
        enqueueSnackbar,
        label,
        ...props
    }) => {

        const onClick = () => {
            displayModal({
                name: 'form',
                props: {
                    title: "Envoyer par email",
                    attributes: {
                        email: { label: "Email", type: "text", validators: ["email"] }
                    },
                    data: {
                        email: get(invoice, "client.contact_accounting.email", "")
                    },
                    onClose: hideModal,
                    onConfirm: async ({ email } = {}) => {
                        const { id: invoice_id } = invoice;
                        await Api.sendInvoiceByEmail({ invoice_id, email });
                        hideModal();
                        enqueueSnackbar('Envoyée!', {variant: 'success'});
                    }
                }
            })
        };

        if (!label) {
            return (
                <IconButton aria-label={'Envoyer par email'} onClick={onClick}>
                    <Icon name={'email'} />
                </IconButton>
            );
        }

        return (
            <Button icon="email" color="primary" {...props} onClick={onClick}>{label}</Button>
        )
    }

));