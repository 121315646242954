import Constants from "AppCore/Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getPriceLists
*/
export const getPriceLists = () => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.getPriceLists();

		dispatch({
			type: C.SET_PRICELISTS,
			pricelists: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createPriceList
*/
export const createPriceList = ({ pricelist }) => async (dispatch, getState, { api, ws }) => {

	try {
		await api.createPriceList({ pricelist });
		await dispatch(getPriceLists());

	} catch (e) {
        console.error(e);
        throw e;
	}
}


export const updateLocalPriceList = ({ pricelist_id, pricelist }) => async (dispatch, getState, { api, ws }) => {
	const pricelists = JSON.parse(JSON.stringify(getState().pricelists));

	pricelists.data.forEach((_pricelist, i) => {
		if (_pricelist.id === pricelist_id) {
			pricelists.data[i] = {
				...pricelists.data[i],
				...pricelist
			}
		}
	});

	dispatch({
		type: C.SET_PRICELISTS,
		pricelists
	});
}

export const updatePriceList = ({ pricelist_id, pricelist }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_pricelists = {
		...(getState().pricelists)
	}

	dispatch(updateLocalPriceList({ pricelist_id, pricelist }));

	try {


		const result = await api.updatePriceList({ pricelist_id, pricelist });
		dispatch(getPriceLists())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_PRICELISTS,
			pricelists: rollback_pricelists
		});
	}

}

export const deletePriceList = ({ pricelist_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deletePriceList({ pricelist_id });
		await dispatch(getPriceLists())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}