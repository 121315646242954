import React from "react";
import MaterialTextField from '@material-ui/core/TextField';


export default class TextField extends React.PureComponent {

    render = () => {

        const { classes, className, ...props } = this.props;

        return (
            <MaterialTextField
                variant="outlined"
                className={className}
                classes={classes}
                {...props}
            />
        );
    }

}