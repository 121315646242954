import StdUser from 'Meth/models/User'

var _model = {
	username: true,
	email: true,
	disabled: true,
	permissions: true,
	deleted: true
};


export default class User extends StdUser {

	getModel() {
		return {
			..._model
		};
	}

	setAdmin(bool) {
		if (!this.permissions) {
			this.permissions = {};
		}
		this.permissions.admin = bool;
	}

	setPermission(type, permission_type) {
		if (!this.permissions) {
			this.permissions = {};
		}

		this.permissions[type] = permission_type === 'w' ? 2 : 1;
	}

	removePermission(type) {
		if (!this.permissions) {
			return;
		}

		delete this.permissions[type];
	}

	setPermissionShop(shop_id, permission_type) {
		if (!this.permissions) {
			this.permissions = {};
		}

		if (!this.permissions.shops) {
			this.permissions.shops = {};
		}

		this.permissions.shops[shop_id] = permission_type === 'w' ? 2 : 1;
	}

	removePermissionShop(shop_id) {
		if (!this.permissions || !this.permissions.shops || !this.permissions.shops[shop_id]) {
			return;
		}

		delete this.permissions.shops[shop_id];
	}

	checkPermission(permission_value, permission_type) {
		switch (permission_type) {
			case 'read':
			case 'r':
				return permission_value > 0;
			case 'write':
			case 'w':
				return permission_value > 1;
			default:
				return false;
		}
	}

	hasPermissions(permissions) {
		for (let i = 0; i < permissions.length; i++) {
			if (!this.hasPermission(permissions[i])) {
				return false;
			}
		}

		return true;
	}

	hasPermission(type, permission_type = 'read') {

		if (this.isAdmin()) {
			return true;
		}

		if (!this.permissions) {
			return false;
		}

		if (!this.permissions[type]) {
			return false;
		}

		if (typeof this.permissions[type] === 'boolean') {
			return this.permissions[type];
		}

		return this.checkPermission(this.permissions[type], permission_type);
	}

	hasPermissionShop(shop_id, permission_type) {

		if (this.isAdmin()) {
			return true;
		}

		if (!this.permissions || !this.permissions.shops || !this.permissions.shops[shop_id]) {
			return false;
		}

		return this.checkPermission(this.permissions.shops[shop_id], permission_type);
	}

	isAdmin() {

		if (!this.permissions) {
			return false;
		}

		return !!this.permissions.admin;
	}

}






